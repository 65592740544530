import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { generateMediaPath } from 'utils/generateMediaPath'
import Slider, { CustomArrowProps } from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { sectionColourModel } from 'utils/constants/sectionColour'
import { sectionColour } from 'utils/sectionColour'
import { ReactComponent as SliderNext } from 'assets/icon/slider-next.svg'
import { ReactComponent as SliderPrev } from 'assets/icon/slider-prev.svg'
import { CourseInstanceModel } from 'api/data-contracts'
import formatCurrency from 'utils/formatCurrency'

interface ActiveCoursesProps {
  bgColour: sectionColourModel['bgColour']
  activePublicCourseInstances: CourseInstanceModel[] | undefined
}

const ActiveCourses = ({
  bgColour,
  activePublicCourseInstances,
}: ActiveCoursesProps) => {
  const { backgroundColor, headingColor, textColor } = sectionColour(bgColour)

  const [slideDisplay, setSlideDisplay] = useState(4)
  const [topics, setTopics] = useState<string[]>([])
  const [topicFilter, setTopicFilter] = useState<string>()
  const [disableFilter, setDisableFilter] = useState<boolean>(false) // New state variable

  useEffect(() => {
    if (activePublicCourseInstances !== undefined) {
      setSlideDisplay(activePublicCourseInstances?.length)
    }
    topicsList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePublicCourseInstances])

  const slideToDisplayCondition = slideDisplay < 3 ? slideDisplay : 3
  const slideToDisplayConditionBelow1024px = slideDisplay < 2 ? slideDisplay : 2
  const slideToDisplayConditionBelow600px = slideDisplay < 2 ? slideDisplay : 2

  const NextArrow = ({
    currentSlide,
    slideCount,
    ...props
  }: CustomArrowProps) => <SliderNext {...props} />

  const PrevArrow = ({
    currentSlide,
    slideCount,
    ...props
  }: CustomArrowProps) => <SliderPrev {...props} />

  const settings = {
    dots: false,
    arrows: true,
    slidesToShow: slideToDisplayCondition,
    slidesToScroll: 1,
    infinite: true,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slideToDisplayConditionBelow1024px,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: slideToDisplayConditionBelow600px,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          centerMode: true,
        },
      },
    ],
  }

  const topicsList = (): void => {
    const uniqueTopics: string[] = []

    activePublicCourseInstances &&
      activePublicCourseInstances.forEach(courseInstance => {
        if (
          courseInstance?.course?.topic?.name &&
          !uniqueTopics.includes(courseInstance.course.topic.name)
        ) {
          uniqueTopics.push(courseInstance.course.topic.name)
        }
      })

    setTopics(uniqueTopics)
  }

  const filterCoursesByTopic = (topic: string) => {
    if (topic === topicFilter && disableFilter === false) {
      // Disable the filter on second click
      setDisableFilter(true)
      setTopicFilter('')
    } else {
      // Enable the filter and set the new topic filter
      setDisableFilter(false)
      setTopicFilter(topic)
    }
  }

  return (
    <section
      className={`${backgroundColor} pr-0 px-9 py-20 lg:py-32 lg:px-24 courses section-border-grey`}
      id="courses"
    >
      <div className="container mx-auto xl:px-8">
        <div className="text-center">
          <div className="flex text-center flex-col mr-9 lg:mr-0">
            <h2 className={`${headingColor} font-black`}>
              {topics ? 'Courses by topic' : 'Courses'}
            </h2>
            {!topics && <hr className="hr rounded-cap bg-branding-secondary" />}
          </div>
          {topics && (
            <div className="py-6 lg:pb-20">
              {topics.map(topic => {
                return (
                  <button
                    onClick={() => filterCoursesByTopic(topic as string)}
                    key={topic}
                    className={`mx-3 rounded-full ${
                      bgColour === ('grey' || 'white') ? '' : ''
                    } border-[2px] py-2 px-5 button button-radius-large ${
                      bgColour === ('grey' || 'white')
                        ? `${
                            topic === topicFilter
                              ? 'bg-[black!important] text-[#ffffff!important] border-black'
                              : 'bg-[unset!important] text-[#212121!important] border-black'
                          }`
                        : `${
                            topic === topicFilter
                              ? 'bg-[white!important] text-[#212121!important] border-white'
                              : 'bg-[unset!important] text-[#ffffff!important] border-white'
                          }`
                    }`}
                  >
                    {topic}
                  </button>
                )
              })}
            </div>
          )}
        </div>
        <Slider {...settings}>
          {activePublicCourseInstances &&
            activePublicCourseInstances.map(courseInstance => {
              if (
                !topicFilter ||
                (courseInstance?.course?.topic?.name &&
                  courseInstance?.course?.topic.name === topicFilter)
              ) {
                return (
                  <Link
                    to={`/course-details/${courseInstance.id}`}
                    key={courseInstance.id}
                  >
                    <div className="w-[calc(100%-28px)] mx-auto overflow-hidden mb-10">
                      {courseInstance.course?.courseImage ? (
                        <div className="rounded-medium overflow-hidden">
                          <img
                            className="rounded-medium object-cover xl:h-[200px] h-[150px] w-[100%] relative transition-transform origin-center duration-300 hover:scale-[1.03]"
                            alt=""
                            src={generateMediaPath(
                              courseInstance.course?.courseImage,
                            )}
                          />
                        </div>
                      ) : (
                        <div className="rounded-medium overflow-hidden">
                          <img
                            className="rounded-medium object-cover xl:h-[200px] h-[150px] w-[100%] relative transition-transform origin-center duration-300 hover:scale-[1.03]"
                            alt="DummyIcon"
                            src="http://via.placeholder.com/640x360"
                          />
                        </div>
                      )}
                      <h4 className={`${headingColor} text-left mt-8 mb-3`}>
                        {courseInstance.title}
                      </h4>
                      <p className={`${textColor} text-left`}>
                        {courseInstance?.description &&
                          courseInstance?.description?.substring(0, 60) + '...'}
                      </p>
                      <h4 className={`${textColor} pt-3`}>
                        {courseInstance.cost && courseInstance.cost > 0
                          ? formatCurrency(courseInstance.cost)
                          : 'free'}
                      </h4>
                      {courseInstance.location &&
                        courseInstance.location.trim() !== '' && (
                          <div className="flex items-center mt-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20px"
                              height="20px"
                              viewBox="0 0 512.000000 512.000000"
                              preserveAspectRatio="xMidYMid meet"
                              className="mr-1"
                            >
                              <g
                                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                className={`${
                                  backgroundColor === 'bg-white'
                                    ? 'fill-branding-primary'
                                    : 'fill-white'
                                }`}
                                stroke="none"
                              >
                                <path
                                  d="M2307 4675 c-381 -62 -709 -234 -978 -512 -491 -508 -608 -1242 -315
-1970 218 -542 653 -1098 1236 -1580 190 -157 220 -176 290 -181 94 -7 128 12
340 189 580 482 1009 1033 1226 1572 292 725 177 1454 -309 1963 -56 59 -132
131 -168 160 -212 169 -461 286 -734 344 -132 29 -455 37 -588 15z m485 -431
c399 -76 742 -337 918 -699 278 -574 103 -1275 -501 -2000 -106 -127 -412
-435 -546 -550 l-103 -87 -102 88 c-136 116 -443 424 -547 549 -663 797 -808
1560 -409 2160 63 95 217 254 308 318 186 130 389 209 612 237 93 11 264 4
370 -16z"
                                />
                                <path
                                  d="M2350 3520 l0 -320 -320 0 -320 0 0 -215 0 -215 320 0 320 0 0 -320
0 -320 210 0 210 0 0 320 0 320 320 0 320 0 0 215 0 215 -320 0 -320 0 0 320
0 320 -210 0 -210 0 0 -320z"
                                />
                              </g>
                            </svg>
                            <p className={`${textColor} text-left`}>
                              {courseInstance.location}
                            </p>
                          </div>
                        )}
                    </div>
                  </Link>
                )
              } else return null
            })}
        </Slider>
        {/* 
        <div className="flex justify-center">
          <span
            className={`${buttonColor} inline-flex button button-radius-large mt-6 mr-9`}
          >
            <Link to={ROUTE.DASHBOARD}>View all</Link>
          </span>
        </div> */}
      </div>
    </section>
  )
}

export default ActiveCourses
