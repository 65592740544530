import React from 'react'
import { useGetSettings } from 'utils/organisation'
import { generateMediaPath } from '../utils/generateMediaPath'
import { HashLink } from 'react-router-hash-link'

const Hero = () => {
  const { settings } = useGetSettings()

  return (
    <section className="pb-16 px-4 lg:px-16 relative shadow-lg bg-branding-primary overflow-hidden drop-shadow-4xl">
      <div className="bg-white lg:min-h-[400px] text-center lg:text-left container container-mui relative grid lg:px-24 pt-6 lg:py-8 mx-auto lg:gap-12 xl:gap-0 xl:py-14 xl:pb-12 2xl:pb-0 lg:grid-cols-12">
        <div
          className="mx-6 sm:mx-0 relative lg:w-[70%] self-center pb-1 lg:pb-0"
          style={{
            gridColumn: 'span 8 / span 8',
          }}
        >
          <h1 className="mb-8 sm:px-4 lg:px-0 pt-8 lg:pt-0 tracking-tight text-branding-primary">
            {settings?.bannerTitle}
          </h1>
          <p className="mb-8 sm:px-4 lg:px-0 font-medium lg:w-[85%]">
            {settings?.bannerSubTitle}
          </p>
          {settings?.bannerCallToActionUrl && (
            <span className="inline-flex my-3 sm:my-0 button button-wide bg-branding-secondary">
              <HashLink smooth to={settings?.bannerCallToActionUrl}>
                {settings?.bannerCallToActionText}
              </HashLink>
            </span>
          )}
        </div>
        {settings?.bannerImage && (
          <div className="relative lg:absolute banner-hero-img right-[-1.5rem] sm:right-[-4rem] top-[2.5rem] col-span-8 w-full lg:w-[55%] h-[250px] sm:h-[300px] md:h-[400px] lg:h-[480px] order-last">
            <img
              className="object-cover object-center w-full h-full rounded-tl-[80px]"
              src={generateMediaPath(settings?.bannerImage)}
              alt="mockup"
            />
          </div>
        )}
      </div>
    </section>
  )
}

export default Hero
