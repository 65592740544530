import { useGetProfile, useSignOut } from 'utils/auth'

const ProfileUserDetail = () => {
  const { profile } = useGetProfile()
  const { signOut } = useSignOut()

  return (
    <>
      <div className="flex justify-between lg:flex-col items-start">
        <div className="flex items-center mb-8">
          <svg width="35" height="35" viewBox="0 0 35 35" className="mr-3">
            <path
              id="user-circle-svgrepo-com"
              d="M29.667,30.926c-.919-4.148-4.381-6.593-10.666-6.593S9.252,26.778,8.333,30.926M19,35A16,16,0,1,0,3,19,16,16,0,0,0,19,35Zm0-16c2.37,0,3.556-1.27,3.556-4.444S21.37,10.111,19,10.111s-3.556,1.27-3.556,4.444S16.63,19,19,19Z"
              transform="translate(-1.5 -1.5)"
              fill="#fff"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
            />
          </svg>

          <p className="font-semibold">
            Currently logged in as <br />
            <span className="text-branding-primary">
              {profile?.firstname} {profile?.lastname}
            </span>
          </p>
        </div>
        <p>
          <span
            className="font-medium cursor-pointer forgot-password-link"
            onClick={() => signOut()}
          >
            Log out
          </span>
        </p>
      </div>
    </>
  )
}
export default ProfileUserDetail
