import {
  AddUserAssessmentAnswerItemModel,
  SectionModel,
  UserModuleSectionAssessmentModel,
} from 'api/data-contracts'
import { useState } from 'react'
import { useAddMyAssessmentAnswer } from 'utils/assessment'
import { useGetSectionRandomQuestions } from 'utils/section'
import { Question } from './Question'
import { COMPLETED } from 'utils/constants/slide'
import { useModuleContext } from 'context/ModuleContext'
import AssessmentAwayMessage from 'components/AssessmentAwayMessage'
import { useNavigate, useParams } from 'react-router-dom'
import { COURSE_DASHBOARD_BY_ID } from 'utils/constants/clientRoutes'

export const Assessment = ({
  section,
  assessmentId,
  setAssessment,
}: {
  section: SectionModel
  assessmentId: string
  setAssessment: (value: UserModuleSectionAssessmentModel) => void
}) => {
  const navigate = useNavigate()
  const { userCourseInstanceId } = useParams()

  const { setAssessmentCompletion } = useModuleContext()

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const { questions } = useGetSectionRandomQuestions(section.id!)
  const [assessmentAwayMessageVisible, setAssessmentAwayMessageVisible] =
    useState(false)

  const { mutateAsync: addMyAssessmentAnswer } = useAddMyAssessmentAnswer()

  const handleQuestionSubmit = async () => {
    await addMyAssessmentAnswer({
      userAssessmentId: assessmentId,
      data: {
        questionId: questions?.[currentQuestionIndex].id,
        addUserAssessmentAnswerItems: selectedAnswerIds,
      },
      onSuccess: data => {
        if (
          data.assessmentProgress === COMPLETED &&
          data.assessmentPassed === true
        ) {
          setAssessmentCompletion(data)
        }
        setAssessment(data)
      },
    })
    setSelectedAnswerIds([])
    setCurrentQuestionIndex(currentQuestionIndex + 1)
  }

  const [selectedAnswerIds, setSelectedAnswerIds] = useState<
    AddUserAssessmentAnswerItemModel[]
  >([])

  window.history.pushState(null, '', window.location.href)
  window.onpopstate = () => {
    window.history.pushState(null, '', window.location.href)
    setAssessmentAwayMessageVisible(true)
  }

  return (
    <>
      <span className="text-small font-bold mb-4">
        Question {currentQuestionIndex + 1} out of {questions?.length}
      </span>
      <div className="question-holder max-w-[1000px]">
        {questions && (
          <Question
            question={questions[currentQuestionIndex]}
            setSelectedAnswer={setSelectedAnswerIds}
            selectedAnswer={selectedAnswerIds}
          />
        )}
      </div>
      <div>
        <button
          className="question-submit mt-2 mb-16 button button-long button-radius-large secondary-button-primary"
          onClick={handleQuestionSubmit}
          disabled={selectedAnswerIds.length === 0}
        >
          Submit
        </button>
      </div>

      {assessmentAwayMessageVisible && (
        <AssessmentAwayMessage
          onStay={() => setAssessmentAwayMessageVisible(false)}
          onLeave={() => {
            setAssessmentAwayMessageVisible(false)
            setAssessment({})
            navigate(
              COURSE_DASHBOARD_BY_ID.replace(
                ':userCourseInstanceId',
                userCourseInstanceId!,
              ),
            )
          }}
        />
      )}
    </>
  )
}
