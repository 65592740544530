import { Tab } from '@headlessui/react'
import {
  UserCourseInstanceModel,
  UserModuleDataModel,
} from 'api/data-contracts'
import AnchorLink from './AnchorLink'
import ModuleAccordion from './ModuleAccordion'
import { generateMediaPath } from 'utils/generateMediaPath'
import { JOINED } from 'utils/constants/slide'
import { ReactComponent as ShareIcon } from 'assets/icon/share.svg'
import FeedbackAccordion from './FeedbackAccordion'
import { useCallback, useEffect, useState } from 'react'

interface TabItemProps {
  title: string
  children: React.ReactNode
}

function TabItem({ title, children }: TabItemProps) {
  return (
    <Tab.Panel
      className={classNames(
        'rounded-cap bg-white',
        'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
      )}
    >
      {children}
    </Tab.Panel>
  )
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function Tabs({
  userCourseInstance,
  selectedTabIndex,
  setSelectedTabIndex,
  allFeedbacks,
}: {
  userCourseInstance: UserCourseInstanceModel
  selectedTabIndex: number
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>
  allFeedbacks: () => UserModuleDataModel[] | undefined
}) {
  const [waitingFeedback, setWaitingFeedback] = useState(false)

  const feebackHandler = useCallback(() => {
    return userCourseInstance?.userModuleDatas?.filter(
      userModuleValue =>
        userModuleValue.userModuleStatus === 'FEEDBACKREQUIRED',
    )
  }, [userCourseInstance])

  useEffect(() => {
    if (feebackHandler()!.length > 0) {
      setWaitingFeedback(true)
    }
  }, [feebackHandler])

  return (
    <div className="w-full sm:px-0">
      <Tab.Group
        selectedIndex={selectedTabIndex}
        onChange={setSelectedTabIndex}
      >
        <Tab.List className="flex space-x-1 bg-blue-900/20 max-w-full overflow-hidden overflow-x-auto">
          <Tab
            className={({ selected }) =>
              classNames(
                'text-left py-3 pr-9 sm:pr-20 border-0 focus:outline-none',
                selected ? 'active-border-bottom' : '',
              )
            }
          >
            <span>Modules</span>
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                'text-left pr-9 sm:pr-20 focus:outline-none',
                selected ? 'active-border-bottom' : '',
              )
            }
          >
            <span>Notes</span>
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                'text-left pr-9 sm:pr-20 focus:outline-none',
                selected ? 'active-border-bottom' : '',
              )
            }
          >
            <span>Resources</span>
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                'text-left pr-9 sm:pr-20 focus:outline-none flex items-center',
                selected ? 'active-border-bottom' : '',
              )
            }
          >
            {allFeedbacks()!.length > 0 && (
              <div className="pending-notification bg-branding-secondary"></div>
            )}
            <span className="ml-2">Feedback</span>
          </Tab>
        </Tab.List>

        <hr className="bg-[#707070] w-[100%] h-[1px] border-none mb-4"></hr>

        <Tab.Panels className="mt-2">
          <TabItem title="Modules">
            <div className="w-full pt-2">
              <div className="mx-auto w-full">
                {userCourseInstance?.currentModule ? (
                  <div className="flex flex-col sm:flex-row rounded-medium justify-between py-7 px-11 my-2 text-white bg-branding-primary">
                    <div>
                      {userCourseInstance?.userInstanceStatus === JOINED ? (
                        ''
                      ) : (
                        <p className="text-small font-medium">
                          Your last active session
                        </p>
                      )}
                      <h3 className="py-2">
                        {userCourseInstance?.currentModule.order}:&nbsp;
                        {userCourseInstance?.currentModule?.name}
                      </h3>
                      {waitingFeedback && (
                        <h4 className="mb-3">Awaiting trainer feedback</h4>
                      )}
                      <p>
                        {userCourseInstance?.userInstanceStatus === JOINED
                          ? 'Start your first module...'
                          : 'Pick up where you left off...'}
                      </p>
                    </div>
                    <AnchorLink
                      to={`/course/${userCourseInstance.id}/module/${userCourseInstance?.currentModule?.id}`}
                      className="button h-fit self-start sm:self-end mt-5 sm:mt-0"
                    >
                      {userCourseInstance?.userInstanceStatus === JOINED
                        ? 'Start'
                        : 'Continue'}
                    </AnchorLink>
                  </div>
                ) : (
                  <div className="flex flex-col sm:flex-row rounded-medium justify-between p-9 my-2 text-white bg-branding-primary">
                    <div>
                      <p className="text-small font-medium">Course completed</p>
                      <h3 className="py-2">
                        {userCourseInstance?.currentModule?.name}
                      </h3>
                      <p>Congratulations! You have completed this course.</p>
                    </div>
                    {userCourseInstance?.certificateUrl && (
                      <AnchorLink
                        to={`${generateMediaPath(
                          userCourseInstance?.certificateUrl,
                          'certificates',
                        )}`}
                        className="button h-fit self-start sm:self-end mt-5 sm:mt-0"
                      >
                        Download certificate
                      </AnchorLink>
                    )}
                  </div>
                )}
                {userCourseInstance?.courseInstance?.course?.modules &&
                  userCourseInstance?.courseInstance?.course?.modules.map(
                    (module, index) => (
                      <ModuleAccordion
                        key={module.id}
                        module={module}
                        userCourseInstance={userCourseInstance}
                        currentModule={userCourseInstance?.currentModule}
                        index={index + 1}
                        setSelectedTabIndex={setSelectedTabIndex}
                      />
                    ),
                  )}
              </div>
            </div>
          </TabItem>
          <TabItem title="Notes">
            {userCourseInstance?.userModuleDatas ? (
              userCourseInstance.userModuleDatas.map((module, index) => {
                const hasNotes = module.userModuleSectionDatas?.some(
                  section => section.notes && section.notes.length > 0,
                )
                return hasNotes ? (
                  <div key={module.id}>
                    <h3 className="font-normal py-6">{module.module?.name}</h3>
                    <hr className="my-3 h-[3px] border-none bg-grey"></hr>
                    {module.userModuleSectionDatas &&
                      module.userModuleSectionDatas.map(section => {
                        const notes = section.notes
                        if (notes && notes.length > 0) {
                          return (
                            <div key={section.id}>
                              {notes.map(note => (
                                <div key={note.id}>
                                  <h4 className="py-5 underline">
                                    {note.title}
                                  </h4>
                                  <p>{note.note}</p>
                                  <hr className="bg-grey w-[100%] h-[1px] border-none my-4"></hr>
                                </div>
                              ))}
                            </div>
                          )
                        } else {
                          return null
                        }
                      })}
                  </div>
                ) : index === 0 ? (
                  <p className="my-6" key={module.id}>
                    You have no notes associated with this course.
                  </p>
                ) : null
              })
            ) : (
              <p className="my-6">Error: No user module data found.</p>
            )}
          </TabItem>
          <TabItem title="Resources">
            {userCourseInstance?.courseInstance?.course?.modules ? (
              userCourseInstance.courseInstance.course.modules.map(module => {
                const resources = module.resources
                return resources && resources.length > 0 ? (
                  <div key={module.id}>
                    <h3 className="font-normal py-6">
                      Module {module.order}: {module.name}
                    </h3>
                    {resources.map(resource => (
                      <div key={resource.id}>
                        <>
                          {resource.resourceType === 'URL' && resource.url ? (
                            <div className="flex flex-wrap">
                              <h4 className="border-b-[3px] border-link z-10">
                                <AnchorLink
                                  to={resource.url}
                                  className="OpenInNewIcon pr-5"
                                >
                                  {resource.name ?? ''}
                                </AnchorLink>
                              </h4>
                              <ShareIcon
                                fill="#005e85"
                                className="ml-[-14px] mt-[7px] OpenInNewIcon"
                                focusable="false"
                                aria-hidden="true"
                                data-testid="OpenInNewIcon"
                              />
                            </div>
                          ) : resource.resourceType === 'File' &&
                            resource.filePath ? (
                            <div>Open Resource File</div>
                          ) : null}
                          <p className="mt-3 pb-5">{resource.description}</p>
                        </>
                      </div>
                    ))}
                    <hr className="bg-grey w-[100%] h-[1px] border-none my-4"></hr>
                  </div>
                ) : resources === null ? (
                  <p className="my-6" key={module.id}>
                    There are no resources associated with this course.
                  </p>
                ) : null
              })
            ) : (
              <p className="my-6">Error: No user course module data found.</p>
            )}
          </TabItem>
          <TabItem title="Feedback">
            <div className="pt-2">
              {userCourseInstance && (
                <FeedbackAccordion allFeedbacks={allFeedbacks} />
              )}
            </div>
          </TabItem>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
