import { useForm, SubmitHandler } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { ForgotPasswordModel } from 'utils/models/Auth'
import { CustomTextField } from 'components/CustomTextField'
import { getOrganisationId } from 'utils/organisation'
import { BASEURL } from 'utils/constants/clientRoutes'
import { useResetPasswordRequest } from 'utils/auth'
import { useState } from 'react'
import AuthPageWrapper from './AuthPageWrapper'

const validationSchema = Yup.object<ForgotPasswordModel>().shape({
  email: Yup.string()
    .email('Email address format is incorrect')
    .required('Email is required'),
})

const ParticipantInvitation = () => {
  const { mutateAsync } = useResetPasswordRequest()
  const [sendMail, setSendMail] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordModel>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit: SubmitHandler<ForgotPasswordModel> = data => {
    const forgotPasswordData = {
      userEmail: data.email,
      baseSiteUrl: BASEURL,
      organisationId: getOrganisationId(),
    }
    mutateAsync(forgotPasswordData)
      .then(res => {
        setSendMail(true)
      })
      .catch(error => {
        //console.log(error)
      })
  }

  return (
    <AuthPageWrapper
      title="Forgotten your password?"
      description="Don't worry, just pop in your email address below and we can send you a link to reset your password."
      showPanel={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomTextField
          control={control}
          errors={errors?.email}
          name="email"
          label="Email"
        />

        <div className="w-full my-auto mx-0 mt-8">
          <button
            type="submit"
            className="w-full py-4 button bg-branding-secondary"
          >
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Reset password
          </button>
        </div>
        {sendMail && (
          <p className="mt-9 font-normal text-main text-branding-secondary">
            We have successfully sent an email. Please click on the link to
            reset your password.
          </p>
        )}
      </form>
    </AuthPageWrapper>
  )
}

export default ParticipantInvitation
