import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { generateMediaPath } from '../utils/generateMediaPath'
import { OrganisationTestimonialModel } from 'api/data-contracts'
import { sectionColourModel } from 'utils/constants/sectionColour'
import { sectionColour } from 'utils/sectionColour'

type Props = {
  testimonials: OrganisationTestimonialModel[]
  bgColour: sectionColourModel['bgColour']
}
const Testimonials = ({ testimonials, bgColour }: Props) => {
  const { backgroundColor, textColor, headingColor } = sectionColour(bgColour)
  return (
    <>
      {testimonials && testimonials.length > 0 && (
        <section
          className={`${backgroundColor} px-9 py-20 lg:py-32 lg:px-24 section-border-grey`}
        >
          <div className="container mx-auto xl:px-12 testimonials">
            <Carousel
              showThumbs={false}
              showStatus={false}
              showArrows={true}
              emulateTouch={true}
              showIndicators={testimonials.length > 1 ? true : false}
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${
                      hasPrev ? 'absolute' : 'hidden'
                    } top-[-17rem] sm:top-0 bottom-0 left-0 flex justify-center items-center p-3 hover:opacity-70 cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <span
                      className={`testimonial-slider-icon testimonial-slider-prev ${
                        bgColour === 'grey'
                          ? 'bg-branding-primary'
                          : bgColour === 'white'
                          ? 'bg-branding-primary'
                          : bgColour === 'branding'
                          ? 'bg-white'
                          : ''
                      }`}
                    ></span>
                  </div>
                )
              }}
              renderArrowNext={(clickHandler, hasNext) => {
                return (
                  <div
                    className={`${
                      hasNext ? 'absolute' : 'hidden'
                    } top-[-17rem] sm:top-0 bottom-0 right-0 flex justify-center items-center p-3 hover:opacity-70 cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <span
                      className={`testimonial-slider-icon testimonial-slider-next ${
                        bgColour === 'grey'
                          ? 'bg-branding-primary'
                          : bgColour === 'white'
                          ? 'bg-branding-primary'
                          : bgColour === 'branding'
                          ? 'bg-white'
                          : ''
                      }`}
                    ></span>
                  </div>
                )
              }}
            >
              {testimonials.map(testimonial => (
                <div
                  key={testimonial.id}
                  className="flex justify-center items-center flex-col"
                >
                  <div className="rounded-full w-[100px] h-[100px]">
                    {testimonial.image && (
                      <img
                        className="object-cover relative rounded-full border-[2px] border-solid border-main"
                        alt=""
                        src={generateMediaPath(testimonial.image)}
                      ></img>
                    )}
                  </div>
                  <div className="pt-6 flex justify-center items-center text-center flex-col w-[100%] lg:w-[80%]">
                    <h3 className={`${textColor}`}>
                      "{testimonial.testimonial}"
                    </h3>
                    <h4 className={`${headingColor} pt-5`}>
                      {testimonial.author}
                    </h4>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </section>
      )}
    </>
  )
}

export default Testimonials
