import {
  AnswerOptionModel,
  QuestionModel,
  SectionModel,
} from 'api/data-contracts'
import { useState } from 'react'
import { Question } from './MiniQuizQuestion'
import { useModuleContext } from 'context/ModuleContext'

interface MiniQuizProps {
  section: SectionModel
}
export const MiniQuiz = ({ section }: MiniQuizProps) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [showNextButton, setShowNextButton] = useState(false)
  const [showSubmitButton, setShowSubmitButton] = useState(true)
  const [isQuestionSubmitted, setIsQuestionSubmitted] = useState(false)
  const questions = section.sectionQuestionLinks ?? []
  const [selectedAnswers, setSelectedAnswers] = useState<AnswerOptionModel[]>(
    [],
  )
  const { setSectionIsRequired } = useModuleContext()

  const QuestionHandler = async () => {
    setIsQuestionSubmitted(true)
    if (currentQuestionIndex === questions.length - 1) {
      // show 'retry' instead of 'next' on last question
      setShowNextButton(false)
      setShowSubmitButton(false)
      // setShowRetryButton(true)
      // section.isRequiredToBeCompleted && should be applied to setMiniQuizInProgress at some point
      setSectionIsRequired(false)
      //enable Navigation next button
    } else {
      // show 'next'
      setShowNextButton(true)
    }
  }

  const NextQuestionHandler = async () => {
    setIsQuestionSubmitted(false)
    setSelectedAnswers([])
    setCurrentQuestionIndex(currentQuestionIndex + 1)
    setShowNextButton(false)
  }

  /*  const RerenderQuestionsHandler = async () => {
    setIsQuestionSubmitted(false)
    setCurrentQuestionIndex(0)
    setSelectedAnswers([])
    setShowRetryButton(false)
  } */

  return (
    <>
      <span className="text-base font-bold">
        Question{' '}
        {questions?.length > 0
          ? currentQuestionIndex + 1
          : currentQuestionIndex}{' '}
        out of {questions?.length}
      </span>
      {section?.contentArea1 && (
        <p className="pt-6 italic">{section?.contentArea1}</p>
      )}
      <Question
        question={
          (questions && questions[currentQuestionIndex]?.question) ??
          ({} as QuestionModel)
        }
        setSelectedAnswer={setSelectedAnswers}
        selectedAnswer={selectedAnswers}
        isQuestionSubmitted={isQuestionSubmitted}
      />
      <div className="flex justify-between pb-16">
        <button
          className={
            !showNextButton && showSubmitButton
              ? 'disabled:opacity-40 mt-6 button button-long button-radius-large secondary-button-primary'
              : 'hidden'
          }
          onClick={QuestionHandler}
          disabled={selectedAnswers.length === 0}
        >
          Submit
        </button>
        <button
          onClick={NextQuestionHandler}
          className={
            showNextButton
              ? 'disabled:opacity-40 button button-large button-radius-large secondary-button-primary mt-6'
              : 'hidden'
          }
        >
          Next
        </button>
      </div>
    </>
  )
}
