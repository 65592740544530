/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PaymentIntentRequestModel,
  PaymentIntentResponseModel,
  ValidationExceptionModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Payment<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Payment
   * @name CreatePaymentIntent
   * @request POST:/api/Payment/createintent
   * @secure
   */
  createPaymentIntent = (
    data: PaymentIntentRequestModel,
    params: RequestParams = {},
  ) =>
    this.request<PaymentIntentResponseModel, ValidationExceptionModel>({
      path: `/api/Payment/createintent`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Payment
   * @name StripeWebhook
   * @request POST:/api/Payment/stripewebhook/{organisationId}
   * @secure
   */
  stripeWebhook = (organisationId: string, params: RequestParams = {}) =>
    this.request<void, ValidationExceptionModel>({
      path: `/api/Payment/stripewebhook/${organisationId}`,
      method: 'POST',
      secure: true,
      ...params,
    })
}
