import { OpenInNew } from '@mui/icons-material'
import { ResourceModel } from 'api/data-contracts'
import AnchorLink from 'components/AnchorLink'
import { useCallback, useEffect, useState } from 'react'
import { useApiClient } from 'utils/use-clients'

export const Resource = ({ resource }: { resource: ResourceModel }) => {
  const [file, setFile] = useState<string>()
  const client = useApiClient()
  const fetchFile = useCallback(async () => {
    if (!resource.id) {
      return
    }
    const file = await client.resource.getResourceFile(resource.id)
    const blob = await file.blob()
    const url = URL.createObjectURL(blob)
    setFile(url)
  }, [client.resource, resource.id])

  useEffect(() => {
    fetchFile()
  }, [fetchFile])

  return (
    <div className="mb-4">
      <div className="flex flex-wrap">
        <div className="border-b-[3px] border-[#005E85] z-10">
          <AnchorLink
            to={resource?.url ?? file ?? ''}
            className="text-lg text-blue font-bold pr-6"
          >
            {resource.name}
          </AnchorLink>
        </div>

        <OpenInNew className="!w-[20px] ml-[-18px] mt-[-1px] OpenInNewIcon" />
      </div>
      <div className="mt-3">
        <span className="text-base text-grey font-normal">
          {resource.description}
        </span>
      </div>
    </div>
  )
}
