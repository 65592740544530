import Footer from 'components/Footer'
import { useLocation, useParams } from 'react-router-dom'
import AppBar from 'components/AppBar'
import { useGetActiveCourseInstanceById } from 'utils/courseInstance'
import { Checkout } from './Checkout'
import { ReactComponent as LearningIcon } from 'assets/icon/learning.svg'
import { ReactComponent as ClockIcon } from 'assets/icon/clock.svg'
import { ReactComponent as ChartIcon } from 'assets/icon/chart.svg'
import { ReactComponent as CalendarIcon } from 'assets/icon/calendar.svg'
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter'
import dateFormatter from 'utils/dateFormatter'
import { LoaderSpinner } from 'components/LoaderSpinner'

const Payment = () => {
  const { courseInstanceId } = useParams()
  const { courseInstance } = useGetActiveCourseInstanceById(courseInstanceId!)

  const { state } = useLocation()
  const { Optional1, Optional2 } = state

  return (
    <>
      <AppBar />
      <div className="page-payment">
        {courseInstance ? (
          <>
            <section className="px-9 pt-10 pb-20 lg:pb-32 lg:pt-16 lg:px-24 text-white bg-branding-primary">
              <div className="container mx-auto lg:px-12 flex gap-6">
                <div className="justify-start w-[100%] lg:w-[65%]">
                  <div className="pb-8 flex justify-start flex-col">
                    <h1>Order Summary</h1>
                    <hr className="w-[13%] bg-white mt-6 h-1 rounded-cap border-white"></hr>
                  </div>
                  <div className="w-[100%] pb-8 lg:pb-16">
                    <div className="grid grid-cols-4 gap-4 mt-10">
                      <div className="col-span-3">
                        <p>{courseInstance.title}</p>
                      </div>
                      <div className="col-span-1">
                        <p>£{courseInstance.cost}</p>
                      </div>
                      <div className="col-span-3">
                        <p>Total before tax</p>
                      </div>
                      <div className="col-span-1">
                        <p>£{(courseInstance.cost! / 1.2).toFixed(2)}</p>
                      </div>
                      <div className="col-span-3">
                        <p>Tax @ 20.0%</p>
                      </div>
                      <div className="col-span-1">
                        <p>
                          {' '}
                          £{((courseInstance.cost! / 1.2) * 0.2).toFixed(2)}
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-4 gap-4 mt-10">
                      <div className="col-span-3">
                        <h2>Order total</h2>
                      </div>
                      <div className="col-span-1">
                        <h2>£{courseInstance.cost}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="lg:absolute top-0 w-full lg:px-36 lg:py-24 lg:block z-9 lg:z-1">
              <div className="container mx-auto relative p-6 lg:py-20">
                <div className="h-full lg:h-fit w-full lg:w-[40%] xl:w-[30%] xxl:w-[25%] relative lg:absolute lg:right-[0%] xl:right-[3%] lg:drop-shadow-lg shadow-md bg-white lg:border-branding-secondary lg:border-opacity-50 lg:border-[3px] md:block text-left booking-panel payment-panel">
                  <div className="px-8 pt-8 flex-col">
                    <h2>
                      <strong>{courseInstance.title}</strong>
                    </h2>
                    <p className="font-normal my-4">
                      {courseInstance.subTitle}
                    </p>
                  </div>
                  <div className="px-8 pb-8">
                    <div className="grid gap-1 lg:gap-8">
                      {courseInstance.course?.duration && (
                        <div className="flex flex-row items-center">
                          <span className="text-small mr-2">
                            <ClockIcon />
                          </span>
                          <span className="font-bold">
                            {courseInstance.course?.duration}
                          </span>
                        </div>
                      )}
                      <div className="flex flex-row items-center">
                        <span className="text-small mr-2">
                          <LearningIcon />
                        </span>
                        <span className="font-bold">
                          {capitalizeFirstLetter(
                            courseInstance.course?.courseStructure ?? '',
                          )}
                        </span>
                      </div>
                      {courseInstance.course?.duration && (
                        <div className="flex flex-row items-center">
                          <span className="text-small mr-2">
                            <ChartIcon />
                          </span>
                          <span className="font-bold">
                            {courseInstance.course?.duration}
                          </span>
                        </div>
                      )}
                      {courseInstance.startDate && (
                        <div className="flex flex-row items-center">
                          <span className="text-small mr-2">
                            <CalendarIcon />
                          </span>
                          <span className="font-bold">
                            {dateFormatter(courseInstance.startDate)}
                          </span>
                        </div>
                      )}
                      <div className="flex flex-row">
                        <h3 className="font-bold">£{courseInstance.cost}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className="bg-light-grey px-9 py-20 lg:py-32 lg:px-24 text-left whatlearn">
              <div className="container mx-auto lg:px-12 lg:flex gap-[0 4rem]">
                <div className="justify-start lg:w-[65%]">
                  <div className="pb-3 flex flex-col">
                    <h2 className="text-center lg:text-left text-branding-primary">
                      Payment Details
                    </h2>
                    <hr className="hr rounded-cap bg-branding-secondary"></hr>
                  </div>
                  <div className="mx-auto w-[100%]">
                    <Checkout Optional1={Optional1} Optional2={Optional2} />
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <LoaderSpinner />
        )}
        <Footer />
      </div>
    </>
  )
}
export default Payment
