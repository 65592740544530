import AppBar from 'components/AppBar'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

type AuthPageProps = {
  children: ReactNode
  title: string
  description?: string
  showPanel: boolean
  PanelTitle?: string
  PanelDescription?: string
  PanelButtonText?: string
  PanelButtonLink?: string
}

export const AuthPageWrapper: React.FC<
  React.PropsWithChildren<AuthPageProps>
> = ({
  children,
  title,
  description,
  showPanel,
  PanelTitle,
  PanelDescription,
  PanelButtonText,
  PanelButtonLink,
}) => {
  const navigate = useNavigate()

  return (
    <>
      <AppBar />
      <section className="px-9 pt-10 py-20 lg:py-32 lg:px-24">
        <div className="container mx-auto lg:px-12 flex flex-wrap justify-between gap-10 sm:gap-20 lg:gap-6">
          <div className="w-[100%] sm:mx-auto sm:w-[65%] lg:mx-0 lg:w-[47%]">
            <h1 className="mt-5 mb-10 text-branding-primary">{title}</h1>
            {description && <h4 className="my-5">{description}</h4>}
            <div className="w-[90%]">{children}</div>
          </div>
          {showPanel && (
            <div className="self-start lg:pt-24 w-[100%] lg:w-[46%]">
              <div className="flex flex-col gap-10 text-white px-10 py-10 rounded-large bg-branding-primary">
                {PanelTitle && <h4 className="text-white">{PanelTitle}</h4>}
                {PanelDescription && <p>{PanelDescription}</p>}
                {PanelButtonText && (
                  <button
                    type="submit"
                    className="button button-wide self-start text-main"
                    onClick={() => navigate(PanelButtonLink!)}
                  >
                    {PanelButtonText}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  )
}

export default AuthPageWrapper
