import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useApiClient } from './use-clients'

export type PaymentIntentCreateRequestProps = {
  data: {
    courseInstanceId: string
    optional1?: string | null
    optional2?: string | null
  }
}

const useCreatePaymentIntent = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (props: PaymentIntentCreateRequestProps) =>
      client.payment.createPaymentIntent(props.data),
    onSuccess: async (data, props) => {
      queryClient.setQueryData(['PaymentData'], data) //set query (oldData,newData)
      queryClient.refetchQueries(['PaymentData']) // refetch queries that depend on the user data
    },
  })
  return mutation
}

export { useCreatePaymentIntent }
