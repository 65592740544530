import {
  ResetUserPasswordCommand,
  RequestPasswordResetModel,
  LoginUserModel,
  CheckUserExistsModel,
  SectionModel,
} from 'api/data-contracts'

export interface CompleteLoginUserModel extends LoginUserModel {
  email?: string | null
}

export interface CheckEmailExistsModel extends CheckUserExistsModel {
  email?: string | null
  userExists?: boolean
}
export interface ResetPasswordModel extends ResetUserPasswordCommand {
  password?: string | null
  confirmpassword?: string | null
}

export interface ForgotPasswordModel extends RequestPasswordResetModel {
  email?: string
}

export interface RegisterModel {
  UserName?: string
  Email?: string
  Firstname?: string
  Lastname?: string
  Password?: string
  OrganisationId?: string
  BaseSiteUrl?: string
  confirmpassword?: string
}

export const defaultSectionModel: SectionModel = {
  id: '',
  name: null,
  description: null,
  tags: null,
  order: 0,
  isInMenu: false,
  sectionType: null,
  sectionTemplate: null,
  isRequiredToBeCompleted: false,
  createdDate: null,
  title: null,
  contentImage: null,
  contentArea1: null,
  contentArea2: null,
  contentArea3: null,
  miniQuizNumQuestionsToShow: 0,
  assessmentNumQuestionsToShow: 0,
  assessmentMarkProcess: null,
  assessmentQuestionOrder: null,
  assessmentPassRate: 0,
  assessmentIsTimed: false,
  assessmentTimePerQuestionSeconds: 0,
  assessmentNumberOfAttempts: 0,
  sectionQuestionLinks: null,
}
