import React from 'react'
import { Link } from 'react-router-dom'
interface CustomLinkProps {
  to: string
  children: React.ReactNode
  className?: string
  onClick?: () => void // Add the onClick prop
}
const AnchorLink: React.FC<CustomLinkProps> = ({
  to,
  children,
  className,
  onClick,
}) => {
  const isInternalLink = (url: string) => {
    const isCurrentDomain = url.startsWith(window.location.host)
    const isRelativePath = !url.includes('://')
    return isCurrentDomain || isRelativePath
  }
  if (to !== null && to !== '' && to !== undefined) {
    if (isInternalLink(to)) {
      return (
        <Link
          to={to}
          className={className}
          onClick={onClick ? onClick : undefined}
        >
          {children}
        </Link>
      )
    } else {
      return (
        <a
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          className={className}
          onClick={onClick ? onClick : undefined}
        >
          {children}
        </a>
      )
    }
  } else
    return (
      <a
        href="/"
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        onClick={onClick ? onClick : undefined}
      >
        {children}
      </a>
    )
}
export default AnchorLink
