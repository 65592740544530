import React, { useState } from 'react'
import Footer from 'components/Footer'
import { useNavigate, useParams } from 'react-router-dom'
import { useUser } from 'utils/auth'
import {
  useGetActiveCourseInstanceById,
  useAddMeToCourseInstance,
} from 'utils/courseInstance'
import parse from 'html-react-parser'
import ModuleDashboardAccordion from 'components/ModuleDashboardAccordion'
import { useGetMyCoursesByOrganisation } from 'utils/organisation'
import AppBar from 'components/AppBar'
import { generateMediaPath } from 'utils/generateMediaPath'
import { toast } from 'react-toastify'
import { LoaderSpinner } from 'components/LoaderSpinner'
import CourseBookingConfirmation from 'components/CourseBookingConfirmation'

const CourseDetails = () => {
  const { courseInstanceId } = useParams()
  const { courseInstance } = useGetActiveCourseInstanceById(courseInstanceId!)
  const { mutateAsync: addMeToCourse } = useAddMeToCourseInstance(
    courseInstanceId!,
  )
  const { user } = useUser()
  const { myCourses } = useGetMyCoursesByOrganisation()
  const navigate = useNavigate()
  const [optional1Val, setOptional1Val] = useState('')
  const [optional2Val, setOptional2Val] = useState('')

  const [
    courseBookingConfirmationVisible,
    setCourseBookingConfirmationVisible,
  ] = useState(false)

  const handleAddMeToCourse = async () => {
    const query = {
      Optional1: optional1Val,
      Optional2: optional2Val,
    }

    if (courseInstance?.useOptional1 === true && optional1Val === '') {
      toast.error(`${courseInstance?.optional1} is required`)
      return <></>
    }

    if (courseInstance?.useOptional2 === true && optional2Val === '') {
      toast.error(`${courseInstance?.optional2} is required`)
      return <></>
    }

    if (user) {
      if (courseInstance?.cost !== 0 && courseInstance?.cost !== null) {
        navigate(`/payment/${courseInstance?.id}`, {
          state: query,
        })
      } else {
        await addMeToCourse({
          query,
          onSuccess: () => {
            navigate('/')
          },
          onError: error => {
            toast.error('An error occurred while adding you to the course.')
          },
        })
      }
    } else {
      navigate('/login')
    }
  }

  const getDaySuffix = (day: number): string => {
    if (day >= 11 && day <= 13) {
      return 'th'
    }
    switch (day % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  const CapitalizeFirstLetter = (string: string) => {
    return string ? string[0].toUpperCase() + string.slice(1).toLowerCase() : ''
  }

  const bookingHandler = () => {
    if (
      courseInstance?.useOptional1 === true ||
      courseInstance?.useOptional2 === true
    ) {
      setCourseBookingConfirmationVisible(true)
    } else {
      handleAddMeToCourse()
    }
  }

  return (
    <>
      <AppBar />
      <div className="page-coursedetail">
        {courseInstance ? (
          <>
            <section className="px-9 pt-10 pb-20 lg:pb-32 lg:pt-16 lg:px-24 text-white bg-branding-primary">
              <div className="container mx-auto lg:px-12 flex gap-6">
                <div className="justify-start w-[100%] lg:w-[65%]">
                  <div className="pb-8 flex justify-start flex-col">
                    <h1>{courseInstance.title}</h1>
                    <hr className="w-[13%] bg-white mt-6 h-1 rounded-cap border-white"></hr>
                  </div>
                  <div className="w-[100%] pb-8 lg:pb-16">
                    <h3 className="font-medium">{courseInstance.subTitle}</h3>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-5 gap-1 lg:gap-8">
                    {courseInstance.course?.duration && (
                      <div className="flex flex-row lg:flex-col">
                        <span className="font-bold">Duration:</span>

                        <span className="pl-2 lg:pl-0 lg:mt-3 lg:pt-3 lg:border-t border-white border-opacity-70">
                          {courseInstance.course?.duration}
                        </span>
                      </div>
                    )}
                    <hr className="hr h-[1px] w-full bg-branding-secondary lg:hidden"></hr>
                    <div className="flex flex-row lg:flex-col">
                      <span className="font-bold">Location:</span>

                      <span className="pl-2 lg:pl-0 lg:mt-3 lg:pt-3 lg:border-t border-white border-opacity-70">
                        {CapitalizeFirstLetter(
                          courseInstance.course?.courseStructure ?? '',
                        )}
                      </span>
                    </div>
                    <hr className="hr h-[1px] w-full bg-branding-secondary lg:hidden"></hr>
                    {courseInstance.course?.duration && (
                      <div className="flex flex-row lg:flex-col">
                        <span className="font-bold">Runs over:</span>

                        <span className="pl-2 lg:pl-0 lg:mt-3 lg:pt-3 lg:border-t border-white border-opacity-70">
                          {courseInstance.course?.duration}
                        </span>
                      </div>
                    )}
                    <hr className="hr h-[1px] w-full bg-branding-secondary lg:hidden"></hr>
                    {courseInstance.startDate && courseInstance.endDate ? (
                      <>
                        <div className="flex flex-row lg:flex-col">
                          <span className="font-bold">Starts on:</span>

                          <span className="pl-2 lg:pl-0 lg:mt-3 lg:pt-3 lg:border-t border-white border-opacity-70 text-left">
                            {new Date(courseInstance.startDate)
                              .toLocaleDateString('en-GB', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                              })
                              .replace(
                                /\b(\d{1,2})\b/g,
                                '$1' + getDaySuffix(Number.parseInt('$1', 10)),
                              )}
                          </span>
                        </div>
                        <div className="flex flex-row lg:flex-col">
                          <span className="font-bold">Ends on:</span>

                          <span className="pl-2 lg:pl-0 lg:mt-3 lg:pt-3 lg:border-t border-white border-opacity-70 text-left">
                            {new Date(courseInstance.endDate)
                              .toLocaleDateString('en-GB', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                              })
                              .replace(
                                /\b(\d{1,2})\b/g,
                                '$1' + getDaySuffix(Number.parseInt('$1', 10)),
                              )}
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-row lg:flex-col">
                        <span className="font-bold">Status:</span>

                        <span className="pl-2 lg:pl-0 lg:mt-3 lg:pt-3 lg:border-t border-white border-opacity-70 text-left">
                          Ongoing
                        </span>
                      </div>
                    )}

                    <hr className="hr h-[1px] w-full bg-branding-secondary sm:hidden"></hr>
                    <div className="flex flex-row lg:hidden">
                      <span>Price:</span>
                      <span className="pb-6 lg:pb-0 pl-2 lg:pl-0 lg:mt-3 lg:pt-3 lg:border-t border-white border-opacity-70">
                        £{courseInstance.cost}
                      </span>
                    </div>
                    {courseInstance.bookingInfo && (
                      <div className="text-left w-[100%] lg:hidden rounded-2xl bg-white">
                        <div className="flex flex-col">
                          <div className="p-7 text-main">
                            {parse(courseInstance.bookingInfo)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
            {courseInstance.course?.courseContent && (
              <section className="bg-light-grey px-9 py-20 lg:py-32 lg:px-24 text-left whatlearn">
                <div className="container mx-auto lg:px-12 lg:flex gap-[0 4rem]">
                  <div className="justify-start lg:w-[65%]">
                    <div className="pb-3 flex flex-col">
                      <h2 className="text-center lg:text-left text-branding-primary">
                        What you'll learn
                      </h2>
                      <hr className="hr rounded-cap bg-branding-secondary"></hr>
                    </div>
                    <div className="mx-auto w-[100%]">
                      {parse(courseInstance.course?.courseContent)}
                    </div>
                  </div>
                </div>
              </section>
            )}
            <section className="bg-white px-9 py-20 lg:py-32 lg:px-24">
              <div className="container mx-auto lg:px-12 flex gap-6">
                <div className="text-center lg:text-left w-[100%] lg:w-[65%]">
                  <div className="flex flex-col">
                    <h2 className="text-branding-primary">
                      Course description
                    </h2>
                    <hr className="hr rounded-cap bg-branding-secondary"></hr>
                  </div>
                  <div className="w-[100%] font-normal text-lg">
                    {courseInstance.description}
                  </div>
                </div>
              </div>
            </section>
            <section className="px-9 py-20 lg:py-32 lg:px-24 font-white bg-branding-primary">
              <div className="container mx-auto lg:px-12 flex gap-6 justify-center lg:justify-start">
                <div className="w-[100%] lg:w-[65%] z-10">
                  <div className="flex text-center lg:text-left flex-col">
                    <h2 className="text-white">Course structure</h2>
                    <hr className="hr rounded-cap bg-white"></hr>
                  </div>
                  <div className="w-[100%]">
                    {courseInstance.course?.modules &&
                      courseInstance.course?.modules.map(module => (
                        <React.Fragment key={module.id}>
                          <ModuleDashboardAccordion module={module} />
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              </div>
            </section>
            {courseInstance.trainer?.firstname && (
              <section className="bg-white px-9 py-20 lg:py-32 lg:px-24">
                <div className="container mx-auto lg:px-12 flex gap-6">
                  <div className="justify-start lg:max-w-[75%]">
                    <div className="pb-8 flex justify-start flex-col">
                      <h3 className="mb-3 lg:mb-6 text-branding-primary">
                        Your trainer
                      </h3>
                      <hr className="w-[100px] hr rounded-cap bg-branding-secondary"></hr>
                    </div>
                    <div className="lg:flex lg:space-x-6">
                      <div>
                        {/* Remove check for empty string and add image when available from api */}
                        {courseInstance.trainer?.profileImage &&
                        courseInstance.trainer?.firstname ? (
                          <img
                            className="rounded-full border-gray-light mx-auto shadow-md lg:mr-20 border min-w-[120px]"
                            alt={courseInstance.trainer?.firstname}
                            src={generateMediaPath(
                              courseInstance.trainer?.profileImage,
                            )}
                          ></img>
                        ) : (
                          <img
                            className="rounded-full"
                            alt="placeholder"
                            src="https://via.placeholder.com/100/92c952"
                          ></img>
                        )}
                      </div>
                      <div className="text-center lg:text-left mt-10 lg:mt-0">
                        <h5 className="pb-4">
                          {courseInstance.trainer?.firstname}
                        </h5>
                        <p>{courseInstance.trainer?.bio}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
            <div className="fixed top-0 w-full px-36 py-24 lg:block z-9 lg:z-1">
              <div className="container mx-auto relative p-6 lg:py-20">
                <div className="xl:right-[-8%] xxl:right-[0%] lg:drop-shadow-lg shadow-md bg-white lg:border-branding-secondary lg:border-opacity-50 lg:border-[3px] md:block text-center booking-panel">
                  <div className="px-8 lg:p-8 flex-col">
                    {courseInstance.cost! > 0 ? (
                      <h4 className="hidden lg:block">
                        <strong>£{courseInstance.cost}</strong> per person
                      </h4>
                    ) : (
                      <h3 className="font-normal hidden lg:block">
                        <strong>Free </strong>
                        to join
                      </h3>
                    )}
                    {user ? (
                      myCourses && (
                        <>
                          {myCourses.some(
                            course =>
                              course.courseInstance?.id === courseInstanceId,
                          ) ? (
                            <span className="my-6">
                              You are already enrolled in this course
                            </span>
                          ) : (
                            <button
                              className="button px-8 mt-6 mb-0 w-full bg-branding-secondary"
                              onClick={bookingHandler}
                            >
                              Book your place
                            </button>
                          )}
                        </>
                      )
                    ) : (
                      <div>
                        <button
                          className="button button-large button-radius-large mt-6 mb-0 w-full bg-branding-secondary"
                          onClick={() => {
                            navigate('/login')
                          }}
                        >
                          Book your place
                        </button>
                      </div>
                    )}
                    <div className="flex justify-between hidden">
                      {/* <button
                    className="lightbutts-icon py-2 text-base border-[1px]  bg-light-grey font-bold px-4 mt-4 mb-0 rounded-medium flex items-center"
                  >
                    Save
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="inline max-h-[15px] ml-2"
                      width="18.988"
                      height="16.921"
                      viewBox="0 0 18.988 16.921"
                    >
                      <path
                        id="heart-svgrepo-com-3"
                        d="M18.606,5.376a4.719,4.719,0,0,1,0,6.673l-6.445,6.445a.944.944,0,0,1-1.335,0L4.382,12.049a4.719,4.719,0,0,1,0-6.673A4.162,4.162,0,0,1,9.4,4.333a4.774,4.774,0,0,1,2.094,1.954,4.774,4.774,0,0,1,2.094-1.954A4.162,4.162,0,0,1,18.606,5.376Z"
                        transform="translate(-2 -2.849)"
                        fill="none"
                        stroke="#575757"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                    </svg>
                  </button>
                  <button
                    className="lightbutts-icon py-2 text-base border-[1px]  bg-light-grey font-bold px-4 mt-4 mb-0 rounded-medium flex items-center"
                  >
                    Share
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="inline max-h-[15px] ml-2"
                      width="16"
                      height="17.289"
                      viewBox="0 0 16 17.289"
                    >
                      <path
                        id="share-2-svgrepo-com"
                        d="M14.5,6.5,11,3m0,0L7.5,6.5M11,3V14.375m7-2.625v4.375a1.75,1.75,0,0,1-1.75,1.75H5.75A1.75,1.75,0,0,1,4,16.125V11.75"
                        transform="translate(-3 -1.586)"
                        fill="none"
                        stroke="#575757"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                    </svg>
                  </button> */}
                    </div>
                  </div>
                  {courseInstance.bookingInfo && (
                    <>
                      <hr className="border-gray-light my-1 rounded-cap hidden lg:block"></hr>
                      <div className="p-8 hidden lg:block">
                        {parse(courseInstance.bookingInfo)}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <Footer />

            {courseBookingConfirmationVisible && (
              <CourseBookingConfirmation
                courseInstance={courseInstance}
                optional1Val={optional1Val}
                setOptional1Val={setOptional1Val}
                optional2Val={optional2Val}
                setOptional2Val={setOptional2Val}
                onCancel={() => setCourseBookingConfirmationVisible(false)}
                onBook={() => handleAddMeToCourse()}
              />
            )}
          </>
        ) : (
          <LoaderSpinner />
        )}
      </div>
    </>
  )
}
export default CourseDetails
