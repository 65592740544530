import { useEffect, useMemo, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { CustomTextField } from 'components/CustomTextField'
import { CustomPasswordField } from 'components/CustomPasswordField'
import { BASEURL } from 'utils/constants/clientRoutes'
import { RegisterModel } from 'utils/models/Auth'
import { useSignUp } from 'utils/auth'
import { getOrganisationId } from 'utils/organisation'
import AppBar from 'components/AppBar'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import PasswordValidation from '../PasswordValidation'
import { passwordValidation } from 'utils/validation/passwords'

const validationSchema = Yup.object<RegisterModel>().shape({
  Firstname: Yup.string().required('First name is required'),
  Lastname: Yup.string().required('Last name is required'),
  Email: Yup.string()
    .email(
      'Enter an email address in the correct format, for example: name@example.com',
    )
    .required('Email is required'),
  Password: passwordValidation,
  confirmpassword: Yup.string()
    .nullable()
    .oneOf([Yup.ref('Password'), null], 'Both passwords should match')
    .required('Confirm password is required'),
})

interface CreateAccountProps {
  setUserCreated: (value: boolean) => void
  emailDefaultValue: string
  setAccountExist: (value: boolean) => void
}

const CreateAccount = ({
  setUserCreated,
  emailDefaultValue,
  setAccountExist,
}: CreateAccountProps) => {
  const { mutateAsync: signUp } = useSignUp()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

  const [createAccountVisible, setCreateAccountVisible] = useState(true)

  const initalValues = useMemo(() => {
    return {
      Email: emailDefaultValue,
      Firstname: '',
      Lastname: '',
      Password: '',
      confirmpassword: '',
    }
  }, [emailDefaultValue])

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<RegisterModel>({
    resolver: yupResolver(validationSchema),
    defaultValues: initalValues,
  })

  const password = watch('Password')

  useEffect(() => {
    // Here setTimeout is used to set value in the form
    // once store password is applied from password manager
    const settingUpInitialValues = setTimeout(() => {
      reset(initalValues)
    }, 820)
    return () => clearTimeout(settingUpInitialValues)
    // reset and initialvalue dependency un-necessarily re-render the code
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit: SubmitHandler<RegisterModel> = async data => {
    setCreateAccountVisible(false)
    const registerData: RegisterModel = {
      UserName: data.Email,
      Email: data.Email,
      Firstname: data.Firstname,
      Lastname: data.Lastname,
      Password: data.Password,
      OrganisationId: getOrganisationId() as string,
      BaseSiteUrl: BASEURL,
    }
    try {
      await signUp(registerData)
      setUserCreated(true)
    } catch {
      toast.error('User account registration failed')
    }
  }

  return (
    <>
      <AppBar />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="!mt-3"
        autoComplete="off"
      >
        <h4 className="pb-7 register-heading">Your details</h4>

        <CustomTextField
          control={control}
          errors={errors?.Firstname}
          name="Firstname"
          label="First name"
        />
        <CustomTextField
          control={control}
          errors={errors?.Lastname}
          name="Lastname"
          label="Last name"
        />
        <CustomTextField
          control={control}
          errors={errors?.Email}
          name="Email"
          label="Email address"
          disable={true}
        />
        <hr className="bg-branding-secondary hr my-14 w-full h-[1px]" />

        <h4 className="pb-2 register-heading">Setting your password</h4>
        <PasswordValidation password={password || ''} />

        <CustomPasswordField
          control={control}
          errors={errors?.Password}
          name="Password"
          label="Password"
          state={passwordVisible}
          setState={setPasswordVisible}
        />
        <CustomPasswordField
          control={control}
          errors={errors?.confirmpassword}
          name="confirmpassword"
          label="Confirm password"
          state={confirmPasswordVisible}
          setState={setConfirmPasswordVisible}
        />

        <div className="w-full flex flex-wrap lg:w-3/4 mb-auto mt-8 mx-0">
          <button
            type="submit"
            className={`button w-full bg-branding-secondary ${
              !createAccountVisible && 'pointer-events-none opacity-50'
            }`}
          >
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Create account
          </button>
          <button
            type="submit"
            className="button w-[33%] mb-3 lg:mb-0 lg:mr-3 text-white mt-8 bg-branding-primary"
            onClick={() => setAccountExist(true)}
          >
            Back
          </button>
          <div className="w-full mt-8">
            <Link className="link" to="/login">
              <span className="font-medium">
                Already have an account? Log in
              </span>
            </Link>
          </div>
        </div>
      </form>
    </>
  )
}

export default CreateAccount
