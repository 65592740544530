import { Auth } from 'api/Auth'
import { Course } from 'api/Course'
import { CourseInstance } from 'api/CourseInstance'
import { Module } from 'api/Module'
import { Organisation } from 'api/Organisation'
import { Section } from 'api/Section'
import { User } from 'api/User'
import { UserAssessment } from 'api/UserAssessment'
import { UserCourseInstance } from 'api/UserCourseInstance'
import { TOKEN_KEY, getToken, isTokenExpired } from './auth'
import { getEnv } from './env'
import { Note } from 'api/Note'
import { Resource } from 'api/Resource'
import { PolicyDocument } from 'api/PolicyDocument'
import { Payment } from 'api/Payment'

type SecurityDataType = object
const securityWorker = () => {
  const token = getToken()
  if (token) {
    //check token is not expired,
    if (isTokenExpired()) {
      //TODO exhange refresh token for new token
      localStorage.removeItem(TOKEN_KEY)
    }
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  }
}

const auth = new Auth<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const useAuthClient = () => {
  return { auth }
}

const course = new Course<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const module = new Module<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const organisation = new Organisation<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const user = new User<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const section = new Section<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const note = new Note<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const courseInstance = new CourseInstance<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const userCourseInstance = new UserCourseInstance<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const userAssessment = new UserAssessment<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const resource = new Resource<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const policyDocument = new PolicyDocument<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const payment = new Payment<SecurityDataType>({
  baseUrl: getEnv().API_URL,
  securityWorker,
})

const useApiClient = () => {
  return {
    course,
    module,
    organisation,
    user,
    section,
    note,
    courseInstance,
    userCourseInstance,
    userAssessment,
    resource,
    policyDocument,
    payment,
  }
}

export { useAuthClient, useApiClient }
