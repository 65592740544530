import { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import * as ROUTE from 'utils/constants/clientRoutes'
import { CustomPasswordField } from 'components/CustomPasswordField'
import { CustomTextField } from 'components/CustomTextField'
import { useSignIn } from 'utils/auth'
import { CompleteLoginUserModel } from 'utils/models/Auth'
import AuthPageWrapper from './AuthPageWrapper'

const validationSchema = Yup.object<CompleteLoginUserModel>().shape({
  email: Yup.string()
    .email('Email address format is incorrect')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
})

const Login: React.FC = () => {
  const { mutateAsync } = useSignIn()
  const navigate = useNavigate()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<CompleteLoginUserModel>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit: SubmitHandler<CompleteLoginUserModel> = data => {
    mutateAsync({
      userName: data.email,
      password: data.password,
    })
      .then(res => {
        navigate(ROUTE.DASHBOARD)
      })
      .catch(error => {
        setErrorMessage('Incorrect username or password')
      })
  }

  return (
    <AuthPageWrapper
      title="Log in to your account"
      showPanel={true}
      PanelTitle="Create an account"
      PanelDescription="Don't have an account yet? Create one here"
      PanelButtonText="Create an account"
      PanelButtonLink="/register"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomTextField
          control={control}
          errors={errors?.email}
          name="email"
          label="Email address"
        />
        <CustomPasswordField
          control={control}
          errors={errors?.password}
          name="password"
          label="Password"
          state={passwordVisible}
          setState={setPasswordVisible}
        />
        <div className="w-full my-auto mx-0">
          <button
            type="submit"
            className="button sm:button-ultra-wide w-full mt-4 bg-branding-secondary"
          >
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Log in
          </button>
        </div>
        {errorMessage !== '' && (
          <p className="text-error mt-5">{errorMessage}</p>
        )}
        <p className="mt-9">
          <span
            className="font-medium cursor-pointer forgot-password-link"
            onClick={() => navigate(ROUTE.FORGOT_PASSWORD)}
          >
            Forgotten your password?
          </span>
        </p>
      </form>
    </AuthPageWrapper>
  )
}

export default Login
