import { AnswerOptionModel } from 'api/data-contracts'
import { Draggable } from 'react-beautiful-dnd'
import DoneIcon from '../../../../../assets/icon/correct-answer.svg'
import CancelIcon from '../../../../../assets/icon/incorrect-answer.svg'

type AnswerProps = {
  answer: AnswerOptionModel
  index: number
  isQuestionSubmitted: boolean
}

export const SpecificOrder = ({
  answer,
  index,
  isQuestionSubmitted,
}: AnswerProps) => {
  let classNames =
    'px-5 py-3 text-left w-[100%] border-main rounded-medium shadow-bottom bg-white'

  if (isQuestionSubmitted) {
    //if index of item equals answer.answerOrder
    if (index === answer.answerOrder) {
      classNames += 'text-correct welldone'
    } else {
      classNames += 'bg-light-grey incorrect'
    }
  }

  return (
    <div className="flex items-center mb-6">
      <Draggable
        draggableId={answer.id!}
        index={index}
        key={answer.id}
        isDragDisabled={isQuestionSubmitted}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`${classNames} ${
              snapshot.isDragging ? 'bg-gray-400' : ''
            }`}
          >
            {answer.answerText}
          </div>
        )}
      </Draggable>
      {isQuestionSubmitted &&
        (index === answer.answerOrder ? (
          <span className="ml-4 text-correct-500">
            <img src={DoneIcon} alt="DoneIcon" />
          </span>
        ) : (
          <span className="ml-4 text-error-500">
            <img src={CancelIcon} alt="CancelIcon" />
          </span>
        ))}
    </div>
  )
}
