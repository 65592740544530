import { AnswerOptionModel, QuestionModel } from 'api/data-contracts'
import React, { ReactNode } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'

type QuestionProps = {
  children: ReactNode
  question: QuestionModel
  setSelectedAnswer: (answer: AnswerOptionModel[]) => void
  answerOptions: AnswerOptionModel[]
}

export const SpecificOrderWrapper = ({
  children,
  setSelectedAnswer,
  answerOptions,
}: QuestionProps) => {
  const reorder = (
    list: AnswerOptionModel[],
    startPosition: number,
    endPosition: number,
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startPosition, 1)
    result.splice(endPosition, 0, removed)

    return result
  }

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return

    const newAnswerOptions = reorder(
      answerOptions,
      result.source.index,
      result.destination.index,
    )
    setSelectedAnswer(newAnswerOptions)
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={`${
              snapshot.isDraggingOver ? 'bg-gray-200' : ''
            } rounded-medium`}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
