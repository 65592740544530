import { useState, useEffect } from 'react'
import { Stripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { CheckoutForm } from 'components/payments/CheckoutForm'
import { useCreatePaymentIntent } from 'utils/payment'
import { useParams } from 'react-router-dom'
import { useBranding } from 'utils/useBranding'
import { StripeTypes } from 'utils/models/Stripe'
import fetchPaymentKey from 'utils/fetchPaymentKey'
import { useUser } from 'utils/auth'
import { LoaderSpinner } from 'components/LoaderSpinner'

interface CheckoutProps {
  Optional1: string
  Optional2: string
}
export const Checkout = ({ Optional1, Optional2 }: CheckoutProps) => {
  const [clientSecret, setClientSecret] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { mutateAsync: createPayment } = useCreatePaymentIntent()
  const { courseInstanceId } = useParams()
  const { paymentKey, branding } = useBranding()
  const { user } = useUser()
  const [stripe, setStripe] = useState<Stripe | null>(null)

  useEffect(() => {
    if (paymentKey) {
      fetchPaymentKey(paymentKey, setStripe, setErrorMessage)
    }
  }, [paymentKey])

  useEffect(() => {
    const fetchClientSecret = async () => {
      if (user) {
        if (courseInstanceId) {
          if (stripe) {
            try {
              const response = await createPayment({
                data: {
                  courseInstanceId: courseInstanceId,
                  optional1: Optional1,
                  optional2: Optional2,
                },
              })
              const clientSecret = response.data.clientSecret
              setClientSecret(clientSecret!)
            } catch {
              setErrorMessage(
                'Failed to load paymentKey. Please try again later.',
              )
            }
          }
        } else
          setErrorMessage(
            'Course does not exist. Please try picking an available course.',
          )
      } else setErrorMessage('Please login to pay for a course.')
    }
    fetchClientSecret()
  }, [stripe, courseInstanceId, createPayment, user, Optional1, Optional2])

  const options: StripeTypes = {
    clientSecret,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: branding.stylingSecondary as string,
        colorBackground: '#fff',
        colorPrimaryText: '#000',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontLineHeight: '23px',
        fontSizeBase: '1.1rem',
        fontSizeSm: '1.1rem',
      },
    },
  }

  if (!stripe) {
    return <LoaderSpinner />
  }

  return (
    <div className="Checkout">
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {clientSecret && (
        <Elements options={options} stripe={stripe!}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  )
}
