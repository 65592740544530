import { AnswerOptionModel, QuestionModel } from 'api/data-contracts'
import React from 'react'
import { useEffect } from 'react'
import { FreeText } from './answerTypes/FreeText'
import { MultiSelect } from './answerTypes/MultiSelect'
import { SingleSelect } from './answerTypes/SingleSelect'
import { SpecificOrder } from './answerTypes/SpecificOrder'
import { SpecificOrderWrapper } from './answerTypes/SpecificOrderWrapper'
import {
  FREETEXT,
  MULTISELECT,
  SINGLESELECT,
  SPECIFICORDER,
} from 'utils/constants/questions'
import renderQuestionSubtitle from 'components/module/RenderQuestionSubtitle'

type QuestionProps = {
  question: QuestionModel
  selectedAnswer: AnswerOptionModel[]
  setSelectedAnswer: (answer: AnswerOptionModel[]) => void
  isQuestionSubmitted: boolean
}

export const Question = ({
  question,
  setSelectedAnswer,
  selectedAnswer,
  isQuestionSubmitted,
}: QuestionProps) => {
  useEffect(() => {
    if (Object.keys(question).length > 0) {
      if (question.questionType === SPECIFICORDER && question.answerOptions) {
        setSelectedAnswer(question.answerOptions)
      } else {
        setSelectedAnswer([])
      }
    }
  }, [question, setSelectedAnswer])

  const renderAnswerOption = (answer?: AnswerOptionModel, index?: number) => {
    switch (question.questionType) {
      case FREETEXT:
        return (
          <FreeText
            answer={answer!}
            answerItem={answer!}
            setSelectedAnswer={setSelectedAnswer}
          />
        )

      case MULTISELECT:
        return (
          <MultiSelect
            answer={answer!}
            answerItem={answer!}
            setSelectedAnswer={setSelectedAnswer}
            selectedAnswer={selectedAnswer}
            isQuestionSubmitted={isQuestionSubmitted}
          />
        )

      case SINGLESELECT:
        return (
          <SingleSelect
            answer={answer!}
            answerItem={answer!}
            setSelectedAnswer={setSelectedAnswer}
            selectedAnswer={selectedAnswer}
            isQuestionSubmitted={isQuestionSubmitted}
          />
        )

      case SPECIFICORDER:
        return (
          <SpecificOrder
            answer={answer!}
            index={index!}
            isQuestionSubmitted={isQuestionSubmitted}
          />
        )
    }
  }

  return (
    <>
      {Object.keys(question).length > 0 ? (
        <div id={question.id}>
          <h3 className="my-5 mb-7" id={`question-${question.id}`}>
            {question.question}
          </h3>
          {renderQuestionSubtitle(question.questionType!)}
          {question.questionType === SPECIFICORDER && question.answerOptions ? (
            <>
              <SpecificOrderWrapper
                question={question!}
                answerOptions={selectedAnswer!}
                setSelectedAnswer={setSelectedAnswer}
              >
                {selectedAnswer &&
                  selectedAnswer.map((answer, index) => (
                    <React.Fragment key={answer.id}>
                      {renderAnswerOption(answer, index)}
                    </React.Fragment>
                  ))}
              </SpecificOrderWrapper>
            </>
          ) : question.questionType === SINGLESELECT ||
            question.questionType === MULTISELECT ? (
            <>
              {question.answerOptions &&
                question.answerOptions.map((answer, index) => (
                  <React.Fragment key={answer.id}>
                    {renderAnswerOption(answer, index)}
                  </React.Fragment>
                ))}
            </>
          ) : question.questionType === FREETEXT ? (
            <div className="my-5 mb-2">{renderAnswerOption()}</div>
          ) : null}
        </div>
      ) : (
        <p className="my-3">
          No questions have been defined by the course administrator for this
          mini quiz.
        </p>
      )}
    </>
  )
}
