import classNames from 'classnames'

type Props = {
  children: React.ReactNode
  className?: string
  size?: 'small' | 'normal'
  variant?: 'primary' | 'secondary'
} & Record<string, unknown>

const sizeClassMap = {
  small: {
    styles: 'p-5',
  },
  normal: {
    styles: 'p-10',
  },
}

const variantClassMap = {
  primary: {
    styles: 'bg-[--primary]',
  },
  secondary: {
    styles: 'bg-[--secondary]',
  },
}

function Button({ children, className, size, variant, ...rest }: Props) {
  const variantStyles = variant ? variantClassMap[variant].styles : ''
  const sizeStyles = size ? sizeClassMap[size].styles : ''

  return (
    <button
      type="button"
      className={classNames('px-6 py-2', className, variantStyles, sizeStyles)}
      {...rest}
    >
      {children}
    </button>
  )
}

export default Button
