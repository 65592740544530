import { MULTISELECT, FREETEXT, SPECIFICORDER } from 'utils/constants/questions'

const renderQuestionSubtitle = (questionType: string) => {
  const subTitle =
    questionType === MULTISELECT
      ? 'Please select all that apply...'
      : questionType === FREETEXT
      ? 'Please enter the correct answer'
      : questionType === SPECIFICORDER
      ? 'Please put the answers in the correct order'
      : 'Choose the correct answer'

  return <span className="mb-4 block">{subTitle}</span>
}

export default renderQuestionSubtitle
