import getDaySuffix from './getDaySuffix'

const dateFormatter = (startDate: string | number | Date) => {
  return new Date(startDate)
    .toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })
    .replace(/\b(\d{1,2})\b/g, '$1' + getDaySuffix(Number.parseInt('$1', 10)))
}

export default dateFormatter
