// BaseUrl
export const BASEURL = window.location.protocol + '//' + window.location.host

// Unauthenticated Routes
export const DASHBOARD = '/'
export const PAGE_NOT_FOUND = '*'
export const LOGIN = '/login'
export const REGISTER = '/register'
export const COURSE_DETAILS_BY_ID = '/course-details/:courseInstanceId'
export const PAYMENT = '/payment/:courseInstanceId'
export const PAYMENT_COMPLETION = '/payment/completion'
export const COURSE_CERTIFICATE = '/course-certificate/:userCourseInstanceId'
export const FORGOT_PASSWORD = '/forgot-password'
export const PARTICIPANT_INVITATION = '/complete-setup'
export const RESET_PASSWORD = '/reset-password'
export const VERIFY_EMAIL = '/verify-email'

// Authenticated Routes
export const COURSE_DASHBOARD_BY_ID = '/course-dashboard/:userCourseInstanceId'
export const COURSE_MODULE_BY_ID =
  '/course/:userCourseInstanceId/module/:moduleId'
export const POLICY = '/policy/:policyId'
export const PREVIEW_SECTION = '/preview/:moduleId/section/:sectionId'
export const PROFILE = '/profile'

// Test
export const TEST_DND = '/dnd'
