import { UserModuleSectionAssessmentModel } from 'api/data-contracts'
import { COMPLETED } from 'utils/constants/slide'

export const MaxScoredAssessment = (
  assessments: UserModuleSectionAssessmentModel[],
) => {
  const completedAssessment = assessments?.filter(
    value => value.assessmentProgress === COMPLETED,
  )

  return completedAssessment?.length > 0
    ? completedAssessment?.reduce((previous, current) =>
        current.assessmentScore &&
        previous.assessmentScore &&
        previous.assessmentScore > current.assessmentScore
          ? previous
          : current,
      )
    : {}
}
