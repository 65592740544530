import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UpdateNoteModel } from 'api/data-contracts'

import { useApiClient } from './use-clients'

// DELETE:/api/Note/{noteId}
const useDeleteNote = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (noteId: string) => client.note.deleteNote(noteId),
    onSuccess: () => {
      queryClient.invalidateQueries(['notes'])
    },
  })
  return mutation
}

// PUT:/api/Note/{noteId}
export type UpdateSectionNoteProps = {
  noteId: string
  data: UpdateNoteModel
}
const useUpdateNote = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: UpdateSectionNoteProps) =>
      client.note.updateNote(props.noteId, props.data),
    onSuccess: async data => {
      queryClient.setQueryData(['notes'], data)
      queryClient.refetchQueries(['notes'])
    },
  })
  return mutation
}

export { useDeleteNote, useUpdateNote }
