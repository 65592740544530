import { useState } from 'react'
import renderQuestionSubtitle from 'components/module/RenderQuestionSubtitle'
import { useGetSectionRandomQuestions } from 'utils/section'

interface AssessmentPreviewProps {
  sectionId: string
}
const AssessmentPreview = ({ sectionId }: AssessmentPreviewProps) => {
  const { questions } = useGetSectionRandomQuestions(sectionId!)

  const [currentQuestion, setCurrentQuestion] = useState(1)

  const nextQuestionHandler = () => {
    if (questions && questions.length !== 0) {
      if (questions.length !== currentQuestion) {
        setCurrentQuestion(currentQuestion + 1)
      }
    }
  }

  return (
    <>
      {questions && questions.length > 0 ? (
        <>
          <span className="text-base font-bold">
            Question {currentQuestion} out of {questions.length}
          </span>
          <>
            {questions.map((questionValue, index) => (
              <div
                key={questionValue.id}
                className={`${currentQuestion - 1 !== index && 'hidden'}`}
              >
                <h3 className="my-5 mb-7">{questionValue.question}</h3>
                {renderQuestionSubtitle(questionValue.questionType!)}
                {questionValue.answerOptions &&
                  questionValue.answerOptions.map(answerOptionValue => (
                    <div
                      className="question-answer-item sentence-uppercase mb-6"
                      key={answerOptionValue.id}
                    >
                      <label className="shadow-bottom rounded-medium w-[100%] border-2 block px-5 py-3 border-white">
                        <input
                          type="checkbox"
                          className="hidden border rounded-medium border-main p-2 cursor-pointer "
                        />
                        {answerOptionValue.answerText}
                      </label>
                    </div>
                  ))}
              </div>
            ))}
          </>
        </>
      ) : (
        <>
          <span className="text-base font-bold">Question 0 out of 0</span>
          <br />
        </>
      )}

      <button
        className="button button-large button-radius-large secondary-button-primary mt-6"
        onClick={() => nextQuestionHandler()}
      >
        Next
      </button>
    </>
  )
}

export default AssessmentPreview
