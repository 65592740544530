import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useBranding } from 'utils/useBranding'
import { generateMediaPath } from 'utils/generateMediaPath'
import { COURSE_DASHBOARD_BY_ID, PROFILE } from 'utils/constants/clientRoutes'
import { InfoOutlined, FormatListBulletedOutlined } from '@mui/icons-material'
import { ReactComponent as ProfileIcon } from 'assets/icon/profile.svg'
import { ReactComponent as ShareIcon } from 'assets/icon/share.svg'
import HomeIcon from '../../assets/icon/homepage.svg'
import { useModuleContext } from 'context/ModuleContext'
import { ASSESSMENT } from 'utils/constants/section'
import { ModuleModel } from 'api/data-contracts'
import AssessmentAwayMessage from 'components/AssessmentAwayMessage'
import { CONTENTS, NOTES, RESOURCES } from 'utils/constants/drawerType'

interface AppbarProps {
  module: ModuleModel
}

const Appbar = ({ module }: AppbarProps) => {
  const { branding } = useBranding()

  const navigate = useNavigate()
  const { userCourseInstanceId } = useParams()
  const {
    currentSection,
    assessment,
    setAssessment,
    setIsResourcesOpen,
    sidebarDrawerName,
    setSidebarDrawerName,
  } = useModuleContext()
  const [assessmentAwayMessageVisible, setAssessmentAwayMessageVisible] =
    useState(false)
  const [sidebarClickedButton, setSidebarClickedButton] = useState('')

  const bannerIconHandler = () => {
    navigate('/')
    setAssessment({})
  }

  const homeHandler = () => {
    navigate(
      COURSE_DASHBOARD_BY_ID.replace(
        ':userCourseInstanceId',
        userCourseInstanceId!,
      ),
    )
    setAssessment({})
  }

  const profileHandler = () => {
    navigate(PROFILE)
    setAssessment({})
  }

  const onLeaveFunctionality = (fieldName: string) => {
    fieldName === 'banner' && bannerIconHandler()
    fieldName === 'home' && homeHandler()
    fieldName === 'profile' && profileHandler()
  }

  return (
    <>
      <section className="module-sidenav flex flex-col max-h-screen h-screen w-[100px] lg:w-[130px] xl:w-[150px] justify-between relative z-[99] bg-branding-primary">
        <div className="mb-4 cursor-pointer">
          {branding.companyLogo && (
            <img
              className="w-[70%] ml-[15%] mt-12 org-logo "
              src={generateMediaPath(branding.companyLogo)}
              alt="logo"
              onClick={
                Object.keys(assessment).length > 0
                  ? () => {
                      setSidebarClickedButton('banner')
                      setAssessmentAwayMessageVisible(true)
                    }
                  : () => bannerIconHandler()
              }
            />
          )}
        </div>

        <div className="flex flex-col align-center font-medium text-white text-opacity-90">
          <button
            className="sidebarButton justify-center opacity-70 border-0 flex flex-col items-center mx-auto rounded-lg"
            onClick={
              Object.keys(assessment).length > 0
                ? () => {
                    setSidebarClickedButton('home')
                    setAssessmentAwayMessageVisible(true)
                  }
                : () => homeHandler()
            }
          >
            <img src={HomeIcon} alt="home" />
            <span className="mt-1 text-extra-small">Home</span>
          </button>
          <button
            className={`sidebarButton justify-center border-0 flex flex-col items-center mx-auto rounded-lg 
            ${sidebarDrawerName === RESOURCES && 'active opacity-100'} 
            ${
              currentSection?.sectionType === ASSESSMENT &&
              'pointer-events-none opacity-30'
            }`}
            onClick={() => {
              setIsResourcesOpen(true)
              setSidebarDrawerName(RESOURCES)
            }}
          >
            {currentSection?.sectionType !== ASSESSMENT &&
              module &&
              module?.resources![0] &&
              sidebarDrawerName !== RESOURCES && (
                <div className="w-5 h-5 absolute flex justify-center items-center right-[3px] top-[-11px] text-xs rounded-full bg-error border-white border-[2px]">
                  <span className="text-sm font-black">
                    {module?.resources.length}
                  </span>
                </div>
              )}
            <InfoOutlined
              fontSize="large"
              className={`${
                sidebarDrawerName !== RESOURCES &&
                currentSection?.sectionType !== ASSESSMENT &&
                'opacity-70'
              }`}
            />
            <span
              className={`${
                sidebarDrawerName !== RESOURCES &&
                currentSection?.sectionType !== ASSESSMENT &&
                'opacity-70'
              } mt-1 text-extra-small`}
            >
              Resources
            </span>
          </button>
          <button
            className={`sidebarButton justify-center border-0 flex flex-col items-center mx-auto rounded-lg
            ${sidebarDrawerName === CONTENTS ? 'active' : 'opacity-70'} `}
            onClick={() => {
              setIsResourcesOpen(true)
              setSidebarDrawerName(CONTENTS)
            }}
          >
            <FormatListBulletedOutlined fontSize="large" />
            <span className="mt-1 text-extra-small">Contents</span>
          </button>
          <button
            className={`sidebarButton justify-center border-0 flex flex-col items-center mx-auto rounded-lg
            ${sidebarDrawerName === NOTES && 'active opacity-100'}  
            ${
              currentSection?.sectionType === ASSESSMENT &&
              'pointer-events-none opacity-30'
            } 
            ${
              sidebarDrawerName !== NOTES &&
              currentSection?.sectionType !== ASSESSMENT &&
              'opacity-70'
            }`}
            onClick={() => {
              setIsResourcesOpen(true)
              setSidebarDrawerName(NOTES)
            }}
          >
            <div>
              <svg
                width="28"
                height="28.227"
                viewBox="0 0 28 28.227"
                className="w-full h-auto"
              >
                <path
                  d="M16.4,4.423H7.1A3.1,3.1,0,0,0,4,7.524V26.131a3.1,3.1,0,0,0,3.1,3.1H25.708a3.1,3.1,0,0,0,3.1-3.1v-9.3M11.753,21.479V17.6L25.321,4.035a2.741,2.741,0,0,1,3.876,0h0a2.741,2.741,0,0,1,0,3.876l-7.365,7.365-6.2,6.2Z"
                  transform="translate(-3 -2.005)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.5"
                />
              </svg>
            </div>
            <span className="mt-2 text-extra-small">Notes</span>
          </button>
        </div>
        <div>
          <hr className="w-[70%] ml-[15%] mt-3 bg-white h-[1px] mx-auto rounded-cap border-0 mb-8"></hr>
          <button
            className="sidebarButton opacity-70 justify-center border-0 flex flex-col items-center mx-auto rounded-lg"
            onClick={
              Object.keys(assessment).length > 0
                ? () => {
                    setSidebarClickedButton('profile')
                    setAssessmentAwayMessageVisible(true)
                  }
                : () => profileHandler()
            }
          >
            <div className="opacity-90">
              <ProfileIcon className="profile-icon w-7 h-7" />
            </div>
            <div className="flex mt-2 text-white text-opacity-90 opacity-90 items-center">
              <span className="text-extra-small">Profile</span>
              <ShareIcon className="share-icon ml-1" />
            </div>
          </button>
        </div>
      </section>

      {assessmentAwayMessageVisible && (
        <AssessmentAwayMessage
          onStay={() => setAssessmentAwayMessageVisible(false)}
          onLeave={() => onLeaveFunctionality(sidebarClickedButton)}
        />
      )}
    </>
  )
}

export default Appbar
