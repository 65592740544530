import { Control, Controller, MultipleFieldErrors } from 'react-hook-form'
import InvisibleIcon from '../assets/icon/Invisible.svg'
import VisibleIcon from '../assets/icon/Visible.svg'
interface CustomPasswordFieldProps {
  control?: Control
  errors?: {
    type?: string
    message?: string
    types?: MultipleFieldErrors
  }
  name: string
  label: string
  state: boolean
  setState: (value: boolean) => void
}

export const CustomPasswordField = ({
  control,
  errors,
  name,
  label,
  state,
  setState,
}: CustomPasswordFieldProps) => {
  return (
    <div className="w-full my-auto mx-0 mb-8">
      <Controller
        name={`${name}`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <div className="flex flex-col relative">
            <label className="text-grey text-small font-medium mb-2">
              {label}
            </label>
            <input
              className="focus:outline-none auth-input-field px-4 py-[0.625rem]"
              value={value === null || value === undefined ? '' : value}
              onChange={onChange}
              placeholder={label}
              type={state ? 'text' : 'password'}
            ></input>
            {errors?.message !== undefined && (
              <p className="text-error mt-1 text-base font-medium">
                {errors?.message}
              </p>
            )}
            <img
              className="absolute right-4 cursor-pointer top-[2.7rem]"
              src={state ? InvisibleIcon : VisibleIcon}
              alt="EyeIcon"
              onClick={() => setState(!state)}
            />
          </div>
        )}
      />
    </div>
  )
}
