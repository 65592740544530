export const CONTENT = 'CONTENT'
export const CONTENT_EMBED = 'CONTENTEMBED'
export const CONTENT_ONE_COLUMN = 'CONTENTONECOLUMN'
export const CONTENT_TWO_COLUMN = 'CONTENTTWOCOLUMN'
export const CONTENT_THREE_COLUMN = 'CONTENTTHREECOLUMN'
export const CONTENT_WITH_IMAGE = 'CONTENTWITHIMAGE'
export const MINIQUIZ = 'MINIQUIZ'
export const ASSESSMENT = 'ASSESSMENT'
export const IMAGE = "IMAGE"
export const EMBED = "EMBED"

export const THIRTYTHREE_SIXTYSIX = '33/66'
export const SIXTYSIX_THIRTYTHREE = '66/33'