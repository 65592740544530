/* This example requires Tailwind CSS v2.0+ */
import { Fragment, ReactNode } from 'react'
import { Dialog, Transition } from '@headlessui/react'

interface Props {
  open: boolean
  setOpen: (value: boolean) => void
  title?: string
  children: ReactNode
  setDrawerType: React.Dispatch<React.SetStateAction<string>>
}

export default function HeadlessSlideOver({
  open,
  setOpen,
  title,
  children,
  setDrawerType,
}: Props) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <div className="DrawerWrapper">
        <div className="relative InnerDrawer md:rounded-r-4xl">
          <Transition.Child
            as={Fragment}
            enter="lg:transform lg:transition lg:ease-in-out duration-0 lg:duration-500"
            enterFrom="lg:translate-x-full"
            enterTo="lg:translate-x-0"
            leave="lg:transform lg:transition lg:ease-in-out duration-0 lg:duration-500"
            leaveFrom="lg:translate-x-0"
            leaveTo="lg:translate-x-full"
          >
            <div className="h-full flex flex-col bg-white lg:shadow-left lg:rounded-l-4xl lg:rounded-r-4xl">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-0 lg:duration-500 lg:duration-0"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-0 sm:duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="hidden lg:block absolute top-[24px] right-[24px] lg:top-[28px] lg:right-[28px]">
                  <button
                    className="rounded-md text-gray-300"
                    onClick={() => {
                      setOpen(false)
                      setDrawerType('')
                    }}
                  >
                    <div className="absolute top-[40px] right-[20px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="47"
                        height="47"
                        viewBox="0 0 47 47"
                      >
                        <g
                          id="Group_2524"
                          data-name="Group 2524"
                          transform="translate(-1757 -283)"
                        >
                          <circle
                            id="Ellipse_41"
                            data-name="Ellipse 41"
                            cx="22"
                            cy="22"
                            r="22"
                            transform="translate(1759 284)"
                            fill="#575757"
                          />
                          <path
                            id="close-circle-svgrepo-com"
                            d="M32.333,17.667,17.667,32.333m14.667,0L17.667,17.667M25,47A22,22,0,1,0,3,25,22,22,0,0,0,25,47Z"
                            transform="translate(1755.5 281.5)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3"
                          />
                        </g>
                      </svg>
                    </div>
                  </button>
                </div>
              </Transition.Child>
              {title && (
                <div className="px-4 sm:px-6">
                  <Dialog.Title className="text-lg font-medium text-gray-900">
                    {title}
                  </Dialog.Title>
                </div>
              )}
              <div className="mt-12 lg:mt-32 h-full overflow-y-auto relative flex-1">
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition.Root>
  )
}
