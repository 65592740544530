import { NoteModel } from 'api/data-contracts'
import React, { createContext, useContext, useState } from 'react'

type NoteContextProps = {
  showNoteConfig: boolean | undefined
  setShowNoteConfig: (showAddNote: boolean | undefined) => void
  note: NoteModel | undefined
  setNote: (note: NoteModel | undefined) => void
}

const NoteContext = createContext<NoteContextProps>({
  showNoteConfig: {} as boolean | undefined,
  setShowNoteConfig: () => {
    //initalized function
  },
  note: {} as NoteModel | undefined,
  setNote: () => {
    //initalized function
  },
})

type ModuleContextProviderProps = {
  children: React.ReactNode
}

const NoteContextProvider = ({ children }: ModuleContextProviderProps) => {
  const [showNoteConfig, setShowNoteConfig] = useState<boolean | undefined>()
  const [note, setNote] = useState<NoteModel | undefined>()

  return (
    <NoteContext.Provider
      value={{ showNoteConfig, setShowNoteConfig, note, setNote }}
    >
      {children}
    </NoteContext.Provider>
  )
}

const useNoteContext = () => useContext(NoteContext)

export { NoteContextProvider, useNoteContext }
