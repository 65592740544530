import { useEffect } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'

import * as ROUTE from '../utils/constants/clientRoutes'
import ProtectedRoute from 'routes/ProtectedRoute'
import { getToken } from 'utils/auth'

// Unauthenticated Routes
import Dashboard from 'pages/Dashboard'
import Login from 'pages/Auth/Login'
import { TestDnd } from 'components/module/section/assessment/TestDnd'
import Register from 'pages/Auth/Register/Register'
import CourseDetails from 'pages/CourseDetails'
import VerifyEmail from 'pages/Auth/VerifyEmail'
import ResetPassword from 'pages/Auth/ResetPassword'
import ForgotPassword from 'pages/Auth/ForgotPassword'

// Authenticated Routes
import CourseDashboard from 'pages/CourseDashboard'
import Module from 'pages/Module'
import Policy from 'pages/Policy'
import ParticipantInvitation from 'pages/Auth/ParticipantInvitation'
import PreviewSlide from 'components/previewSlide'
import Payment from 'pages/Payment'
import PaymentCompletion from 'pages/PaymentCompletion'
import Profile from 'pages/YourAccount'

const AppRoutes = () => {
  const token = getToken()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    if (token && pathname === ROUTE.LOGIN) {
      navigate(ROUTE.DASHBOARD)
    }
  }, [navigate, token, pathname])

  return (
    <Routes>
      {/****** Unauthenticated Routes *******/}
      <Route path={ROUTE.DASHBOARD} element={<Dashboard />} />
      <Route path={ROUTE.LOGIN} element={<Login />} />
      <Route path={ROUTE.TEST_DND} element={<TestDnd />} />
      <Route path={ROUTE.REGISTER} element={<Register />} />
      <Route path={ROUTE.COURSE_DETAILS_BY_ID} element={<CourseDetails />} />
      <Route path={ROUTE.PAYMENT} element={<Payment />} />
      <Route path={ROUTE.PAYMENT_COMPLETION} element={<PaymentCompletion />} />
      <Route path={ROUTE.VERIFY_EMAIL} element={<VerifyEmail />} />
      <Route path={ROUTE.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={ROUTE.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route
        path={ROUTE.PARTICIPANT_INVITATION}
        element={<ParticipantInvitation />}
      />

      {/****** Authenticated Routes *******/}
      <Route
        path={ROUTE.COURSE_DASHBOARD_BY_ID}
        element={<ProtectedRoute Component={CourseDashboard} />}
      />
      <Route
        path={ROUTE.COURSE_MODULE_BY_ID}
        element={<ProtectedRoute Component={Module} />}
      />
      <Route
        path={ROUTE.POLICY}
        element={<ProtectedRoute Component={Policy} />}
      />
      <Route
        path={ROUTE.PREVIEW_SECTION}
        element={<ProtectedRoute Component={PreviewSlide} />}
      />
      <Route
        path={ROUTE.PROFILE}
        element={<ProtectedRoute Component={Profile} />}
      />

      {/****** No Routes Found *******/}
      <Route
        path="/*"
        element={
          token ? (
            <Navigate to={ROUTE.DASHBOARD} />
          ) : (
            <Navigate to={ROUTE.LOGIN} />
          )
        }
      />
    </Routes>
  )
}

export default AppRoutes
