import { Disclosure } from '@headlessui/react'

import { UserModuleDataModel } from 'api/data-contracts'

// Icons
import AccordionOpenIcon from '../assets/icon/accordion-open.svg'
import AccordionCloseIcon from '../assets/icon/accordion-close.svg'

interface FeedbackAccordionProps {
  allFeedbacks: () => UserModuleDataModel[] | undefined
}
const FeedbackAccordion = ({ allFeedbacks }: FeedbackAccordionProps) => {
  const feedbacksData = allFeedbacks()!

  return (
    <>
      {feedbacksData.length > 0 ? (
        <>
          {feedbacksData.map(feedbackValue => (
            <div
              className="rounded-medium border-[1px] my-4 drop-shadow-lg border-branding-secondary bg-white"
              key={feedbackValue.id}
            >
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between items-center rounded-medium py-5 px-8 text-left text-sm  font-medium text-purple-900  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      <p className="font-lg underline font-semibold">
                        {feedbackValue.module?.order}.{' '}
                        {feedbackValue.module?.name} - feedback
                      </p>
                      {open ? (
                        <img
                          src={AccordionCloseIcon}
                          alt="AccordionCloseIcon"
                        />
                      ) : (
                        <img src={AccordionOpenIcon} alt="AccordionOpenIcon" />
                      )}
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-gray-500">
                      <div className="pl-8 pb-8 pr-8">
                        <p className="text-base mt-4 mb-4">
                          {feedbackValue.moduleFeedback !== null &&
                            feedbackValue.moduleFeedback !== undefined &&
                            feedbackValue.moduleFeedback.substring(0, 170) +
                              '...'}
                        </p>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          ))}
        </>
      ) : (
        <p>You have no feedbacks associated with this course.</p>
      )}
    </>
  )
}
export default FeedbackAccordion
