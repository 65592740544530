import { useEffect, useState } from 'react'
import { OverlayProvider } from 'react-aria'
import HeadlessSlideOver from 'components/DrawerBase'
import Resources from 'components/module/Resources'
import { ModuleModel, UserModuleDataModel } from 'api/data-contracts'
import Notes from './notes/Notes'
import Contents from './Contents'
import { useModuleContext } from 'context/ModuleContext'
import { NoteContextProvider } from 'context/NoteContext'
import { contentDataModel } from 'utils/models/Content'
import { INCOMPLETE } from 'utils/constants/slide'
import { useParams } from 'react-router-dom'
import { RESOURCES, CONTENTS, NOTES } from 'utils/constants/drawerType'

interface DrawerProps {
  module: ModuleModel
  myModule: UserModuleDataModel
}

const Drawer = ({ myModule, module }: DrawerProps) => {
  const {
    currentSection,
    isResourcesOpen,
    setIsResourcesOpen,
    sidebarDrawerName,
    setSidebarDrawerName,
  } = useModuleContext()
  const { userCourseInstanceId } = useParams()
  const [moduleContentsData, setModuleContentsData] =
    useState<contentDataModel[]>()

  useEffect(() => {
    //sections where mySections ID matches Section ID?? console.log module?.sections & console.log response, what is the difference?
    const response = module?.sections?.map(section => {
      const data = myModule?.userModuleSectionDatas?.find(
        userModuleSection => userModuleSection?.section?.id === section?.id,
      )?.sectionProgress

      return {
        ...section,
        status: data || INCOMPLETE,
      }
    })
    setModuleContentsData(response)
  }, [myModule, module])

  if (!currentSection) {
    return null
  }

  const currentSectionData = myModule?.userModuleSectionDatas?.find(
    section => section?.section?.id === currentSection.id,
  )

  return (
    <OverlayProvider>
      <HeadlessSlideOver
        open={isResourcesOpen}
        setOpen={setIsResourcesOpen}
        setDrawerType={setSidebarDrawerName}
      >
        <div className="flex flex-col drop-shadow-2xl">
          {sidebarDrawerName === RESOURCES && (
            <Resources resources={module?.resources || []} />
          )}
          {sidebarDrawerName === NOTES && (
            <NoteContextProvider>
              <Notes
                currentSectionData={currentSectionData!}
                currentSectionId={currentSection.id!}
                userCourseInstanceId={userCourseInstanceId!}
              />
            </NoteContextProvider>
          )}
          {sidebarDrawerName === CONTENTS && (
            <Contents sections={moduleContentsData || []} />
          )}
        </div>
      </HeadlessSlideOver>
    </OverlayProvider>
  )
}

export default Drawer
