import { ModuleModel, SectionModel } from 'api/data-contracts'
import ProgressTracker from './ProgressTracker'
import { ReactComponent as ClosePanelMobileIcon } from 'assets/icon/close-panel-mobile.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { COURSE_DASHBOARD_BY_ID } from 'utils/constants/clientRoutes'
import { useEffect, useState } from 'react'
import { useModuleContext } from 'context/ModuleContext'
import { ASSESSMENT } from 'utils/constants/section'
import { useBranding } from 'utils/useBranding'
import AssessmentAwayMessage from 'components/AssessmentAwayMessage'

interface SectionProps {
  module?: ModuleModel
  totalSections?: number
  lastCompletedSectionOrder?: number
  currentSlide?: SectionModel
}
const Header = ({
  module,
  totalSections,
  lastCompletedSectionOrder,
  currentSlide,
}: SectionProps) => {
  const navigate = useNavigate()
  const { userCourseInstanceId } = useParams()
  const { branding } = useBranding()
  const {
    currentSection,
    assessment,
    setAssessment,
    isResourcesOpen,
    setIsResourcesOpen,
    setSidebarDrawerName,
  } = useModuleContext()

  const [activeTopbar, setActiveTopbar] = useState('Course')
  const [assessmentAwayMessageVisible, setAssessmentAwayMessageVisible] =
    useState(false)

  useEffect(() => {
    isResourcesOpen === false && setActiveTopbar('Course')
  }, [isResourcesOpen])

  const borderStyle = `5px solid transparent`
  const activeborderStyle = `8px solid ${branding.stylingPrimary}`

  const borderFunc = (navbarName: string) =>
    activeTopbar === navbarName ? activeborderStyle : borderStyle

  const onClickCloseButtonHandler = () => {
    navigate(
      COURSE_DASHBOARD_BY_ID.replace(
        ':userCourseInstanceId',
        userCourseInstanceId!,
      ),
    )
  }
  return (
    <>
      <div className="headerWrapper">
        <div className="headerBar bg-branding-primary lg:bg-transparent">
          <div className="flex items-center">
            <div
              onClick={
                Object.keys(assessment).length > 0
                  ? () => setAssessmentAwayMessageVisible(true)
                  : () => onClickCloseButtonHandler()
              }
            >
              <ClosePanelMobileIcon className="close-navbar" />
            </div>
            <div className="w-full">
              <div className="flex justify-between items-center md:items-start">
                <p className="text-[14px] lg:text-[18px] text-white lg:text-black font-bold block mb-0">
                  {module?.name ?? 'Section'}{' '}
                  {currentSection.id &&
                    currentSection?.order &&
                    '| ' + currentSection.order}
                  {!currentSection.id && currentSlide && currentSlide.order}
                </p>
                {currentSection.id && (
                  <ProgressTracker
                    lastCompletedSectionOrder={lastCompletedSectionOrder!}
                    totalSections={totalSections!}
                  />
                )}
              </div>
              <h2 className="text-[16px] lg:text-[36px] block lg:text-branding-primary my-[-5px] lg:my-0 text-white max-w-[300px] sm:max-w-none text-ellipsis overflow-hidden sm:overflow-auto whitespace-nowrap">
                {currentSection.id ? currentSection?.name : currentSlide?.name}
              </h2>
            </div>
          </div>
        </div>

        <div className="headerTopbar shadow-navbar flex">
          <div
            className="w-full text-center pt-4 pb-2 font-semibold cursor-pointer"
            style={{ borderBottom: borderFunc('Course') }}
            onClick={() => {
              setActiveTopbar('Course')
              setIsResourcesOpen && setIsResourcesOpen(false)
              setSidebarDrawerName && setSidebarDrawerName('')
            }}
          >
            Course
          </div>
          <div
            className={`w-full text-center pt-4 pb-2 font-semibold notes cursor-pointer
              ${
                currentSection?.sectionType === ASSESSMENT &&
                'pointer-events-none opacity-30'
              }`}
            style={{ borderBottom: borderFunc('Notes') }}
            onClick={() => {
              setActiveTopbar('Notes')
              setIsResourcesOpen && setIsResourcesOpen(true)
              setSidebarDrawerName && setSidebarDrawerName('Notes')
            }}
          >
            Notes
          </div>
          <div
            className={`w-full text-center pt-4 pb-2 font-semibold cursor-pointer
              ${
                currentSection?.sectionType === ASSESSMENT &&
                'pointer-events-none opacity-30'
              }`}
            style={{ borderBottom: borderFunc('Resources') }}
            onClick={() => {
              setActiveTopbar('Resources')
              setIsResourcesOpen && setIsResourcesOpen(true)
              setSidebarDrawerName && setSidebarDrawerName('Resources')
            }}
          >
            <span className="relative">
              Resources
              {currentSection?.sectionType !== ASSESSMENT &&
                module &&
                module?.resources && (
                  <button className="text-white w-5 h-5 hidden lg:block absolute right-[-27px] top-[-14px] sm:right-[-30px] sm:top-[-10px] text-xs rounded-full bg-error">
                    {module?.resources.length}
                  </button>
                )}
            </span>
          </div>
        </div>
      </div>

      <hr className="w-full lg:mt-3 h-[1px] bg-[#9D9D9D] rounded-[100px] border-0 desktop-hr"></hr>

      {assessmentAwayMessageVisible && (
        <AssessmentAwayMessage
          onStay={() => setAssessmentAwayMessageVisible(false)}
          onLeave={() => {
            setAssessmentAwayMessageVisible(false)
            onClickCloseButtonHandler()
            setAssessment({})
          }}
        />
      )}
    </>
  )
}

export default Header
