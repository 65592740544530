import { SectionModel } from 'api/data-contracts'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import {
  useGetMySectionAssessments,
  useStartMySectionAssessment,
} from 'utils/section'
import { Assessment } from './Assessment'
import { MaxScoredAssessment } from './MaxScoredAssessment'
import { useModuleContext } from 'context/ModuleContext'
import { COMPLETED, INPROGRESS, STARTED } from 'utils/constants/slide'
import { ASSESSOR } from 'utils/constants/sections'
import { LoaderSpinner } from 'components/LoaderSpinner'

interface UserAssessmentProps {
  section: SectionModel
}

export const UserAssessment = ({ section }: UserAssessmentProps) => {
  const { userCourseInstanceId } = useParams()
  const {
    assessment,
    setAssessment,
    assessmentCompletion,
    setAssessmentCompletion,
    currentSection,
  } = useModuleContext()
  const { mutateAsync: startMySectionAssessment } =
    useStartMySectionAssessment()

  const {
    assessments,
    isLoading: assessmentsLoading,
    isError: assessmentsError,
  } = useGetMySectionAssessments(section?.id as string)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (assessments) {
      const filterValue = MaxScoredAssessment(assessments)
      setAssessmentCompletion(filterValue)
      setIsLoading(false)
    }
  }, [assessments, setAssessmentCompletion])

  const StartAssessment = async () => {
    await startMySectionAssessment({
      sectionId: section.id!,
      data: {
        userCourseInstanceId: userCourseInstanceId,
      },
      onSuccess: data => {
        setAssessment(data)
      },
    })
  }

  const userHasAttemptsLeft =
    currentSection && assessments
      ? currentSection.assessmentNumberOfAttempts! >= assessments!.length
      : null
  if (assessmentsLoading || isLoading) {
    return <LoaderSpinner />
  }

  if (
    assessment?.assessmentProgress === COMPLETED &&
    !Object.keys(assessmentCompletion).length
  ) {
    return null
  }

  if (assessmentsError) {
    return <p>Error loading module data</p>
  }

  if (!assessments) {
    return <p>Module does not exist.</p>
  }

  const isRetakeButtonDisable =
    section.assessmentNumberOfAttempts !== 0 &&
    assessments.length >= section.assessmentNumberOfAttempts!

  return (
    <div>
      {assessment?.assessmentProgress === INPROGRESS ||
      assessment?.assessmentProgress === STARTED ? (
        <div>
          <Assessment
            section={section}
            assessmentId={assessment.id!}
            setAssessment={setAssessment!}
          />
        </div>
      ) : (
        <div className="flex flex-col gap-10">
          {currentSection.assessmentMarkProcess !== ASSESSOR ? (
            assessment?.assessmentProgress === COMPLETED ? (
              <div>
                <h3 className="mb-4">Assessment completed</h3>
                <span className="mb-2 block">
                  You scored : <strong>{assessment.assessmentScore}%</strong>
                </span>
                <span className="mb-2 block">
                  {assessment.assessmentPassed
                    ? 'Well done, you Passed!'
                    : 'Sorry, you failed the assessment.'}
                </span>
              </div>
            ) : (
              Object.keys(assessmentCompletion).length > 0 && (
                <div>
                  <h3 className="mb-4">Assessment summary</h3>
                  <p className="mb-2 block">
                    You scored :
                    <strong> {assessmentCompletion.assessmentScore}%</strong>
                  </p>
                  <p className="mb-2 block">
                    {assessmentCompletion.assessmentPassed
                      ? 'You have already passed this assessment.'
                      : 'Sorry, you failed the assessment.'}
                  </p>
                </div>
              )
            )
          ) : (
            <div>
              <h3 className="mb-4">Assessment completed</h3>
              <p className="mb-2 block">Please await feedback</p>
            </div>
          )}
          {(assessment?.assessmentProgress === '' ||
            !assessmentCompletion.assessmentPassed) &&
          (userHasAttemptsLeft ||
            currentSection.assessmentNumberOfAttempts === 0) ? (
            <>
              {assessments?.length ? <hr /> : null}
              <div>
                {!assessments?.length ? (
                  <h3 className="mb-4">Start your assessment</h3>
                ) : (
                  <h3
                    className={`mb-4 
                    ${
                      isRetakeButtonDisable &&
                      'opacity-50 !cursor-default pointer-events-none'
                    }`}
                  >
                    Retry the assessment
                  </h3>
                )}
                {section?.contentArea1 && (
                  <p className="mb-6 block">{section?.contentArea1}</p>
                )}
                <button
                  className="my-5 button button-long button-radius-large secondary-button-primary"
                  onClick={StartAssessment}
                >
                  {!assessments?.length ? 'Start' : 'Retry'}
                </button>
              </div>
            </>
          ) : null}
        </div>
      )}
    </div>
  )
}
