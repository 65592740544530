import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import VerifyIcon from 'assets/icon/Verify.svg'
import { CustomPasswordField } from 'components/CustomPasswordField'
import { ResetPasswordModel } from 'utils/models/Auth'
import { getOrganisationId } from 'utils/organisation'
import { useCompleteSetup } from 'utils/auth'
import AppBar from 'components/AppBar'
import * as ROUTE from 'utils/constants/clientRoutes'
import { passwordValidation } from 'utils/validation/passwords'

const validationSchema = Yup.object<ResetPasswordModel>().shape({
  password: passwordValidation,
  confirmpassword: Yup.string()
    .nullable()
    .oneOf([Yup.ref('password'), null], 'Both passwords should match')
    .required('Confirm password is required'),
})

const ParticipantInvitation = () => {
  const { mutateAsync: completeSetup } = useCompleteSetup()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const [passwordSetup, setPasswordSetup] = useState(false)
  const navigate = useNavigate()
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<ResetPasswordModel>({
    resolver: yupResolver(validationSchema),
  })
  const search = useLocation().search
  const token = new URLSearchParams(search).get('token')

  const onSubmit: SubmitHandler<ResetPasswordModel> = data => {
    const completeSetupData = {
      completeSetupToken: token,
      newPassword: data.password,
      organisationId: getOrganisationId(),
    }
    completeSetup(completeSetupData)
      .then(res => {
        setPasswordSetup(true)
      })
      .catch(error => {
        //console.log(error)
      })
  }

  return (
    <>
      <AppBar />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="container flex flex-wrap pt-12 md:pt-20 pb-10  px-5 mx-auto">
            <div className="w-full lg:w-2/4">
              {!passwordSetup && (
                <>
                  <h2 className="mt-5 mb-5 text-branding-primary">
                    Complete setup
                  </h2>
                  <h3 className="pt-5">Setting your password</h3>
                  <p className="pt-3">
                    Choose a password that is hard to guess and unique to this
                    account. Your password must...
                  </p>
                  <ul className="list-disc pl-5 pt-4 pb-5">
                    <li>Be 8-20 characters long</li>
                    <li>Contain at least one special character</li>
                    <li>Contain at least one capital letter</li>
                    <li>Contain at least one number</li>
                  </ul>

                  <CustomPasswordField
                    control={control}
                    errors={errors?.password}
                    name="password"
                    label="Password"
                    state={passwordVisible}
                    setState={setPasswordVisible}
                  />
                  <CustomPasswordField
                    control={control}
                    errors={errors?.confirmpassword}
                    name="confirmpassword"
                    label="Confirm password"
                    state={confirmPasswordVisible}
                    setState={setConfirmPasswordVisible}
                  />

                  <div className="w-full lg:w-3/4 my-auto mx-0">
                    <button
                      type="submit"
                      className="w-full py-4 button bg-branding-secondary"
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Set password
                    </button>
                  </div>
                </>
              )}
              {passwordSetup && (
                <>
                  <h2 className="mt-5 mb-5 text-branding-primary">
                    Setup Completed
                  </h2>
                  <div className="flex items-center pt-5 ">
                    <img src={VerifyIcon} alt="verifyIcon" />
                    <p className="pl-1 font-medium text-xl">
                      Your password was setup successfully
                    </p>
                  </div>
                  <div className="w-full lg:w-80 my-auto mx-0">
                    <button
                      className="w-full py-4 button mt-8 bg-branding-secondary"
                      onClick={() => navigate(ROUTE.LOGIN)}
                    >
                      Log in
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default ParticipantInvitation
