import parse from 'html-react-parser'
import { sectionColour } from 'utils/sectionColour'
import { sectionColourModel } from 'utils/constants/sectionColour'
import { OrganisationSettingModel } from 'api/data-contracts'

interface IntroPanelProps {
  settings: OrganisationSettingModel
  bgColour: sectionColourModel['bgColour']
}

const IntroPanel = ({ settings, bgColour }: IntroPanelProps) => {
  const { backgroundColor, textColor, headingColor } = sectionColour(bgColour)
  return (
    <section
      className={`${backgroundColor} px-9 py-20 lg:py-32 lg:px-24 section-border-grey`}
    >
      <div className="container mx-auto xl:px-12">
        <h2 className={`text-center sm:text-left ${headingColor}`}>
          {settings?.introTitle}
        </h2>
        <hr className="hr rounded-cap bg-branding-secondary"></hr>
        <p
          className={`xl:max-w-[80%] ${textColor} text-center sm:text-left font-medium`}
        >
          {settings?.introText && parse(settings?.introText)}
        </p>
      </div>
    </section>
  )
}

export default IntroPanel
