import {
  AddUserAssessmentAnswerItemModel,
  AnswerOptionModel,
} from 'api/data-contracts'

type QuestionProps = {
  answer: AnswerOptionModel
  setSelectedAnswer: (answer: AddUserAssessmentAnswerItemModel[]) => void
  answerItem: AddUserAssessmentAnswerItemModel
}

export const FreeText = ({
  answer,
  setSelectedAnswer,
  answerItem,
}: QuestionProps) => {
  return (
    <textarea
      rows={10}
      cols={50}
      placeholder="Type your answer here..."
      className="my-4 py-2 px-4 text-left w-[100%] border-main border-2 shadow-md"
      onChange={e =>
        setSelectedAnswer([
          {
            ...[answerItem],
            answerText: e.target.value,
          },
        ])
      }
    />
  )
}
