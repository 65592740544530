import { Control, Controller, MultipleFieldErrors } from 'react-hook-form'
interface CustomTextFieldProps {
  control?: Control
  errors?: {
    type?: string
    message?: string
    types?: MultipleFieldErrors
  }
  name: string
  label: string
  disable?: boolean
}

export const CustomTextField = ({
  control,
  errors,
  name,
  label,
  disable,
}: CustomTextFieldProps) => {
  return (
    <div className="w-full my-auto mx-0 mb-8">
      <Controller
        name={`${name}`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <div className="flex flex-col inputFieldDisable">
            <label className="text-grey text-small font-medium mb-2">
              {label}
            </label>
            <input
              className="focus:outline-none auth-input-field px-4 py-[0.625rem]"
              value={value === null || value === undefined ? '' : value}
              onChange={onChange}
              placeholder={label}
              autoComplete="off"
              disabled={disable ? true : false}
            ></input>
            {errors?.message !== undefined && (
              <p className="text-error mt-1 text-base font-medium">
                {errors?.message}
              </p>
            )}
          </div>
        )}
      />
    </div>
  )
}
