import { AnswerOptionModel } from 'api/data-contracts'
import DoneIcon from '../../../../../assets/icon/correct-answer.svg'
import CancelIcon from '../../../../../assets/icon/incorrect-answer.svg'

type QuestionProps = {
  answer: AnswerOptionModel
  setSelectedAnswer: (answer: AnswerOptionModel[]) => void
  selectedAnswer: AnswerOptionModel[]
  answerItem: AnswerOptionModel
  isQuestionSubmitted: boolean
}

export const SingleSelect = ({
  answer,
  setSelectedAnswer,
  selectedAnswer,
  answerItem,
  isQuestionSubmitted,
}: QuestionProps) => {
  const isSelected = selectedAnswer[0]?.id === answerItem.id
  const isCorrect = answerItem.isCorrect
  let classNames =
    'my-4 py-2 px-4 text-left w-[100%] border-main rounded-medium shadow-bottom w-[95%]'

  if (isQuestionSubmitted) {
    if (!isSelected && !isCorrect) {
      classNames += ' bg-light-grey youshouldnthavechosethisone'
    } else if (!isSelected && isCorrect) {
      classNames += ' text-correct youshouldhavechosethisone'
    } else if (isSelected && isCorrect) {
      classNames += '  border-2 text-correct welldone'
    } else if (isSelected && !isCorrect) {
      classNames += ' border-2 bg-light-grey incorrect'
    }
  } else if (isSelected) {
    classNames += ' border-2'
  }

  return (
    <div className="flex items-center">
      <button
        className={classNames}
        disabled={isQuestionSubmitted}
        onClick={() => setSelectedAnswer([answerItem])}
      >
        {answer?.answerText}
      </button>
      {isQuestionSubmitted &&
        (isSelected && isCorrect ? (
          <span className="ml-4 text-correct-500">
            <img src={DoneIcon} alt="DoneIcon" />
          </span>
        ) : isSelected && !isCorrect ? (
          <span className="ml-4 text-error-500">
            <img src={CancelIcon} alt="CancelIcon" />
          </span>
        ) : null)}
    </div>
  )
}
