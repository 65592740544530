import {
  CompletionModel,
  SectionModel,
  UserModuleSectionAssessmentModel,
} from 'api/data-contracts'
import React, { createContext, useContext, useState } from 'react'
import { defaultSectionModel } from 'utils/models/Auth'

type ModuleContextProps = {
  currentSection: SectionModel
  setCurrentSection: (section: SectionModel) => void
  assessmentCompletion: UserModuleSectionAssessmentModel
  setAssessmentCompletion: (value: UserModuleSectionAssessmentModel) => void
  moduleCompletion: boolean
  setModuleCompletion: (value: boolean) => void
  sectionIsRequired: boolean
  setSectionIsRequired: (value: boolean) => void
  assessment: UserModuleSectionAssessmentModel
  setAssessment: (value: UserModuleSectionAssessmentModel) => void
  moduleCompletionData: CompletionModel
  setModuleCompletionData: (value: CompletionModel) => void
  isResourcesOpen: boolean
  setIsResourcesOpen: (value: boolean) => void
  sidebarDrawerName: string
  setSidebarDrawerName: React.Dispatch<React.SetStateAction<string>>
}

const ModuleContext = createContext<ModuleContextProps>({
  currentSection: defaultSectionModel,
  setCurrentSection: () => {
    //initalized function
  },
  assessmentCompletion: {} as UserModuleSectionAssessmentModel,
  setAssessmentCompletion: () => {
    //initalized function
  },
  moduleCompletion: false,
  setModuleCompletion: () => {
    //initalized function
  },
  sectionIsRequired: false,
  setSectionIsRequired: () => {
    //initalized function
  },
  assessment: {} as UserModuleSectionAssessmentModel,
  setAssessment: () => {
    //initalized function
  },
  moduleCompletionData: {} as CompletionModel,
  setModuleCompletionData: () => {
    //initalized function
  },
  isResourcesOpen: false,
  setIsResourcesOpen: () => {
    //initalized function
  },
  sidebarDrawerName: '',
  setSidebarDrawerName: () => {
    //initalized function
  },
})

type ModuleContextProviderProps = {
  children: React.ReactNode
}

const ModuleContextProvider = ({ children }: ModuleContextProviderProps) => {
  const [currentSection, setCurrentSection] =
    useState<SectionModel>(defaultSectionModel)
  const [assessmentCompletion, setAssessmentCompletion] =
    useState<UserModuleSectionAssessmentModel>({})
  const [moduleCompletion, setModuleCompletion] = useState(false)
  const [sectionIsRequired, setSectionIsRequired] = useState(false)
  const [assessment, setAssessment] =
    useState<UserModuleSectionAssessmentModel>({})
  const [moduleCompletionData, setModuleCompletionData] =
    useState<CompletionModel>({})
  const [isResourcesOpen, setIsResourcesOpen] = useState(false)
  const [sidebarDrawerName, setSidebarDrawerName] = useState('')

  return (
    <ModuleContext.Provider
      value={{
        currentSection,
        setCurrentSection,
        assessmentCompletion,
        setAssessmentCompletion,
        moduleCompletion,
        setModuleCompletion,
        sectionIsRequired,
        setSectionIsRequired,
        assessment,
        setAssessment,
        moduleCompletionData,
        setModuleCompletionData,
        isResourcesOpen,
        setIsResourcesOpen,
        sidebarDrawerName,
        setSidebarDrawerName,
      }}
    >
      {children}
    </ModuleContext.Provider>
  )
}

const useModuleContext = () => useContext(ModuleContext)

export { ModuleContextProvider, useModuleContext }
