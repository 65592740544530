import {
  CompletionModel,
  ModuleModel,
  UserModuleDataModel,
} from 'api/data-contracts'
import Section from './section/Section'
import { ReactComponent as SlidePreviousIcon } from 'assets/icon/slide-prev.svg'
import { ReactComponent as SlideNextIcon } from 'assets/icon/slide-next.svg'
import { useModuleContext } from 'context/ModuleContext'
import { useUpdateMySectionProgress } from 'utils/section'
import { ModuleCompletion } from './section/ModuleCompletion'
import { ModuleCompleted } from './section/ModuleCompleted'
import { useCompleteMyModule } from 'utils/module'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { COMPLETED, INPROGRESS } from 'utils/constants/slide'
import { ASSESSMENT, MINIQUIZ } from 'utils/constants/section'
import AssessmentAwayMessage from 'components/AssessmentAwayMessage'
import { useQueryClient } from '@tanstack/react-query'
import ButtonLoaderSpinner from 'components/ButtonLoaderSpinner'

interface StageProps {
  module: ModuleModel
  myModule: UserModuleDataModel
  userCourseInstanceId: string
}
const Stage = ({ module, myModule, userCourseInstanceId }: StageProps) => {
  const {
    currentSection,
    setCurrentSection,
    assessmentCompletion,
    setAssessmentCompletion,
    moduleCompletion,
    setModuleCompletion,
    assessment,
    setAssessment,
    sectionIsRequired,
    setSectionIsRequired,
  } = useModuleContext()

  const { mutateAsync: updateMySectionProgress } = useUpdateMySectionProgress()
  const { mutateAsync: completionAsync } = useCompleteMyModule()
  const [finishButtonDisable, setFinishButtonDisable] = useState(false)
  const [moduleCompletionData, setModuleCompletionData] =
    useState<CompletionModel>()
  const [assessmentAwayMessageVisible, setAssessmentAwayMessageVisible] =
    useState(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    currentSection &&
      (currentSection.sectionType === MINIQUIZ
        ? setSectionIsRequired(true)
        : setSectionIsRequired(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection, myModule])

  if (!currentSection) {
    return null
  }

  const sections = module?.sections
  const moduleId = module?.id

  const nextSection = sections?.find(
    section => section?.order === currentSection.order! + 1,
  )

  const prevSection = sections?.find(
    section => section?.order === currentSection.order! - 1,
  )

  const currentSectionData = myModule?.userModuleSectionDatas?.find(
    section => section?.section?.id === currentSection.id,
  )

  const completedSections = myModule?.userModuleSectionDatas?.filter(
    userSection => userSection?.sectionProgress === COMPLETED,
  )

  const isFirstSection =
    sections && sections[0] && sections[0].id === currentSection.id

  const isFinalSection =
    sections?.[sections.length - 1].id === currentSection.id

  const lastCompletedSection = completedSections?.slice(-1)[0]

  const lastCompletedSectionOrder = lastCompletedSection
    ? sections?.find(section => section.id === lastCompletedSection.section?.id)
        ?.order
    : undefined

  const setInitialSectionValues = () => {
    setAssessmentCompletion({})
    setAssessment({})
    setSectionIsRequired(false)
    queryClient.invalidateQueries(['assessments'])
    queryClient.refetchQueries(['assessments'])
  }

  const handleNextClick = () => {
    setInitialSectionValues()

    if (currentSectionData?.sectionProgress === INPROGRESS) {
      updateMySectionProgress({
        sectionId: currentSection.id!,
        data: {
          userCourseInstanceId: userCourseInstanceId,
          sectionProgress: COMPLETED,
        },
      })
      // const nextSectionId = nextSection?.id
      // updateMySectionProgress({
      //   sectionId: nextSectionId!,
      //   data: {
      //     userCourseInstanceId: userCourseInstanceId,
      //     sectionProgress: INPROGRESS,
      //   },
      // })
    }
    nextSection && setCurrentSection(nextSection)
  }

  const onClickPreviousButtonHandler = () => {
    setInitialSectionValues()

    moduleCompletion
      ? setCurrentSection(currentSection)
      : prevSection && setCurrentSection(prevSection)

    setModuleCompletion(false)
  }

  const onClickFinishButtonHandler = async () => {
    setFinishButtonDisable(true)
    setInitialSectionValues()

    try {
      await completionAsync({
        moduleId: moduleId!,
        data: {
          userCourseInstanceId: userCourseInstanceId,
        },
        onSuccess: data => {
          setModuleCompletionData(data)
          setFinishButtonDisable(false)
          setModuleCompletion(true)
        },
      })
    } catch {
      toast.error('Unable to complete module')
    }
  }

  return (
    <>
      {sections && currentSection && (
        <section
          className={`bottomShadow stage flex w-[100%] px-6 lg:px-20 bg-white h-[100vh] overflow-auto scrollbar-hide`}
        >
          <div className="container relative mb-20 lg:my-20 mx-auto flex flex-col justify-between">
            {!moduleCompletion || currentSection?.order !== sections.length ? (
              <Section
                currentSection={currentSection}
                module={module!}
                totalSections={sections.length}
                lastCompletedSectionOrder={lastCompletedSectionOrder!}
              />
            ) : null}
            {moduleCompletionData && moduleCompletion && (
              <ModuleCompletion
                completionData={moduleCompletionData}
                userCourseInstanceId={userCourseInstanceId!}
                module={module!}
                totalSections={sections.length}
                lastCompletedSectionOrder={lastCompletedSectionOrder!}
              />
            )}
            {myModule &&
            myModule?.userModuleStatus === COMPLETED &&
            moduleCompletion &&
            currentSection.order === sections.length ? (
              <ModuleCompleted
                userCourseInstanceId={userCourseInstanceId!}
                module={module!}
                totalSections={sections.length}
                lastCompletedSectionOrder={lastCompletedSectionOrder!}
              />
            ) : null}

            <div
              className={`flex ${
                isFirstSection ? 'justify-end' : 'justify-between'
              }`}
            >
              {!isFirstSection && (
                <button
                  className="button button-module button-radius-large border-0 bg-branding-secondary mb-[45px] z-10"
                  onClick={
                    Object.keys(assessment).length > 0
                      ? () => setAssessmentAwayMessageVisible(true)
                      : () => onClickPreviousButtonHandler()
                  }
                  disabled={currentSection.order === 0}
                >
                  <SlidePreviousIcon />
                  <span className="pl-[10px]">Previous</span>
                </button>
              )}

              {!isFinalSection && currentSection.order !== sections.length ? (
                <>
                  {(currentSection.sectionType !== ASSESSMENT ||
                    assessmentCompletion.assessmentPassed === true) && (
                    <button
                      className="button button-module button-radius-large border-0 disabled:opacity-40 bg-branding-secondary mb-[45px] z-10"
                      onClick={handleNextClick}
                      disabled={
                        (sectionIsRequired &&
                          currentSectionData?.sectionProgress !==
                            'COMPLETED') ||
                        currentSectionData?.sectionProgress === undefined ||
                        (sectionIsRequired === undefined &&
                          currentSectionData?.sectionProgress !== 'COMPLETED')
                      }
                    >
                      <span className="pr-[10px]">Next</span>
                      <SlideNextIcon />
                    </button>
                  )}
                </>
              ) : null}

              {isFinalSection &&
              (myModule?.userModuleStatus === COMPLETED ||
                moduleCompletionData) &&
              !moduleCompletion ? (
                <>
                  {(currentSection.sectionType !== ASSESSMENT ||
                    assessmentCompletion.assessmentPassed === true) && (
                    <button
                      className="button button-module button-radius-large border-0 mb-[45px] bg-branding-secondary z-10"
                      onClick={() => setModuleCompletion(true)}
                    >
                      <span className="pr-[10px]">Next</span>
                      <SlideNextIcon />
                    </button>
                  )}
                </>
              ) : null}

              {isFinalSection &&
              myModule?.userModuleStatus !== COMPLETED &&
              !moduleCompletionData &&
              !moduleCompletion ? (
                <>
                  {(currentSection.sectionType !== ASSESSMENT ||
                    assessmentCompletion.assessmentPassed === true) && (
                    <button
                      className={`button button-module button-radius-large border-0 mb-[45px] bg-branding-secondary disabled:opacity-50 disabled:pointer-events-none z-10
                  ${finishButtonDisable && 'opacity-50 pointer-events-none'}`}
                      onClick={onClickFinishButtonHandler}
                      disabled={
                        currentSection.order === sections.length - 1 ||
                        (sectionIsRequired &&
                          currentSectionData?.sectionProgress !==
                            'COMPLETED') ||
                        currentSectionData?.sectionProgress === undefined ||
                        (sectionIsRequired === undefined &&
                          currentSectionData?.sectionProgress !== 'COMPLETED')
                      }
                    >
                      <span>Finish</span>
                      <ButtonLoaderSpinner
                        spinnerColor="#212121"
                        spinnerSize={17}
                        loadingState={finishButtonDisable}
                      />
                      <SlideNextIcon />
                    </button>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </section>
      )}
      {assessmentAwayMessageVisible && (
        <AssessmentAwayMessage
          onStay={() => setAssessmentAwayMessageVisible(false)}
          onLeave={() => {
            setAssessmentAwayMessageVisible(false)
            onClickPreviousButtonHandler()
            setAssessment({})
          }}
        />
      )}
    </>
  )
}

export default Stage
