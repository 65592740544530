import React from 'react'
import { NoteModel } from 'api/data-contracts'
import Button from 'components/Button'
import { toast } from 'react-toastify'
import { useDeleteNote } from 'utils/note'
import { useNoteContext } from 'context/NoteContext'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'

type Props = {
  userCourseInstanceId: string
  currentSectionId: string
  note: NoteModel
}

export const Note = ({
  userCourseInstanceId,
  currentSectionId,
  note,
}: Props) => {
  const { setShowNoteConfig, setNote } = useNoteContext()
  const { mutateAsync: deleteNote } = useDeleteNote()
  const [showPopup, setShowPopup] = React.useState(false)
  const [isExpanded, setIsExpanded] = React.useState(false) // Added state for note expansion

  const displayNoteConfig = () => {
    setShowNoteConfig(true)
    setNote(note)
  }

  const deleteSectionNote = () => {
    deleteNote(note.id!).then(res => {
      toast.success('Note deleted.')
    })
  }

  const togglePopup = () => {
    setShowPopup(!showPopup)
  }

  const toggleExpand = () => {
    // Added function to handle note expansion
    setIsExpanded(!isExpanded)
  }

  const shouldShowReadMore = (note.note?.length ?? 0) > 250 // Change this value to set the desired length

  return (
    <>
      <div
        className={isExpanded ? 'note-item my-5 mb-30' : 'note-item my-5  mb-5'}
      >
        <div
          className={`note-actions hover:bg-gray ${showPopup && 'bg-gray'}`}
          onClick={togglePopup}
        >
          ...
          {showPopup && (
            <div className="popup z-10">
              <Button
                className="px-2 edit-note text-base text-grey"
                onClick={displayNoteConfig}
              >
                Edit note
              </Button>
              <Button
                className="px-2 delete-note text-base"
                onClick={deleteSectionNote}
              >
                Delete note
              </Button>
            </div>
          )}
        </div>
        <p className="font-bold mb-3 text-base">{note.title}</p>
        <p
          className={`${
            isExpanded ? 'max-h-none' : 'max-h-[142px] notes-description'
          } overflow-hidden text-base`}
        >
          {note.note ?? ''}
        </p>
        <div className="flex justify-end">
          {shouldShowReadMore && (
            <Button
              className="note-readmore text-black block mb-[10px]"
              onClick={toggleExpand}
            >
              {isExpanded ? (
                <>
                  {'Read less'}
                  <KeyboardArrowUp />
                </>
              ) : (
                <>
                  {'Read more'}
                  <KeyboardArrowDown />
                </>
              )}
            </Button>
          )}
        </div>
        <hr className="w-full mt-[15px] mb-[25px] h-[1px] bg-light-grey rounded-cap border-0"></hr>
      </div>
    </>
  )
}
