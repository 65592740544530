import * as ROUTE from 'utils/constants/clientRoutes'
import VerifyIcon from 'assets/icon/Verify.svg'
import AppBar from 'components/AppBar'
import AnchorLink from 'components/AnchorLink'

const PaymentCompletion = () => {
  const paymentVerify = true
  return (
    <>
      <AppBar />
      <section className="px-9 pt-10 py-20 lg:py-32 lg:px-24">
        <div className="container mx-auto lg:px-12 flex flex-wrap justify-between gap-10 sm:gap-20 lg:gap-6">
          <div className="w-[100%] sm:mx-auto sm:w-[65%] lg:mx-0 lg:w-[47%]">
            <h1 className="mt-5 mb-3 text-branding-primary">
              Payment Completed
            </h1>
            <div className="w-[90%]">
              {paymentVerify ? (
                <>
                  <div className="flex items-center pt-5 mb-5">
                    <p className="pr-2 font-medium text-xl">
                      Your payment was successful
                    </p>{' '}
                    <img src={VerifyIcon} alt="verifyIcon" />
                  </div>
                  <h4>Thank you for purchasing a course</h4>
                  <div className="flex items-center pt-5 pb-5">
                    <p className="pl-1 font-regular text-small">
                      You will recieve an email notification once you have been
                      added to the course.
                    </p>
                  </div>
                  <div className="w-full lg:w-80 my-auto mx-0">
                    <AnchorLink
                      className="w-full py-4 button mt-2 bg-branding-secondary"
                      to={ROUTE.DASHBOARD}
                    >
                      Return to homepage
                    </AnchorLink>
                  </div>
                </>
              ) : (
                <>
                  <p className="pt-5 font-medium text-xl">
                    Your payment is being verified.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PaymentCompletion
