import { Fragment, useCallback, useEffect, useState } from 'react'
import AppBar from 'components/AppBar'
import Hero from 'components/Hero'
import IntroPanel from 'components/IntroPanel'
import MyCourses from 'components/MyCourses'
import ActiveCourses from 'components/ActiveCourses'
import ContentPanel from 'components/ContentPanel'
import CenteredContentPanel from 'components/CenteredContentPanel'
import Testimonials from 'components/Testimonials'
import Footer from 'components/Footer'

import { useUser } from 'utils/auth'
import {
  useGetActivePublicCourseInstancesByOrganisation,
  useGetSettings,
  useGetTestimonials,
} from 'utils/organisation'

// Constants
import {
  ACTIVECOURSES,
  CENTEREDCONTENTPANEL,
  CONTENTPANEL,
  INTRO,
  MYCOURSES,
  TESTIMONIALS,
} from 'utils/constants/sectionColour'
import {
  DashboardSectionModal,
  initialDashboardSections,
} from 'utils/models/DashboardSection'

const Dashboard = () => {
  const { user } = useUser()
  const { activePublicCourseInstances } =
    useGetActivePublicCourseInstancesByOrganisation()
  const { testimonials } = useGetTestimonials()
  const { settings } = useGetSettings()

  const [sectionToDisplay, setSectionToDisplay] = useState<
    DashboardSectionModal[]
  >(initialDashboardSections)

  const sectionVisibilityHandler = (key: string) => {
    const testimonialsIndex = sectionToDisplay.findIndex(
      section => section.key === key,
    )
    const updatedSections = [...sectionToDisplay]
    updatedSections[testimonialsIndex].visible = true
    setSectionToDisplay(updatedSections)
  }

  useEffect(() => {
    settings !== undefined &&
      Object.keys(settings).length > 0 &&
      sectionVisibilityHandler(INTRO)

    user !== null &&
      Object.keys(user).length > 0 &&
      sectionVisibilityHandler(MYCOURSES)

    activePublicCourseInstances !== undefined &&
      activePublicCourseInstances.length > 0 &&
      sectionVisibilityHandler(ACTIVECOURSES)

    settings !== undefined &&
      Object.keys(settings).length > 0 &&
      sectionVisibilityHandler(CONTENTPANEL)

    settings !== undefined &&
      Object.keys(settings).length > 0 &&
      sectionVisibilityHandler(CENTEREDCONTENTPANEL)

    testimonials !== undefined &&
      testimonials.length > 0 &&
      sectionVisibilityHandler(TESTIMONIALS)
    // To avoid re-render by sectionVisibilityHandler in dependency
    // eslint-disable-next-line
  }, [user, settings, activePublicCourseInstances, testimonials])

  const keysWithTrueVisibility = useCallback(() => {
    return sectionToDisplay.filter(item => item.visible).map(item => item.key)
  }, [sectionToDisplay])

  const backgroundColour = (key: string) => {
    const indexValue = keysWithTrueVisibility().findIndex(item => item === key)
    return indexValue % 3 === 0
      ? 'grey'
      : indexValue % 3 === 1
      ? 'white'
      : indexValue % 3 === 2
      ? 'branding'
      : ''
  }

  const visibleSectionCondition = (
    value: DashboardSectionModal,
    key: string,
  ) => {
    return value.key === key && value.visible === true
  }
  return (
    <>
      <AppBar />
      <div className="page-dashboard">
        <Hero />

        {sectionToDisplay.map((sectionValue, index) => (
          <Fragment key={index}>
            {visibleSectionCondition(sectionValue, INTRO) && (
              <IntroPanel
                settings={settings!}
                bgColour={backgroundColour(INTRO)}
              />
            )}
            {visibleSectionCondition(sectionValue, MYCOURSES) && (
              <MyCourses bgColour={backgroundColour(MYCOURSES)} />
            )}
            {visibleSectionCondition(sectionValue, ACTIVECOURSES) && (
              <ActiveCourses
                bgColour={backgroundColour(ACTIVECOURSES)}
                activePublicCourseInstances={activePublicCourseInstances}
              />
            )}
            {visibleSectionCondition(sectionValue, CONTENTPANEL) && (
              <ContentPanel
                settings={settings!}
                bgColour={backgroundColour(CONTENTPANEL)}
              />
            )}
            {visibleSectionCondition(sectionValue, CENTEREDCONTENTPANEL) && (
              <CenteredContentPanel
                settings={settings!}
                bgColour={backgroundColour(CENTEREDCONTENTPANEL)}
              />
            )}
            {visibleSectionCondition(sectionValue, TESTIMONIALS) && (
              <Testimonials
                testimonials={testimonials!}
                bgColour={backgroundColour(TESTIMONIALS)}
              />
            )}
          </Fragment>
        ))}

        <Footer />
      </div>
    </>
  )
}

export default Dashboard
