import { useState } from 'react'
import Profile from './Profile'
import ProfileUserDetail from './ProfileUserDetail'

interface AccountSubMenuProps {
  accountIndex: number
  setAccountIndex: (value: number) => void
  menuName: string
  menuIndex: number
}
const AccountSubMenu = ({
  accountIndex,
  setAccountIndex,
  menuName,
  menuIndex,
}: AccountSubMenuProps) => {
  return (
    <div
      className={`hover:bg-branding-primary hover:text-white  py-4 px-8 w-full sm:w-2/4 mb-2 font-semibold text-lg rounded-lg cursor-pointer
                ${
                  accountIndex === menuIndex
                    ? 'bg-branding-primary text-white'
                    : 'bg-white text-branding-primary'
                }`}
      onClick={() => setAccountIndex(menuIndex)}
    >
      {menuName}
    </div>
  )
}

const YourAccountDetails = () => {
  const [currentAccountIndex, setCurrentAccountIndex] = useState(1)

  return (
    <>
      <div className="min-w-full lg:min-w-[450px]">
        <div className="flex flex-col sm:flex-row lg:flex-col flex-wrap">
          <AccountSubMenu
            accountIndex={currentAccountIndex}
            setAccountIndex={setCurrentAccountIndex}
            menuName="Profile"
            menuIndex={1}
          />
          <AccountSubMenu
            accountIndex={currentAccountIndex}
            setAccountIndex={setCurrentAccountIndex}
            menuName="Course history"
            menuIndex={2}
          />
          <AccountSubMenu
            accountIndex={currentAccountIndex}
            setAccountIndex={setCurrentAccountIndex}
            menuName=" Payment"
            menuIndex={3}
          />
          <AccountSubMenu
            accountIndex={currentAccountIndex}
            setAccountIndex={setCurrentAccountIndex}
            menuName="Notifications"
            menuIndex={4}
          />
          <AccountSubMenu
            accountIndex={currentAccountIndex}
            setAccountIndex={setCurrentAccountIndex}
            menuName="Privacy"
            menuIndex={5}
          />
        </div>
        <hr className="sm:max-w-[200px] mt-8 mb-8" />
        <div className="hidden lg:block">
          <ProfileUserDetail />
        </div>
      </div>
      <div className="min-w-full sm:min-w-[450px]">
        {currentAccountIndex === 1 && <Profile />}
        {currentAccountIndex === 2 && <h4 className="mb-7">Course history</h4>}
        {currentAccountIndex === 3 && <h4 className="mb-7">Payments</h4>}
        {currentAccountIndex === 4 && <h4 className="mb-7">Notifications</h4>}
        {currentAccountIndex === 5 && <h4 className="mb-7">Privacy</h4>}
      </div>
    </>
  )
}

export default YourAccountDetails
