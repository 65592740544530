import { UserModuleSectionDataModule } from 'api/data-contracts'
import { Note } from './Note'
import { NoteConfig } from './NoteConfig'
import { useNoteContext } from 'context/NoteContext'
import React, { useState } from 'react'
import { useGetSectionNotes } from 'utils/section'

type Props = {
  userCourseInstanceId: string
  currentSectionId: string
  currentSectionData: UserModuleSectionDataModule
}

const Notes = ({
  userCourseInstanceId,
  currentSectionId,
  currentSectionData,
}: Props) => {
  const { showNoteConfig, setShowNoteConfig, note } = useNoteContext()
  const [showAddNote, setShowAddNote] = useState(false)

  const data = {
    sectionId: currentSectionId!,
    userCourseInstanceId: { userCourseInstanceId },
    userModuleSectionData: currentSectionData,
  }

  const { notes } = useGetSectionNotes(data)

  const addNote = () => {
    setShowNoteConfig(true)
    setShowAddNote(true)
  }

  return (
    <>
      <h3 className="hidden lg:block text-grey px-7 font-normal">Notes</h3>
      <hr className="hidden lg:block w-full mt-[20px] h-[1px] bg-[#9D9D9D] rounded-cap border-0"></hr>
      <div className="px-7 py-3 lg:py-7 relative">
        {showNoteConfig && showAddNote && (
          <div className="note-panel-addNote">
            <NoteConfig
              notes={notes!}
              currentSectionId={currentSectionId}
              userCourseInstanceId={userCourseInstanceId}
              setShowAddNote={setShowAddNote!}
            />
          </div>
        )}

        {showNoteConfig && note && (
          //get note somehow from note when edit is clicked....
          <div className="note-panel-addNote">
            <NoteConfig
              notes={notes!}
              currentSectionId={currentSectionId}
              userCourseInstanceId={userCourseInstanceId}
              note={note}
            />
          </div>
        )}

        {!showNoteConfig && (
          <>
            <div className="h-4 w-full absolute left-0 top-[100px] lg:top-[125px] shadow-[0_-9px_10px_-2px_rgba(0,0,0,0.05)]"></div>
            <button
              onClick={addNote}
              className="add-note-butt w-full button button-large button-radius-large mb-16 lg:mb-12 bg-branding-secondary"
            >
              Add new note
            </button>
            <div className="note-panel-notes">
              {notes && notes.length ? (
                notes.map(note => (
                  <React.Fragment key={note.id}>
                    <Note
                      key={note.id}
                      note={note}
                      currentSectionId={currentSectionId}
                      userCourseInstanceId={userCourseInstanceId}
                    />
                  </React.Fragment>
                ))
              ) : (
                <p className="font-normal text-lg mt-8 text-grey">
                  This section has no notes.
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Notes
