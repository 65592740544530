/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddMyAssessmentAnswerModel,
  NotFoundExceptionModel,
  UserModuleSectionAssessmentModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class UserAssessment<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags UserAssessment
   * @name GetUserAssessment
   * @request GET:/api/UserAssessment/{userAssessmentId}
   * @secure
   */
  getUserAssessment = (userAssessmentId: string, params: RequestParams = {}) =>
    this.request<UserModuleSectionAssessmentModel, NotFoundExceptionModel>({
      path: `/api/UserAssessment/${userAssessmentId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags UserAssessment
   * @name AddMyAssessmentAnswer
   * @request POST:/api/UserAssessment/{userAssessmentId}/answers/my
   * @secure
   */
  addMyAssessmentAnswer = (
    userAssessmentId: string,
    data: AddMyAssessmentAnswerModel,
    params: RequestParams = {},
  ) =>
    this.request<UserModuleSectionAssessmentModel, NotFoundExceptionModel>({
      path: `/api/UserAssessment/${userAssessmentId}/answers/my`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
}
