import {
  AddUserAssessmentAnswerItemModel,
  AnswerOptionModel,
  QuestionModel,
} from 'api/data-contracts'
import React from 'react'
import { useEffect, useState } from 'react'
import { FreeText } from './answerTypes/FreeText'
import { MultiSelect } from './answerTypes/MultiSelect'
import { SingleSelect } from './answerTypes/SingleSelect'
import { SpecificOrder } from './answerTypes/SpecificOrder'
import { SpecificOrderWrapper } from './answerTypes/SpecificOrderWrapper'
import {
  FREETEXT,
  MULTISELECT,
  SINGLESELECT,
  SPECIFICORDER,
} from 'utils/constants/questions'
import renderQuestionSubtitle from 'components/module/RenderQuestionSubtitle'

type QuestionProps = {
  question: QuestionModel
  selectedAnswer: AddUserAssessmentAnswerItemModel[]
  setSelectedAnswer: (answer: AddUserAssessmentAnswerItemModel[]) => void
}

export const Question = ({
  question,
  setSelectedAnswer,
  selectedAnswer,
}: QuestionProps) => {
  const [answerOptions, setAnswerOptions] = useState<AnswerOptionModel[]>([])

  useEffect(() => {
    if (question.questionType === SPECIFICORDER && question.answerOptions) {
      const newAnswerOptions = question.answerOptions.map(answer => ({
        id: answer.id!,
        answerText: answer.answerText!,
        answerOrder: answer.answerOrder!,
      }))
      setAnswerOptions(newAnswerOptions)
      const updatedAnswers = newAnswerOptions.map(mapAnswerOptionToAnswerItem)
      setSelectedAnswer(updatedAnswers)
    } else {
      setAnswerOptions([])
    }
  }, [question, setSelectedAnswer])

  function mapAnswerOptionToAnswerItem(
    answer: AnswerOptionModel,
    index: number,
  ): AddUserAssessmentAnswerItemModel {
    return {
      answerOptionId: answer.id!,
      answerOrder: index + 1,
      answerText: answer.answerText!,
    }
  }

  const renderAnswerOption = (answer?: AnswerOptionModel, index?: number) => {
    const answerItem: AddUserAssessmentAnswerItemModel = {
      answerOptionId: answer?.id,
      answerOrder: answer?.answerOrder,
      answerText: answer?.answerText,
    }

    switch (question.questionType) {
      case 'FREETEXT':
        return (
          <FreeText
            answer={answer!}
            answerItem={answerItem}
            setSelectedAnswer={setSelectedAnswer}
          />
        )

      case MULTISELECT:
        return (
          <MultiSelect
            answer={answer!}
            answerItem={answerItem}
            setSelectedAnswer={setSelectedAnswer}
            selectedAnswer={selectedAnswer}
          />
        )

      case SINGLESELECT:
        return (
          <SingleSelect
            answer={answer!}
            answerItem={answerItem}
            setSelectedAnswer={setSelectedAnswer}
            selectedAnswer={selectedAnswer}
          />
        )

      case SPECIFICORDER:
        return <SpecificOrder answer={answer!} index={index!} />
    }
  }

  return (
    <div id={question.id}>
      <h3 className="my-5" id={`question-${question.id}`}>
        {question.question}
      </h3>
      {renderQuestionSubtitle(question.questionType!)}
      {question.questionType === SPECIFICORDER && question.answerOptions ? (
        <>
          <SpecificOrderWrapper
            question={question!}
            answerOptions={answerOptions!}
            setAnswerOptions={setAnswerOptions!}
            setSelectedAnswer={setSelectedAnswer}
          >
            {answerOptions &&
              answerOptions.map((answer, index) => (
                <React.Fragment key={answer.id}>
                  {renderAnswerOption(answer, index)}
                </React.Fragment>
              ))}
          </SpecificOrderWrapper>
        </>
      ) : question.questionType === SINGLESELECT ||
        question.questionType === MULTISELECT ? (
        <>
          {question.answerOptions &&
            question.answerOptions.map((answer, index) => (
              <React.Fragment key={answer.id}>
                {renderAnswerOption(answer, index)}
              </React.Fragment>
            ))}
        </>
      ) : question.questionType === FREETEXT ? (
        <div className="my-5 mb-2">{renderAnswerOption()}</div>
      ) : null}
    </div>
  )
}
