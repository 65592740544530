import {
  INTRO,
  MYCOURSES,
  ACTIVECOURSES,
  CONTENTPANEL,
  CENTEREDCONTENTPANEL,
  TESTIMONIALS,
} from 'utils/constants/sectionColour'

export interface DashboardSectionModal {
  key: string
  visible: boolean
}
export const initialDashboardSections = [
  {
    key: INTRO,
    visible: false,
  },
  {
    key: MYCOURSES,
    visible: false,
  },
  {
    key: ACTIVECOURSES,
    visible: false,
  },
  {
    key: CONTENTPANEL,
    visible: false,
  },
  {
    key: CENTEREDCONTENTPANEL,
    visible: false,
  },
  {
    key: TESTIMONIALS,
    visible: false,
  },
]
