import * as Yup from 'yup'
import { useEffect, useMemo } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { CustomTextField } from 'components/CustomTextField'
import { useGetProfile } from 'utils/auth'
import { UserModel } from 'api/data-contracts'
import { useUpdateUser } from 'utils/user'
import { toast } from 'react-toastify'

const validationSchema = Yup.object<UserModel>().shape({
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
})

const Profile = () => {
  const { profile } = useGetProfile()
  const { mutateAsync: updateUser } = useUpdateUser()

  const initalValues = useMemo(() => {
    return {
      firstname: profile?.firstname,
      lastname: profile?.lastname,
      email: profile?.email,
    }
  }, [profile])

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<UserModel>({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(() => {
      return initalValues
    }, [initalValues]),
  })

  useEffect(() => {
    reset(initalValues)
  }, [initalValues, reset])

  const onSubmit: SubmitHandler<UserModel> = async data => {
    try {
      profile && (await updateUser({ userid: profile.id!, data: data }))
      toast.success('User details updated')
    } catch {
      toast.error('Unable to Update user details')
    }
  }

  return (
    <>
      <h4 className="mb-7">Your profile</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomTextField
          control={control}
          errors={errors?.firstname}
          name="firstname"
          label="First name"
        />
        <CustomTextField
          control={control}
          errors={errors?.lastname}
          name="lastname"
          label="Last name"
        />
        <CustomTextField
          control={control}
          errors={errors?.email}
          name="email"
          label="email address"
        />
        <button type="submit" className="button bg-branding-secondary">
          Save changes
        </button>
      </form>
    </>
  )
}

export default Profile
