import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useBranding } from 'utils/useBranding'
import { generateMediaPath } from 'utils/generateMediaPath'
import NavSearchInputField from './NavSearchInputField'
import Navbutton from './Navbutton'
import { useGetProfile, useSignOut, useUser } from 'utils/auth'

// Icons
import NavDropdownIcon from '../assets/icon/burger-menu.svg'
// import HomeIcon from '../assets/icon/home.svg'
// import SearchIcon from '../assets/icon/search.svg'

// Constants
import * as ROUTE from '../utils/constants/clientRoutes'

const AppBar = () => {
  const navigate = useNavigate()
  const { branding } = useBranding()
  const { user } = useUser()
  const { profile } = useGetProfile()
  const { signOut } = useSignOut()

  const [isScroll, setIsScroll] = useState(false)
  const [isNavbarOpen, setIsNavbarOpen] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset
      if (currentScroll > 150) {
        setIsScroll(true)
      } else {
        setIsScroll(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const navbarToggleHandler = () => {
    setIsNavbarOpen(!isNavbarOpen)
  }

  return (
    <div
      className={`header site_header top-0 right-0 left-0 w-full fixed ease-in duration-300 z-50 bg-branding-primary ${
        isScroll ? 'sm:py-2 shadow-navbar' : 'sm:py-5'
      } pl-9 pr-6 sm:px-16 py-5`}
    >
      <div className="container flex items-center justify-between py-2 mx-auto">
        <div className="logo">
          <Link to={ROUTE.DASHBOARD}>
            <img
              className="h-8 sm:h-10"
              src={generateMediaPath(branding.companyLogo!)}
              alt="logo"
            />
          </Link>
        </div>

        <Link to="/" className="desktopView flex">
          <span className="text-white font-bold">Home</span>
        </Link>

        <div className="mobileView hamburgerIcon flex">
          {profile && (
            <Link
              to="/profile"
              className="cursor-pointer flex flex-col justify-center items-center mr-4"
            >
              <svg width="28" height="28" viewBox="0 0 35 35">
                <path
                  id="user-circle-svgrepo-com"
                  d="M29.667,30.926c-.919-4.148-4.381-6.593-10.666-6.593S9.252,26.778,8.333,30.926M19,35A16,16,0,1,0,3,19,16,16,0,0,0,19,35Zm0-16c2.37,0,3.556-1.27,3.556-4.444S21.37,10.111,19,10.111s-3.556,1.27-3.556,4.444S16.63,19,19,19Z"
                  transform="translate(-1.5 -1.5)"
                  fill="transparent"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </svg>
              <span className="text-white font-bold text-small pt-1">
                {profile?.firstname}
              </span>
            </Link>
          )}
          <div onClick={navbarToggleHandler}>
            <img
              className="mobileView cursor-pointer"
              src={NavDropdownIcon}
              alt="NavDropdownIcon"
            />
            <p className="text-white font-bold text-small pt-3">Menu</p>
          </div>
        </div>
        <div className="desktopView">
          {user ? (
            <div className="flex items-center">
              {profile && (
                <Link
                  to="/profile"
                  className="cursor-pointer flex items-center"
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 35 35"
                    className="mr-3"
                  >
                    <path
                      id="user-circle-svgrepo-com"
                      d="M29.667,30.926c-.919-4.148-4.381-6.593-10.666-6.593S9.252,26.778,8.333,30.926M19,35A16,16,0,1,0,3,19,16,16,0,0,0,19,35Zm0-16c2.37,0,3.556-1.27,3.556-4.444S21.37,10.111,19,10.111s-3.556,1.27-3.556,4.444S16.63,19,19,19Z"
                      transform="translate(-1.5 -1.5)"
                      fill="transparent"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                  <span className="text-white font-bold mr-10 leading-none">
                    {profile?.firstname}
                  </span>
                </Link>
              )}
              <button
                className="button button-wide border text-white bg-branding-primary"
                onClick={() => signOut()}
              >
                Log out
              </button>
            </div>
          ) : (
            <>
              {user ? (
                <Navbutton
                  label="Log out"
                  className="button button-wide border text-white bg-branding-primary"
                  setAction={signOut}
                />
              ) : (
                <>
                  <Navbutton
                    label="Log in"
                    className="button button-wide border text-white bg-branding-primary nav-button-primary"
                    setAction={() => navigate(ROUTE.LOGIN)}
                  />
                  <Navbutton
                    label="Register"
                    className="button button-wide border text-branding-primary nav-button-secondary ml-4"
                    setAction={() => navigate(ROUTE.REGISTER)}
                  />
                </>
              )}

              {/* <img
                className="ml-4 cursor-pointer"
                src={SearchIcon}
                alt="search"
                onClick={() => setIsSearchOpen(!isSearchOpen)}
              /> */}
            </>
          )}
        </div>
      </div>

      {/***** search input field for mobile only ******/}
      <div className={`${isSearchOpen ? 'block' : 'hidden'} my-2 sm:hidden`}>
        <NavSearchInputField
          action={isSearchOpen}
          setAction={setIsSearchOpen}
        />
      </div>

      {/********** Mobile View and Tablet View **************/}
      {isNavbarOpen && (
        <div className="pt-3 mobileView active ease-in duration-300">
          <hr className="w-100 bg-white my-2 h-[1px] border-none" />
          {/* <Link to="/" className="py-2 text-white font-bold text-large">

            Home
          </Link> */}
          <div className="flex mt-2">
            {user ? (
              <Navbutton
                label="Log out"
                className="button button-wide border text-white bg-branding-primary my-3"
                setAction={signOut}
              />
            ) : (
              <>
                <Navbutton
                  label="Log in"
                  className="button button-wide border text-white bg-branding-primary my-3"
                  setAction={() => navigate(ROUTE.LOGIN)}
                />
                <Navbutton
                  label="Register"
                  className="button button-wide border text-branding-primary ml-4 my-3"
                  setAction={() => navigate(ROUTE.REGISTER)}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default AppBar
