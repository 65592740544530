import AppBar from 'components/AppBar'
import Footer from 'components/Footer'
import DOMPurify from 'dompurify'
import parse from 'html-react-parser'

// API - custom hooks
import { useGetPolicy } from 'utils/policyDocuments'

const Policy = () => {
  const { policyData } = useGetPolicy()
  const clean = (html: string) => DOMPurify.sanitize(html)

  return (
    <>
      <AppBar />
      {policyData && (
        <div className="px-6 py-20">
          <div className="mx-auto w-full container pt-10 pb-20 lg:px-12">
            <h2 className="mb-3 lg:mb-6 text-branding-primary">
              {policyData?.name ?? ''}
            </h2>
            <hr className="hr rounded-cap mx-auto bg-branding-secondary"></hr>
            <div className="xl:max-w-[80%]">
              {parse(clean(policyData?.content ?? ''))}
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  )
}

export default Policy
