import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  AddMyAssessmentAnswerModel,
  UserModuleSectionAssessmentModel,
} from 'api/data-contracts'
import invariant from 'tiny-invariant'
import { useApiClient } from './use-clients'

// GET:/api/UserAssessment/{userAssessmentId}
const useGetUserAssessment = (userAssessmentId: string) => {
  invariant(userAssessmentId, 'Invalid assessment id')
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['userAssessment', { userAssessmentId }],
    queryFn: () => client.userAssessment.getUserAssessment(userAssessmentId),
    enabled: !!userAssessmentId,
  })
  return {
    ...result,
    userAssessment: result.data?.data,
  }
}

// POST:/api/UserAssessment/{userAssessmentId}/answers/my
export type GetUserAssessmentProps = {
  userAssessmentId: string
  data: AddMyAssessmentAnswerModel
  onSuccess: (data: UserModuleSectionAssessmentModel) => void
}
const useAddMyAssessmentAnswer = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (props: GetUserAssessmentProps) =>
      client.userAssessment.addMyAssessmentAnswer(
        props.userAssessmentId,
        props.data,
      ),
    onSuccess: async (data, props) => {
      queryClient.setQueryData(['assessmentAnswerResponse'], data)
      queryClient.refetchQueries(['assessmentAnswerResponse'])
      queryClient.invalidateQueries(['assessments'])
      queryClient.refetchQueries(['assessments'])
      const assessment = data.data
      props.onSuccess(assessment!)
    },
  })
  return mutation
}

export { useGetUserAssessment, useAddMyAssessmentAnswer }
