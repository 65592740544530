import { useCallback, useEffect, useState } from 'react'
import Tabs from 'components/Tabs'
import Footer from 'components/Footer'
import { useParams } from 'react-router-dom'
import { useGetMyUserCourseInstance } from 'utils/userCourseInstance'
import AppBar from 'components/AppBar'
import { defaultSectionModel } from 'utils/models/Auth'
import SideActionPanel from './SideActionPanel'

// Contexts
import { useModuleContext } from 'context/ModuleContext'

const CourseDetails = () => {
  const { userCourseInstanceId } = useParams()
  const { setCurrentSection } = useModuleContext()
  const { userCourseInstance } = useGetMyUserCourseInstance(
    userCourseInstanceId!,
  )
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const allFeedbacks = useCallback(() => {
    return userCourseInstance?.userModuleDatas?.filter(
      userModuleValue => userModuleValue.moduleFeedback !== null,
    )
  }, [userCourseInstance])

  useEffect(() => {
    setCurrentSection(defaultSectionModel)
  }, [setCurrentSection])

  return (
    <>
      <AppBar />
      <div className="page-coursedashboard">
        <section
          className="bg-light-grey px-9 pt-10 pb-20 lg:py-16 lg:px-24 border-b-[1px]"
          style={{ borderColor: '#CCCDCD' }}
        >
          <div className="container mx-auto lg:px-12 flex gap-6">
            <div className="justify-start">
              <div className="pb-3 flex justify-start flex-col">
                <h2 className="text-branding-primary">
                  {userCourseInstance?.courseInstance?.title}
                </h2>
                <hr className="my-4 w-[35%] bg-branding-secondary mt-6 h-1 rounded-cap border-white" />
                <h3 className="pt-4 font-normal text-branding-primary">
                  {userCourseInstance?.currentModule?.name}
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white px-9 pt-10 pb-20 lg:px-24 lg:py-12">
          {userCourseInstance && (
            <div className="container mx-auto flex flex-col lg:flex-row px-0 lg:px-12 lg:justify-between">
              <div className="w-full lg:w-auto lg:flex-grow max-w-[664px] xxl:max-w-[800px]">
                <Tabs
                  userCourseInstance={userCourseInstance}
                  selectedTabIndex={selectedTabIndex}
                  setSelectedTabIndex={setSelectedTabIndex}
                  allFeedbacks={allFeedbacks}
                />
              </div>
              <div className="w-full max-w-[400px] flex flex-col">
                <SideActionPanel
                  userCourseInstance={userCourseInstance}
                  setSelectedTabIndex={setSelectedTabIndex}
                  allFeedbacks={allFeedbacks}
                />
              </div>
            </div>
          )}
        </section>

        <Footer />
      </div>
    </>
  )
}
export default CourseDetails
