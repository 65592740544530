import { Disclosure } from '@headlessui/react'
import { ModuleModel } from 'api/data-contracts'

type Props = {
  module: ModuleModel
}
export default function ModuleAccordion(props: Props) {
  return (
    <div className="rounded-medium my-4 drop-shadow-lg bg-white text-branding-primary">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full items-center justify-between rounded-medium bg-white px-5 py-4 text-left text-md font-medium hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
              <span>
                <h4 className="font-medium">{props.module.name}</h4>
              </span>

              {!open ? (
                <span className="accordion accordion-open"></span>
              ) : (
                <span className="accordion accordion-close"></span>
              )}
            </Disclosure.Button>
            <Disclosure.Panel className="px-5 pb-4 text-md w-[90%]">
              <div className="flex flex-row justify-between text-black">
                <span>{props.module.description}</span>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}
