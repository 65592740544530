import { ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ModuleContextProvider } from './ModuleContext'
import Toast from 'components/module/Toast'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

type Props = { children: ReactNode }

const AppProviders = ({ children }: Props) => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={true} />
    <ModuleContextProvider>
      <BrowserRouter>{children}</BrowserRouter>
      <Toast />
    </ModuleContextProvider>
  </QueryClientProvider>
)

export { AppProviders }
