import { useState } from 'react'
import CheckAccount from './CheckAccount'
import CreateAccount from './CreateAccount'
import AuthPageWrapper from '../AuthPageWrapper'

const Register: React.FC = () => {
  // Toggle Form
  const [accountExist, setAccountExist] = useState(true)

  // User Registration Form Toggle
  const [userCreated, setUserCreated] = useState(false)

  // For Setting up email default value
  const [emailDefaultValue, setEmailDefaultValue] = useState('')

  return (
    <>
      {userCreated ? (
        <AuthPageWrapper
          title="Success! Your account has been created"
          showPanel={false}
        >
          <p className="pt-3">
            An email has been sent to{' '}
            <span className="font-bold">{emailDefaultValue}. </span>
            Please click on the link to verify your email.
          </p>
        </AuthPageWrapper>
      ) : (
        <AuthPageWrapper
          title="Register for an account"
          showPanel={true}
          PanelTitle="Already have an account?"
          PanelButtonText="Log in"
          PanelButtonLink="/login"
        >
          {accountExist ? (
            <CheckAccount
              setEmailDefaultValue={setEmailDefaultValue}
              setAccountExist={setAccountExist}
            />
          ) : (
            <CreateAccount
              setUserCreated={setUserCreated}
              emailDefaultValue={emailDefaultValue}
              setAccountExist={setAccountExist}
            />
          )}
        </AuthPageWrapper>
      )}
    </>
  )
}

export default Register
