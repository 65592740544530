import { useQuery } from '@tanstack/react-query'
import { useApiClient } from './use-clients'

interface BrandingType {
  companyLogo?: string
  stylingPrimary: string
  stylingSecondary: string
  stylingInfo: string
}

const defaultBranding: BrandingType = {
  companyLogo: '',
  stylingPrimary: '#fff',
  stylingSecondary: '#fff',
  stylingInfo: '#fff',
}

// GET:/api/Organisation/{domain}/branding
// branding(domain)
const useBranding = () => {
  const domain = window.location.hostname
  const port = window.location.port
  const url = domain + (port ? ':' + port : '')
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['branding'],
    queryFn: () => client.organisation.branding(url),
    staleTime: Infinity,
    cacheTime: Infinity,
  })
  return {
    ...result,
    brandingId: result.data?.data?.id,
    branding: result.data?.data ?? defaultBranding,
    paymentKey: result.data?.data?.paymentKey,
  }
}

export { useBranding }
