import { AnswerOptionModel } from 'api/data-contracts'
import DoneIcon from '../../../../../assets/icon/correct-answer.svg'
import CancelIcon from '../../../../../assets/icon/incorrect-answer.svg'

type QuestionProps = {
  answer: AnswerOptionModel
  setSelectedAnswer: (answer: AnswerOptionModel[]) => void
  selectedAnswer: AnswerOptionModel[]
  answerItem: AnswerOptionModel
  isQuestionSubmitted: boolean
}

export const MultiSelect = ({
  answer,
  setSelectedAnswer,
  selectedAnswer,
  answerItem,
  isQuestionSubmitted,
}: QuestionProps) => {
  const allIsSelected = selectedAnswer.some(
    selected => selected.id === answer?.id,
  )
  const isCorrect = answerItem.isCorrect
  let classNames =
    'text-lg shadow-bottom rounded-medium w-[100%] border-main block px-5 py-3 cursor-pointer'
  if (isQuestionSubmitted) {
    if (!allIsSelected && !isCorrect) {
      classNames += ' bg-light-grey youshouldnthavechosethisone'
    } else if (!allIsSelected && isCorrect) {
      classNames += ' font-semibold text-correct youshouldhavechosethisone'
    } else if (allIsSelected && isCorrect) {
      classNames += ' options-border font-semibold text-correct welldone'
    } else if (allIsSelected && !isCorrect) {
      classNames += ' options-border bg-light-grey incorrect'
    }
  } else if (selectedAnswer.some(selected => selected.id === answer?.id)) {
    classNames += ' options-border'
  }

  return (
    <div className="flex-nowrap flex items-center mb-6">
      <div className="w-[80%] lg:w-[90%]">
        <label
          key={answer?.id}
          className={`${classNames} border-2 ${
            classNames.match('options-border')
              ? 'border-branding-primary'
              : 'border-white'
          }`}
        >
          <input
            type="checkbox"
            disabled={isQuestionSubmitted}
            className={`hidden border rounded-medium border-main p-2 cursor-pointer ${
              selectedAnswer.some(selected => selected.id === answer?.id)
                ? 'bg-main text-white'
                : ''
            }`}
            onChange={event => {
              if (event.target.checked) {
                setSelectedAnswer([...selectedAnswer, answerItem])
              } else {
                setSelectedAnswer(
                  selectedAnswer.filter(selected => selected.id !== answer?.id),
                )
              }
            }}
            checked={selectedAnswer.some(
              selected => selected.id === answer?.id,
            )}
          />
          {answer?.answerText}
        </label>
      </div>
      {isQuestionSubmitted &&
        (allIsSelected && isCorrect ? (
          <span className="ml-4 text-correct-500">
            <img src={DoneIcon} alt="DoneIcon" />
          </span>
        ) : allIsSelected && !isCorrect ? (
          <span className="ml-4 text-error-500">
            <img src={CancelIcon} alt="CancelIcon" />
          </span>
        ) : null)}
    </div>
  )
}
