import { Stripe } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'

const fetchPaymentKey = async (
  paymentKey: string,
  setStripe: React.Dispatch<React.SetStateAction<Stripe | null>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
): Promise<void> => {
  try {
    const stripePromise = await loadStripe(paymentKey)
    setStripe(stripePromise)
  } catch (error) {
    setErrorMessage('Failed to load paymentKey. Please try again later.')
  }
}

export default fetchPaymentKey
