import Sidebar from 'components/module/Sidebar'
import Stage from 'components/module/Stage'
import useSetInitialSection from 'utils/useSetInitialSection'
import { useParams } from 'react-router-dom'
import { useGetModuleData, useGetMyModuleData } from 'utils/module'
import { LoaderSpinner } from 'components/LoaderSpinner'
import { useEffect } from 'react'
import { useModuleContext } from 'context/ModuleContext'
import { ASSESSMENT } from 'utils/constants/section'

const Module = () => {
  const { currentSection, setIsResourcesOpen, setSidebarDrawerName } =
    useModuleContext()

  const { userCourseInstanceId, moduleId } = useParams()

  const {
    module: myModule,
    isError: myModuleError,
    isLoading: myModuleLoading,
  } = useGetMyModuleData(moduleId!, userCourseInstanceId)
  const {
    module,
    isError: moduleError,
    isLoading: moduleLoading,
  } = useGetModuleData(moduleId!)

  useEffect(() => {
    currentSection?.sectionType === ASSESSMENT && setIsResourcesOpen(false)
    currentSection?.sectionType === ASSESSMENT && setSidebarDrawerName('')
  }, [currentSection, setIsResourcesOpen, setSidebarDrawerName])

  useSetInitialSection(module!, userCourseInstanceId!, myModule!)

  if (moduleLoading || myModuleLoading) {
    return <LoaderSpinner />
  }

  if (moduleError || myModuleError) {
    return <p>Error loading module data</p>
  }

  if (!module || !myModule) {
    return <p>Module does not exist.</p>
  }

  return (
    <div className="flex overflow-hidden bg-branding-primary">
      <Stage
        module={module}
        myModule={myModule}
        userCourseInstanceId={userCourseInstanceId!}
      />

      <div className="clippy-shadow shadow-top-right">
        <div className="rounded-border"></div>
      </div>
      <div className="clippy-shadow shadow-bottom-right">
        <div className="rounded-border"></div>
      </div>

      <Sidebar module={module} myModule={myModule} />
    </div>
  )
}

export default Module
