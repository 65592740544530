import { OrganisationSettingModel } from 'api/data-contracts'
import parse from 'html-react-parser'
import { sectionColourModel } from 'utils/constants/sectionColour'
import { sectionColour } from 'utils/sectionColour'

type Props = {
  settings: OrganisationSettingModel
  bgColour: sectionColourModel['bgColour']
}

const CenteredContentPanel = ({ settings, bgColour }: Props) => {
  const { backgroundColor, textColor, headingColor, borderColor } =
    sectionColour(bgColour)
  return (
    <section
      className={`${backgroundColor} px-9 py-20 lg:py-32 lg:px-24 section-border-grey`}
    >
      <div className="container mx-auto xl:px-12">
        <div className="mb-12 flex justify-center">
          <h2 className={`${headingColor} font-black`}> How it works</h2>
        </div>
        <div
          className={`${textColor} grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12`}
        >
          {settings?.howItWorksTitle && (
            <div className={`${borderColor} col border-t border-opacity-70`}>
              <h3 className="mb-4 mt-6">{settings?.howItWorksTitle}</h3>
              <p>{settings?.howItWorks && parse(settings?.howItWorks)}</p>
            </div>
          )}
          {settings?.howItWorksTitle2 && (
            <div className={`${borderColor} col border-t border-opacity-70`}>
              <h3 className="mb-4 mt-6">{settings?.howItWorksTitle2}</h3>
              <p>{settings?.howItWorks2 && parse(settings?.howItWorks2)}</p>
            </div>
          )}
          {settings?.howItWorksTitle3 && (
            <div className={`${borderColor} col border-t border-opacity-70`}>
              <h3 className="mb-4 mt-6">{settings?.howItWorksTitle3}</h3>
              <p>{settings?.howItWorks3 && parse(settings?.howItWorks3)}</p>
            </div>
          )}
          {settings?.howItWorksTitle4 && (
            <div className={`${borderColor} col border-t border-opacity-70`}>
              <h3 className="mb-4 mt-6">{settings?.howItWorksTitle4}</h3>
              <p>{settings?.howItWorks4 && parse(settings?.howItWorks4)}</p>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default CenteredContentPanel
