import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import * as ROUTE from 'utils/constants/clientRoutes'
import { getOrganisationId } from 'utils/organisation'
import { useVerifyEmail } from 'utils/auth'
import { VerifyEmailCommand } from 'api/data-contracts'
import VerifyIcon from 'assets/icon/Verify.svg'
import AuthPageWrapper from './AuthPageWrapper'

const VerifyEmail: React.FC<VerifyEmailCommand> = () => {
  const navigate = useNavigate()

  const [emailVerify, setEmailVerify] = useState(false)

  const search = useLocation().search
  const token = new URLSearchParams(search).get('token')

  const { mutateAsync } = useVerifyEmail()

  useEffect(() => {
    const verifyEmaildata = {
      verifyEmailToken: token,
      organisationId: getOrganisationId(),
    }

    mutateAsync(verifyEmaildata)
      .then(res => {
        setEmailVerify(true)
      })
      .catch(error => {
        //console.log(error)
      })
  }, [mutateAsync, token])

  return (
    <AuthPageWrapper title="Email verification" showPanel={false}>
      {emailVerify ? (
        <>
          <div className="flex items-center pt-5 pb-5">
            <img src={VerifyIcon} alt="verifyIcon" />
            <p className="pl-1 font-medium text-xl">
              Your email was verified successfully.
            </p>
          </div>
          <div className="w-full lg:w-80 my-auto mx-0">
            <button
              className="w-full py-4 button mt-8 bg-branding-secondary"
              onClick={() => navigate(ROUTE.LOGIN)}
            >
              Log in
            </button>
          </div>
        </>
      ) : (
        <>
          <p className="pt-5 font-medium text-xl">
            Your email is being verified.
          </p>
        </>
      )}
    </AuthPageWrapper>
  )
}

export default VerifyEmail
