import { useState } from 'react'
import { SectionModel } from 'api/data-contracts'
import renderQuestionSubtitle from 'components/module/RenderQuestionSubtitle'

interface MiniQuizPreviewProps {
  section: SectionModel
}
const MiniQuizPreview = ({ section }: MiniQuizPreviewProps) => {
  const [currentQuestion, setCurrentQuestion] = useState(1)

  const nextQuestionHandler = () => {
    if (
      section.sectionQuestionLinks &&
      section.sectionQuestionLinks.length !== 0
    ) {
      if (section.sectionQuestionLinks.length !== currentQuestion) {
        setCurrentQuestion(currentQuestion + 1)
      }
    }
  }

  return (
    <>
      <span className="text-base font-bold">
        Question{' '}
        {section.sectionQuestionLinks && section.sectionQuestionLinks.length > 0
          ? currentQuestion
          : 0}{' '}
        out of{' '}
        {section.sectionQuestionLinks && section.sectionQuestionLinks.length}
      </span>

      <p className="pt-6 italic">{section.contentArea1 ?? ''}</p>

      {section.sectionQuestionLinks &&
        section.sectionQuestionLinks.length > 0 &&
        section.sectionQuestionLinks.map((miniQuizValue, index) => (
          <div
            key={miniQuizValue.id}
            className={`${currentQuestion - 1 !== index && 'hidden'}`}
          >
            <h3 className="my-5 mb-7">{miniQuizValue?.question?.question}</h3>
            {miniQuizValue?.question?.questionType &&
              renderQuestionSubtitle(miniQuizValue.question.questionType)}
            <div className="flex-nowrap flex items-center mb-6">
              <div className="w-[80%] lg:w-[90%]">
                {miniQuizValue?.question &&
                  miniQuizValue?.question.answerOptions &&
                  miniQuizValue?.question.answerOptions.map(
                    answerOptionsValue => (
                      <label
                        className="text-lg shadow-bottom rounded-medium w-[100%] border-main block px-5 py-3 cursor-pointer border-2 border-white mb-6"
                        key={answerOptionsValue.id}
                      >
                        {answerOptionsValue.answerText}
                      </label>
                    ),
                  )}
              </div>
            </div>
          </div>
        ))}

      <button
        className="button button-large button-radius-large secondary-button-primary mt-6"
        onClick={() => nextQuestionHandler()}
      >
        Next
      </button>
    </>
  )
}

export default MiniQuizPreview
