import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetMyCoursesByOrganisation } from 'utils/organisation'
import { generateMediaPath } from '../utils/generateMediaPath'
import { sectionColour } from 'utils/sectionColour'
import { sectionColourModel } from 'utils/constants/sectionColour'
import Slider, { CustomArrowProps } from 'react-slick'
import { ReactComponent as SliderNext } from 'assets/icon/slider-next.svg'
import { ReactComponent as SliderPrev } from 'assets/icon/slider-prev.svg'

const MyCourses = (bgColour: sectionColourModel) => {
  const { myCourses } = useGetMyCoursesByOrganisation()
  const { backgroundColor, headingColor } = sectionColour(bgColour.bgColour)
  const [slideDisplay, setSlideDisplay] = useState(4)

  useEffect(() => {
    if (myCourses !== undefined) {
      setSlideDisplay(myCourses?.length)
    }
  }, [myCourses])

  const slideToDisplayCondition = slideDisplay < 2 ? slideDisplay : 2
  const slideToDisplayConditionBelow1024px = slideDisplay < 1 ? slideDisplay : 1
  const slideToDisplayConditionBelow640px = slideDisplay < 2 ? slideDisplay : 2

  const NextArrow = ({
    currentSlide,
    slideCount,
    ...props
  }: CustomArrowProps) => <SliderNext {...props} />

  const PrevArrow = ({
    currentSlide,
    slideCount,
    ...props
  }: CustomArrowProps) => <SliderPrev {...props} />

  const settings = {
    dots: false,
    arrows: true,
    slidesToShow: slideToDisplayCondition,
    slidesToScroll: 1,
    infinite: true,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slideToDisplayConditionBelow1024px,
          arrows: false,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: slideToDisplayConditionBelow640px,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          arrows: false,
        },
      },
    ],
  }

  return myCourses && myCourses.length > 0 ? (
    <section
      className={`${backgroundColor} pr-0 px-9 py-20 lg:py-32 lg:px-24 section-border-grey`}
    >
      <div className="container mx-auto xl:px-8">
        <div className="flex text-center flex-col mr-9 lg:mr-0">
          <h2 className={`${headingColor} font-black`}>My courses</h2>
          <hr className="hr mx-auto rounded-cap bg-branding-secondary"></hr>
        </div>
        <div className="grid grid-cols-1 gap-6 ">
          <Slider {...settings}>
            {myCourses.map(course => (
              <Link to={`/course-dashboard/${course.id}`} key={course.id}>
                <div
                  key={course.id}
                  className="w-full mx-auto sm:max-h-[200px] rounded-medium sm:border-gray-light sm:border-[1px] bg-white flex flex-col sm:flex-row items-start"
                >
                  {course.courseInstance?.course?.courseImage ? (
                    <div className="sm:rounded-l-lg overflow-hidden">
                      <img
                        className="sm:min-h-[198px] h-[160px] sm:h-[198px] object-cover rounded-medium sm:rounded-r-none sm:rounded-tl-lg sm:rounded-bl-lg w-full sm:w-[210px] relative transition-transform origin-center duration-300 hover:scale-[1.03]"
                        alt=""
                        src={generateMediaPath(
                          course.courseInstance?.course?.courseImage,
                        )}
                      ></img>
                    </div>
                  ) : (
                    <div className="sm:rounded-l-lg overflow-hidden">
                      <img
                        alt=""
                        src="http://via.placeholder.com/640x360"
                        className="sm:min-h-[198px] h-[160px] sm:h-[198px] object-cover rounded-medium sm:rounded-r-none sm:rounded-tl-lg sm:rounded-bl-lg w-full sm:w-[210px] relative transition-transform origin-center duration-300 hover:scale-[1.03]"
                      ></img>
                    </div>
                  )}
                  <div className="py-6 sm:p-9 w-full sm:w-[60%] max-h-[198px] overflow-hidden">
                    <h3 className="mb-2">{course.courseInstance?.title}</h3>
                    <p className="overflow-hidden">
                      {course.courseInstance?.description
                        ? course.courseInstance?.description?.substring(0, 62) +
                          '...'
                        : ''}
                    </p>
                    {course.courseInstance?.location &&
                      course.courseInstance?.location.trim() !== '' && (
                        <div className="flex items-center mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20px"
                            height="20px"
                            viewBox="0 0 512.000000 512.000000"
                            preserveAspectRatio="xMidYMid meet"
                            className="mr-1"
                          >
                            <g
                              transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                              className="fill-branding-primary"
                              stroke="none"
                            >
                              <path
                                d="M2307 4675 c-381 -62 -709 -234 -978 -512 -491 -508 -608 -1242 -315
-1970 218 -542 653 -1098 1236 -1580 190 -157 220 -176 290 -181 94 -7 128 12
340 189 580 482 1009 1033 1226 1572 292 725 177 1454 -309 1963 -56 59 -132
131 -168 160 -212 169 -461 286 -734 344 -132 29 -455 37 -588 15z m485 -431
c399 -76 742 -337 918 -699 278 -574 103 -1275 -501 -2000 -106 -127 -412
-435 -546 -550 l-103 -87 -102 88 c-136 116 -443 424 -547 549 -663 797 -808
1560 -409 2160 63 95 217 254 308 318 186 130 389 209 612 237 93 11 264 4
370 -16z"
                              />
                              <path
                                d="M2350 3520 l0 -320 -320 0 -320 0 0 -215 0 -215 320 0 320 0 0 -320
0 -320 210 0 210 0 0 320 0 320 320 0 320 0 0 215 0 215 -320 0 -320 0 0 320
0 320 -210 0 -210 0 0 -320z"
                              />
                            </g>
                          </svg>
                          <p>{course.courseInstance?.location}</p>
                        </div>
                      )}
                  </div>
                </div>
              </Link>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  ) : null
}

export default MyCourses
