import { useCallback, useEffect, useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { ModuleModel, UserCourseInstanceModel } from 'api/data-contracts'
import AnchorLink from './AnchorLink'
import { useBranding } from 'utils/useBranding'
import AccordionCloseIcon from '../assets/icon/accordion-close.svg'
import AccordionOpenIcon from '../assets/icon/accordion-open.svg'
import LockedSectionIcon from '../assets/icon/locked-section.svg'

type Props = {
  module: ModuleModel
  index: number
  currentModule: ModuleModel | undefined
  userCourseInstance: UserCourseInstanceModel
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>
}
export default function ModuleAccordion(props: Props) {
  const { branding } = useBranding()

  const [isModuleContainsFeedback, setIsModuleContainsFeedback] = useState('')

  const userCourseInstance = props.userCourseInstance
  const currentModuleOrder = props.currentModule?.order
  const moduleOrder = props.module?.order
  const currentModule = currentModuleOrder === moduleOrder
  const currentModuleIndex =
    currentModuleOrder === moduleOrder! ? moduleOrder && moduleOrder - 1 : null
  const userModuleDatas = userCourseInstance.userModuleDatas
  const currentModuleNotStarted =
    userModuleDatas &&
    userModuleDatas[currentModuleIndex!] &&
    userModuleDatas[currentModuleIndex!].userModuleStatus === 'NOTSTARTED'
  const lockedModule = currentModuleOrder! < moduleOrder!
  const completedModule = currentModuleOrder! > moduleOrder!
  const completedCourse = userCourseInstance.userInstanceStatus === 'COMPLETE'

  const individualModule = useCallback(() => {
    if (
      userCourseInstance &&
      userCourseInstance.userModuleDatas !== null &&
      userCourseInstance.userModuleDatas !== undefined
    ) {
      return userCourseInstance.userModuleDatas.find(
        userModuleDatas => userModuleDatas.module?.id === props.module.id,
      )
    }
  }, [userCourseInstance, props.module.id])

  useEffect(() => {
    individualModule()?.moduleFeedback !== null
      ? setIsModuleContainsFeedback(
          individualModule()?.moduleFeedback as string,
        )
      : setIsModuleContainsFeedback('')
  }, [individualModule])

  return (
    <div
      className={`rounded-medium border-[1px] my-4 drop-shadow-lg border-branding-secondary bg-white
      ${lockedModule && 'bg-light-grey border-grey'}`}
    >
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-between items-center rounded-medium px-4 py-3 text-left text-sm  font-medium text-purple-900  focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
              <div className="flex items-center">
                {lockedModule ? (
                  <div className="1 mr-2 ml-[-2px] mt-[1px] mr-3 text-purple-500 inline">
                    <div className="h-5 w-5">
                      <img
                        src={LockedSectionIcon}
                        alt="LockedSectionIcon"
                        className="m-auto"
                      />
                    </div>
                  </div>
                ) : completedModule ? (
                  <div className="mr-2 mt-1 text-correct-500 inline">
                    <div className="h-5 w-5">
                      <svg width="19" height="19" viewBox="0 0 19 19">
                        <defs>
                          <clipPath id="clip-path">
                            <rect
                              data-name="Rectangle 2504"
                              width="8.597"
                              height="6.959"
                              fill="#fff"
                            />
                          </clipPath>
                        </defs>
                        <g
                          data-name="Group 2420"
                          transform="translate(-870 -329)"
                        >
                          <circle
                            id="Ellipse_36"
                            data-name="Ellipse 36"
                            cx="9.5"
                            cy="9.5"
                            r="9.5"
                            transform="translate(870 329)"
                            fill={`${branding.stylingSecondary}`}
                          />
                          <g
                            id="Group_2419"
                            data-name="Group 2419"
                            transform="translate(874.785 335)"
                          >
                            <g
                              data-name="Group 2418"
                              clipPath="url(#clip-path)"
                            >
                              <path
                                id="Path_6454"
                                data-name="Path 6454"
                                d="M.239,3.515a.819.819,0,0,0,0,1.158L2.286,6.72a.819.819,0,0,0,.579.24h.016A.819.819,0,0,0,3.467,6.7L8.379,1.374A.819.819,0,0,0,7.176.264l-4.334,4.7L1.4,3.515a.819.819,0,0,0-1.158,0"
                                transform="translate(0 0)"
                                fill="#000"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                ) : (
                  currentModule && (
                    <div className="3 mr-2 mt-1 text-yellow-500 inline">
                      <div className="h-5 w-5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19.002"
                          height="19.004"
                          viewBox="0 0 19.002 19.004"
                        >
                          <g
                            data-name="Group 2569"
                            transform="translate(-103.998 -993)"
                          >
                            <g
                              id="Ellipse_45"
                              data-name="Ellipse 45"
                              transform="translate(104 993)"
                              fill="#fff"
                              stroke={`${branding.stylingSecondary}`}
                              strokeWidth="2"
                            >
                              <circle cx="9.5" cy="9.5" r="9.5" stroke="none" />
                              <circle cx="9.5" cy="9.5" r="8.5" fill="none" />
                            </g>
                            <path
                              id="Subtraction_1"
                              data-name="Subtraction 1"
                              d="M-21863.5-56a9.514,9.514,0,0,1-9.5-9.5,9.514,9.514,0,0,1,9.5-9.5c.164,0,.332,0,.5.013V-56.01C-21863.174-56-21863.342-56-21863.5-56Z"
                              transform="translate(21977 1068.002)"
                              fill={`${branding.stylingSecondary}`}
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  )
                )}
                <span className="font-medium">
                  {props.index.toString()}:&nbsp;
                </span>
                <span
                  className={`underline font-medium
                       ${lockedModule && 'no-underline'}`}
                >
                  {' '}
                  {props.module.name}
                </span>
                {isModuleContainsFeedback && (
                  <div className="ml-2 pending-notification bg-branding-secondary"></div>
                )}
              </div>
              {open ? (
                <img src={AccordionCloseIcon} alt="AccordionCloseIcon" />
              ) : (
                <img src={AccordionOpenIcon} alt="AccordionOpenIcon" />
              )}
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-3 pb-2 text-gray-500">
              <div className="pl-8 pb-4 pr-3">
                <p>{props.module.description}</p>
                {isModuleContainsFeedback && (
                  <div className="w-full border-light-gray bg-light-grey rounded-medium border-[2px] p-8 mt-5">
                    <p className="text-lg text-branding-secondary font-semibold mb-3">
                      Feedback
                    </p>
                    <hr className="border-branding-secondary border-[1px]" />
                    <p className="text-base mt-4 mb-4">
                      {isModuleContainsFeedback}
                    </p>
                    <span
                      className="text-base underline font-semibold cursor-pointer"
                      onClick={() => props.setSelectedTabIndex(3)}
                    >
                      View feedback
                    </span>
                  </div>
                )}
                {currentModule && currentModuleNotStarted && (
                  <AnchorLink
                    to={`/course/${userCourseInstance.id}/module/${userCourseInstance?.currentModule?.id}`}
                    className="inline-flex mt-6 button button-radius-large bg-branding-secondary"
                  >
                    Start
                  </AnchorLink>
                )}
                {currentModule && !currentModuleNotStarted && (
                  <AnchorLink
                    to={`/course/${userCourseInstance.id}/module/${userCourseInstance?.currentModule?.id}`}
                    className="inline-flex mt-6 button button-radius-large bg-branding-secondary"
                  >
                    Continue
                  </AnchorLink>
                )}
                {completedModule || completedCourse ? (
                  <AnchorLink
                    to={`/course/${userCourseInstance.id}/module/${props.module.id}`}
                    className="inline-flex mt-6 button button-wide button-radius-large bg-branding-secondary"
                  >
                    Review
                  </AnchorLink>
                ) : null}
                {/* {isModuleContainsFeedback && (
                  <AnchorLink
                    to={`/course/${userCourseInstance.id}/module/${props.module.id}`}
                    className="ml-4 inline-flex mt-6 button button-wide button-radius-large bg-branding-secondary opacity-50 !cursor-default pointer-events-none"
                  >
                    Retake assessment
                  </AnchorLink>
                )} */}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}
