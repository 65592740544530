import { useQuery } from '@tanstack/react-query'
import invariant from 'tiny-invariant'

import { useApiClient } from './use-clients'

// GET:/api/UserCourseInstance/{userCourseInstanceId}/my
const useGetMyUserCourseInstance = (userCourseInstanceId: string) => {
  invariant(userCourseInstanceId, 'Invalid user course instance id')
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['userCourseInstanceId', { userCourseInstanceId }],
    queryFn: () =>
      client.userCourseInstance.getMyUserCourseInstance(userCourseInstanceId),
    enabled: !!userCourseInstanceId,
    refetchOnWindowFocus: false,
  })
  return {
    ...result,
    userCourseInstance: result.data?.data,
  }
}

export { useGetMyUserCourseInstance }
