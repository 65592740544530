import React from 'react'
import * as Progress from '@radix-ui/react-progress'

interface SectionProps {
  totalSections: number
  lastCompletedSectionOrder: number
}
const ProgressTracker = ({
  totalSections,
  lastCompletedSectionOrder,
}: SectionProps) => {
  const [progress, setProgress] = React.useState(totalSections)

  React.useEffect(() => {
    const percent = (lastCompletedSectionOrder / (totalSections - 1)) * 100
    const timer = setTimeout(() => setProgress(percent), 500)
    return () => clearTimeout(timer)
  }, [lastCompletedSectionOrder, totalSections])

  return (
    <div className="progressbar">
      <Progress.Root
        className="ProgressRoot border-0 bg-light-grey"
        value={progress || 0}
      >
        <Progress.Indicator
          className="ProgressIndicator bg-branding-secondary"
          style={{
            transform: `translateX(-${100 - progress}%)`,
          }}
        />
      </Progress.Root>
    </div>
  )
}

export default ProgressTracker
