import { CompletionModel, ModuleModel } from 'api/data-contracts'
import AnchorLink from 'components/AnchorLink'
import { INCOMPLETE } from 'utils/constants/slide'
import { generateMediaPath } from 'utils/generateMediaPath'
import { ReactComponent as HomeIcon } from 'assets/icon/homepage.svg'
import Header from '../Header'
import { contentDataModel } from 'utils/models/Content'
import { useModuleContext } from 'context/ModuleContext'

interface Props {
  completionData: CompletionModel
  userCourseInstanceId: string
  module: ModuleModel
  totalSections: number
  lastCompletedSectionOrder: number
}

export const ModuleCompletion = ({
  completionData,
  userCourseInstanceId,
  module,
  totalSections,
  lastCompletedSectionOrder,
}: Props) => {
  const { setCurrentSection, setModuleCompletion } = useModuleContext()
  const handleSetCurrentSection = (
    event: React.MouseEvent<HTMLButtonElement>,
    section: contentDataModel,
  ) => {
    event.stopPropagation()
    setCurrentSection(section!)
  }

  return (
    <div className="flex items-baseline flex-col">
      <Header
        module={module}
        totalSections={totalSections}
        lastCompletedSectionOrder={lastCompletedSectionOrder}
      />
      <div className="pt-5 headerSpacing">
        {completionData.status !== INCOMPLETE ? (
          <>
            <h2 className="mb-4">{completionData.completionTitle}</h2>
            {completionData.completionImage &&
              completionData.completionTitle && (
                <img
                  className="mb-4 max-h-[50vh]"
                  src={generateMediaPath(completionData.completionImage)}
                  alt={completionData.completionTitle}
                />
              )}
            <p className="mb-4">{completionData.completionDescription}</p>
            <AnchorLink
              to={`/course-dashboard/${userCourseInstanceId}`}
              className={`button button-large button-radius-large secondary-button-primary mb-[45px] flex gap-2 w-fit`}
            >
              Return to modules
              <HomeIcon className="w-[20px] h-[24px] ml-1" />
            </AnchorLink>
          </>
        ) : (
          <>
            <h3 className="text-2xl font-normal text-grey px-7">
              To finish the module, please finish the follow incomplete
              sections.
            </h3>
            <div className="flex-col items-start">
              {completionData.incompleteSections &&
                completionData.incompleteSections.map((section, index) => (
                  <button
                    className="py-1 underline text-branding-primary font-bold text-[18px]"
                    key={section.id}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      handleSetCurrentSection(event, section)
                      setModuleCompletion(false)
                    }}
                  >
                    {section.name}
                  </button>
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
