import { generateMediaPath } from 'utils/generateMediaPath'
import { useBranding } from 'utils/useBranding'
import { FormatListBulletedOutlined, InfoOutlined } from '@mui/icons-material'

import HomeIcon from '../../assets/icon/homepage.svg'
import { ReactComponent as ShareIcon } from 'assets/icon/share.svg'
import { ReactComponent as ProfileIcon } from 'assets/icon/profile.svg'

const Sidebar = () => {
  const { branding } = useBranding()

  return (
    <section className="module-sidenav flex flex-col max-h-screen h-screen w-[100px] lg:w-[130px] xl:w-[150px] justify-between relative z-[99] bg-branding-primary pointer-events-none">
      <div className="mb-4 cursor-pointer">
        {branding.companyLogo && (
          <img
            className="w-[70%] ml-[15%] mt-12 org-logo "
            src={generateMediaPath(branding.companyLogo)}
            alt="logo"
          />
        )}
      </div>

      <div className="flex flex-col align-center font-medium text-white text-opacity-90">
        <button className="sidebarButton justify-center border-0 flex flex-col items-center mx-auto rounded-lg">
          <img src={HomeIcon} alt="home" />
          <span className="mt-1 text-extra-small">Home</span>
        </button>
        <button className="sidebarButton justify-center border-0 flex flex-col items-center mx-auto rounded-lg">
          <InfoOutlined fontSize="large" />
          <span className="mt-1 text-extra-small">Resources</span>
        </button>
        <button className="sidebarButton justify-center border-0 flex flex-col items-center mx-auto rounded-lg">
          <FormatListBulletedOutlined fontSize="large" />
          <span className="mt-1 text-extra-small">Contents</span>
        </button>
        <button className="sidebarButton justify-center border-0 flex flex-col items-center mx-auto rounded-lg">
          <div>
            <svg
              width="28"
              height="28.227"
              viewBox="0 0 28 28.227"
              className="w-full h-auto"
            >
              <path
                d="M16.4,4.423H7.1A3.1,3.1,0,0,0,4,7.524V26.131a3.1,3.1,0,0,0,3.1,3.1H25.708a3.1,3.1,0,0,0,3.1-3.1v-9.3M11.753,21.479V17.6L25.321,4.035a2.741,2.741,0,0,1,3.876,0h0a2.741,2.741,0,0,1,0,3.876l-7.365,7.365-6.2,6.2Z"
                transform="translate(-3 -2.005)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
              />
            </svg>
          </div>
          <span className="mt-2 text-extra-small">Notes</span>
        </button>
      </div>
      <div>
        <hr className="w-[70%] ml-[15%] mt-3 bg-white h-[1px] mx-auto rounded-cap border-0 mb-8"></hr>
        <button className="sidebarButton justify-center border-0 flex flex-col items-center mx-auto rounded-lg">
          <div className="opacity-90">
            <ProfileIcon className="profile-icon w-7 h-7" />
          </div>
          <div className="flex mt-2 text-white items-center">
            <span className="text-extra-small">Profile</span>
            <ShareIcon className="share-icon ml-1" />
          </div>
        </button>
      </div>
    </section>
  )
}

export default Sidebar
