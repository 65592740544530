import { useNavigate } from 'react-router-dom'
// import AnchorLink from './AnchorLink'
import DOMPurify from 'dompurify'
import parse from 'html-react-parser'
import { useGetPolicyDocuments, useGetSettings } from 'utils/organisation'

const Footer = () => {
  const navigate = useNavigate()
  const clean = (html: string) => DOMPurify.sanitize(html)

  const { policyDocuments } = useGetPolicyDocuments()
  const { settings } = useGetSettings()

  return (
    <footer className="bg-main dark:bg-gray-900 px-9 py-20 lg:py-32 lg:px-24 lg:pb-64 text-white relative z-20">
      <div className="container mx-auto lg:px-12">
        <div className="flex flex-wrap pb-10">
          <div className="w-full lg:w-2/4">
            <h5 className="mb-5 text-center sm:text-left">Quick links</h5>
            <ul className="flex flex-wrap">
              {policyDocuments &&
                policyDocuments?.map(policyData => (
                  <li className="w-full lg:w-2/4" key={policyData?.id}>
                    <span
                      className="hover:underline cursor-pointer footer-links"
                      onClick={() =>
                        navigate(`/policy/${policyData?.id}`, {
                          state: policyData,
                        })
                      }
                    >
                      {parse(clean(policyData?.name as string))}
                    </span>
                  </li>
                ))}
            </ul>
          </div>
          <hr className="mobileView w-full my-12"></hr>
          <div className="w-full lg:w-2/4">
            {settings?.companyInfoTitle && (
              <h5 className="mb-5 text-center sm:text-left">
                {settings?.companyInfoTitle}
              </h5>
            )}
            {settings?.companyInfo && (
              <p className="text-extra-small">{settings?.companyInfo}</p>
            )}
          </div>
        </div>
        <hr className="mt-8"></hr>
      </div>
      {/*   <span className="text-small text-center m-auto mt-4 block">
        Created using{' '}
        <a className="font-bold" href="/">
          eLearning Platform name
        </a>
      </span> */}
      <span className="text-xs text-center m-auto mt-6 block">
        <a
          className="font-bold"
          href="https://www.figma.com/community/file/1136337054881623512"
        >
          ICONCINO (v1.7.0)
        </a>{' '}
        Icons by{' '}
        <a
          className="font-bold"
          href="https://www.figma.com/community/file/1136337054881623512"
        >
          Gabriele Malaspina{' '}
        </a>
        are licensed under{' '}
        <a
          className="font-bold"
          href="https://creativecommons.org/licenses/by/4.0/"
        >
          CC BY 4.0.
        </a>
      </span>
    </footer>
  )
}

export default Footer
