import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  CheckEmailExistsModel,
  RegisterModel,
  ForgotPasswordModel,
  ResetPasswordModel,
} from 'utils/models/Auth'
import {
  CompleteSetupCommand,
  LoginResponseModel,
  LoginUserModel,
  RefreshTokenCommand,
  VerifyEmailCommand,
} from 'api/data-contracts'
import { useNavigate } from 'react-router-dom'
import { useAuthClient } from './use-clients'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import invariant from 'tiny-invariant'
import { HttpResponse } from 'api/http-client'

export const TOKEN_KEY = 'access_token'
export const REFRESH_TOKEN_KEY = 'refresh_token'

export function getToken() {
  return localStorage.getItem(TOKEN_KEY)
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY)
}

export function setToken(token: string) {
  localStorage.setItem(TOKEN_KEY, token)
}
export function setRefreshToken(token: string) {
  localStorage.setItem(REFRESH_TOKEN_KEY, token)
}

export function removeToken() {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem(REFRESH_TOKEN_KEY)
}

export function isLoggedIn() {
  const token = getToken()
  return !!token && !isTokenExpired()
}

export function decodeJwtToken(): JwtPayload | null {
  const token = getToken()
  if (token) {
    return jwt_decode<JwtPayload>(token)
  }
  return null
}

export function isTokenExpired() {
  const decoded = decodeJwtToken()
  invariant(decoded, 'decode token must be present.')
  invariant(decoded.exp, 'decode token expiry must be present.')

  if (decoded.exp * 1000 < new Date().getTime()) {
    return true
  } else {
    return false
  }
}

const useSignIn = () => {
  const authClient = useAuthClient()
  const queryClient = useQueryClient()

  const navigate = useNavigate()

  const mutation = useMutation({
    mutationFn: (userLoginModel: LoginUserModel) =>
      authClient.auth.loginUser(userLoginModel, { secure: false }),
    async onSuccess(res) {
      const token = res.data?.accessToken
      const refreshToken = res.data?.refreshToken
      setToken(token!)
      setRefreshToken(refreshToken!)
      navigate('/')
      const decoded = decodeJwtToken()
      queryClient.setQueryData(['user'], () => JSON.stringify(decoded))
    },
  })
  return mutation
}

// # refetch user from localstorage if it does not exist in react query state (usually used after page refresh)
const useUser = () => {
  const result = useQuery({
    queryKey: ['user'], //get user from reactQuery
    queryFn: () => decodeJwtToken(), //decode the localstorage token and store in cache
  })
  return { ...result, user: result?.data ?? null }
}

//removes token from localStorage and token from useQuery
const useSignOut = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const signOut = () => {
    queryClient.setQueryData(['user'], () => null) //removes user from cache
    queryClient.refetchQueries(['user']) // refetch queries that depend on the user data
    removeToken() //remove auth token
    navigate('/login')
    queryClient.removeQueries()
  }
  return { signOut }
}

const useSignUp = () => {
  const authClient = useAuthClient()

  const mutation = useMutation({
    mutationFn: (userRegisterModel: RegisterModel) =>
      authClient.auth.registerUser(userRegisterModel, { secure: false }),
  })
  return mutation
}

const useCheckEmailExist = () => {
  const authClient = useAuthClient()

  const mutation = useMutation({
    mutationFn: (useCheckEmailExist: CheckEmailExistsModel) =>
      authClient.auth.checkEmailExists(useCheckEmailExist, { secure: false }),
  })
  return mutation
}

const useVerifyEmail = () => {
  const authClient = useAuthClient()

  const mutation = useMutation({
    mutationFn: (useVerifyEmail: VerifyEmailCommand) =>
      authClient.auth.verifyEmail(useVerifyEmail, { secure: false }),
  })
  return mutation
}

const useResetPasswordRequest = () => {
  const authClient = useAuthClient()

  const mutation = useMutation({
    mutationFn: (useResetPasswordRequest: ForgotPasswordModel) =>
      authClient.auth.requestPasswordReset(useResetPasswordRequest, {
        secure: false,
      }),
  })
  return mutation
}

const useResetUserPassword = () => {
  const authClient = useAuthClient()

  const mutation = useMutation({
    mutationFn: (useResetUserPassword: ResetPasswordModel) =>
      authClient.auth.resetUserPassword(useResetUserPassword, {
        secure: false,
      }),
  })
  return mutation
}

const useCompleteSetup = () => {
  const authClient = useAuthClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (props: CompleteSetupCommand) =>
      authClient.auth.completeSetup(props),
    onSuccess: async data => {
      console.log('Mutation worked!')
      queryClient.setQueryData(['user'], data) //set query (oldData,newData)
      queryClient.refetchQueries(['user']) // refetch queries that depend on the user data
    },
  })
  return mutation
}


// POST:/api/Auth/token/refresh
const useAuthTokenRefreshCreate = () => {
  const client = useAuthClient()
  const navigate = useNavigate()

  const mutation = useMutation({
    mutationFn: (data: RefreshTokenCommand) =>
      client.auth.authTokenRefreshCreate(data),
    onSuccess(res: HttpResponse<LoginResponseModel>) {
      const token = res.data?.accessToken
      const refreshToken = res.data?.refreshToken
      setToken(token!)
      setRefreshToken(refreshToken!)
    },
    onError: () => {
      navigate('/login')
    },
  })
  return mutation
}
// GET:/api/Auth/profile
const useGetProfile = () => {
  const { user, isLoading: isProfileLoading } = useUser()
  const client = useAuthClient()
  const result = useQuery({
    queryKey: ['profile'],
    queryFn: () => client.auth.profile(),
    enabled: !!user && !isProfileLoading,
    staleTime: Infinity,
  })
  return { ...result, profile: result.data?.data }
}

export {
  useSignIn,
  useUser,
  useSignOut,
  useCheckEmailExist,
  useSignUp,
  useVerifyEmail,
  useResetPasswordRequest,
  useResetUserPassword,
  useCompleteSetup,
  useAuthTokenRefreshCreate,
  useGetProfile,
}
