interface NavbuttonProps {
  label: string
  className: string
  setAction?: () => void
}
const Navbutton = ({ label, className, setAction }: NavbuttonProps) => {
  return (
    <button onClick={setAction} className={className}>
      {label}
    </button>
  )
}

export default Navbutton
