import { useModuleContext } from 'context/ModuleContext'
import { INCOMPLETE, INPROGRESS, COMPLETED } from 'utils/constants/slide'
import { contentDataModel } from 'utils/models/Content'
import { ReactComponent as LockedSectionIcon } from 'assets/icon/locked-section.svg'
import { ReactComponent as CompletedSectionIcon } from 'assets/icon/complete-section.svg'
import { ReactComponent as HomeIcon } from '../../assets/icon/homepage.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import { COURSE_DASHBOARD_BY_ID } from 'utils/constants/clientRoutes'
import AssessmentAwayMessage from 'components/AssessmentAwayMessage'

const Contents = ({ sections }: { sections: contentDataModel[] }) => {
  const {
    currentSection,
    setCurrentSection,
    setModuleCompletion,
    assessment,
    setAssessment,
  } = useModuleContext()
  const handleSetCurrentSection = (
    event: React.MouseEvent<HTMLButtonElement>,
    section: contentDataModel,
  ) => {
    event.stopPropagation()
    setCurrentSection(section!)
  }
  const navigate = useNavigate()
  const [assessmentAwayMessageVisible, setAssessmentAwayMessageVisible] =
    useState(false)
  const { userCourseInstanceId } = useParams()

  const homeHandler = () => {
    navigate(
      COURSE_DASHBOARD_BY_ID.replace(
        ':userCourseInstanceId',
        userCourseInstanceId!,
      ),
    )
    setAssessment({})
  }

  return (
    <>
      <h3 className="font-medium text-grey px-9">Contents</h3>
      <hr className="w-full block mt-[20px] mb-[30px] h-[1px] bg-[#9D9D9D] rounded-cap border-0"></hr>
      <div className="px-9 content-panel-contents">
        <p className="font-bold pt-8 pb-6 text-grey">Course modules</p>
        {sections &&
          sections.map(section => (
            <div
              className={`rounded-medium mb-4 drop-shadow-lg bg-white module-contents-item ${
                section?.status === INCOMPLETE
                  ? 'module-contents-item-icon-invisible'
                  : 'module-contents-item-icon-visible'
              }  ${section === currentSection ? 'selected' : ''}`}
              key={section.id}
            >
              <button
                className={`flex w-full items-center rounded-lg bg-white px-4 py-4 text-left text-md font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 list-options 
                ${
                  section?.status === INPROGRESS
                    ? '!bg-branding-secondary'
                    : section?.status === INCOMPLETE
                    ? '!bg-light-grey pointer-events-none justify-between'
                    : '!bg-white'
                }
                ${section === currentSection && 'active'} `}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  handleSetCurrentSection(event, section)
                  setModuleCompletion(false)
                }}
              >
                {section?.status === COMPLETED && (
                  <div className="flex justify-center w-6 h-6 p-0 pl-0 pr-0 mr-2">
                    <CompletedSectionIcon className="complete-section self-center" />
                  </div>
                )}
                <span className="font-medium text-small text-grey">
                  {section.name}
                </span>
                {section?.status === INCOMPLETE && (
                  <LockedSectionIcon
                    focusable="false"
                    aria-hidden="true"
                    data-testid="LockIcon"
                  />
                )}
              </button>
            </div>
          ))}
        <hr className="w-full block mt-16 mb-1 h-[1px] bg-[#9D9D9D] rounded-cap border-0"></hr>
        <button
          onClick={
            Object.keys(assessment).length > 0
              ? () => {
                  setAssessmentAwayMessageVisible(true)
                }
              : () => homeHandler()
          }
        >
          <div className="flex items-center pt-8 pb-6">
            <HomeIcon className="w-[20px] h-[24px] home-icon mr-3" />{' '}
            <span className="text-small font-bold text-grey ">
              Exit to homepage
            </span>
          </div>
        </button>
      </div>

      {assessmentAwayMessageVisible && (
        <AssessmentAwayMessage
          onStay={() => setAssessmentAwayMessageVisible(false)}
          onLeave={() => {
            setAssessmentAwayMessageVisible(false)
            setAssessment({})
          }}
        />
      )}
    </>
  )
}
export default Contents
