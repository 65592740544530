import { AnswerOptionModel } from 'api/data-contracts'

type QuestionProps = {
  answer: AnswerOptionModel
  setSelectedAnswer: (answer: AnswerOptionModel[]) => void
  answerItem: AnswerOptionModel
}

export const FreeText = ({
  answer,
  setSelectedAnswer,
  answerItem,
}: QuestionProps) => {
  return (
    <input
      className="my-4 py-2 px-4 text-left w-[100%] border-main rounded-medium focus:border-2 shadow-md"
      type="text"
      onChange={e =>
        setSelectedAnswer([
          {
            ...[answerItem],
            answerText: e.target.value,
          },
        ])
      }
    />
  )
}
