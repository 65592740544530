import { useQuery } from '@tanstack/react-query'
import { useApiClient } from './use-clients'
import { ResourceModel } from 'api/data-contracts'
import { useParams } from 'react-router-dom'

interface GetPolicyModel extends ResourceModel {
  content: string
}

// GET:/api/PolicyDocument/{policyId}
const useGetPolicy = () => {
  const { policyId } = useParams()
  const client = useApiClient()

  const result = useQuery({
    queryKey: ['policy', policyId],
    queryFn: () => client.policyDocument.getPolicy(policyId as string),
    enabled: !!policyId,
  })
  return {
    ...result,
    policyData: result.data?.data as GetPolicyModel,
  }
}

export { useGetPolicy }
