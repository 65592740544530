/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  NoteModel,
  NotFoundExceptionModel,
  UnauthorizedExceptionModel,
  UpdateNoteModel,
  ValidationExceptionModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Note<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Note
   * @name UpdateNote
   * @request PUT:/api/Note/{noteId}
   * @secure
   */
  updateNote = (
    noteId: string,
    data: UpdateNoteModel,
    params: RequestParams = {},
  ) =>
    this.request<NoteModel, ValidationExceptionModel | NotFoundExceptionModel>({
      path: `/api/Note/${noteId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    })
  /**
   * No description
   *
   * @tags Note
   * @name DeleteNote
   * @request DELETE:/api/Note/{noteId}
   * @secure
   */
  deleteNote = (noteId: string, params: RequestParams = {}) =>
    this.request<void, UnauthorizedExceptionModel | NotFoundExceptionModel>({
      path: `/api/Note/${noteId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    })
}
