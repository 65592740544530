import { useMemo } from 'react'
import PasswordRequirementField from './Register/PasswordRequirementField'
import {
  hasCapitalLetter,
  hasNumber,
  hasSpecialCharacter,
} from 'utils/validation/passwords'

interface PasswordValidationProps {
  password?: string
}

const PasswordValidation = ({ password }: PasswordValidationProps) => {
  const passwordIsCorrectLength = useMemo(() => {
    return password ? password.length < 20 && password.length > 8 : false
  }, [password])

  const passwordHasASpecialCharacter = useMemo(() => {
    return password ? hasSpecialCharacter.test(password) : false
  }, [password])

  const passwordHasACapitalLetter = useMemo(() => {
    return password ? hasCapitalLetter.test(password) : false
  }, [password])

  const passwordHasNumber = useMemo(() => {
    return password ? hasNumber.test(password) : false
  }, [password])

  return (
    <>
      <p className="py-5">
        Choose a password that is hard to guess and unique to this account.
        <span> Your password must...</span>
      </p>

      <div className="pt-4 pb-7">
        <PasswordRequirementField
          requirementFulfill={passwordIsCorrectLength}
          label="isPasswordEightToTwentyCharacter"
        />
        <PasswordRequirementField
          requirementFulfill={passwordHasASpecialCharacter}
          label="isPasswordOneSpecialCharacter"
        />
        <PasswordRequirementField
          requirementFulfill={passwordHasACapitalLetter}
          label="isPasswordOneCapitalLetter"
        />
        <PasswordRequirementField
          requirementFulfill={passwordHasNumber}
          label="isPasswordOneNumber"
        />
      </div>
    </>
  )
}

export default PasswordValidation
