export const sectionColour = (bgColour: string) => {
  const backgroundColor =
    bgColour === 'grey'
      ? 'bg-light-grey'
      : bgColour === 'white'
      ? 'bg-white'
      : bgColour === 'branding'
      ? 'bg-branding-primary'
      : ''

  const textColor =
    bgColour === 'grey'
      ? 'text-main'
      : bgColour === 'white'
      ? 'text-main'
      : bgColour === 'branding'
      ? 'text-white'
      : ''

  const headingColor =
    bgColour === 'grey'
      ? 'text-branding-primary'
      : bgColour === 'white'
      ? 'text-branding-primary'
      : bgColour === 'branding'
      ? 'text-white'
      : ''

  const buttonColor =
    bgColour === 'grey'
      ? 'bg-branding-secondary'
      : bgColour === 'white'
      ? 'bg-branding-secondary'
      : bgColour === 'branding'
      ? 'bg-white'
      : ''

  const borderColor =
    bgColour === 'grey'
      ? 'border-main'
      : bgColour === 'white'
      ? 'border-main'
      : bgColour === 'branding'
      ? 'border-white'
      : ''

  return {
    backgroundColor,
    textColor,
    headingColor,
    buttonColor,
    borderColor,
  }
}
