import DOMPurify from 'dompurify'
import parse from 'html-react-parser'
import { generateMediaPath } from 'utils/generateMediaPath'

import { ContentItemModel } from 'api/data-contracts'

// Constants
import { CONTENT, EMBED, IMAGE } from 'utils/constants/section'
import openLinksInNewTab from 'utils/openLinksInNewTab'

export const SectionTemplate = (ContentItems: ContentItemModel) => {
  openLinksInNewTab()
  const cleanIframe = (iframe: string) =>
    DOMPurify.sanitize(iframe, {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
    })

  switch (ContentItems.contentItemType) {
    case CONTENT:
      return parse(cleanIframe(ContentItems.contentValue!))
    case IMAGE:
      return (
        <img
          className="max-h-[80vh] object-contain md:object-left"
          src={generateMediaPath(ContentItems.contentValue!)}
          alt="contentImage"
        />
      )
    case EMBED:
      return (
        <div className="w-full embed-field-content">
          {parse(cleanIframe(ContentItems.contentValue!))}
        </div>
      )
    default:
      return <div>No section template exists under content</div>
  }
}
