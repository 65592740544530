import { useMutation } from '@tanstack/react-query'

import { UpdateUserModel } from 'api/data-contracts'
import { useApiClient } from './use-clients'

// PUT:/api/User/{userid}
interface useUpdateUserProps {
  userid: string
  data: UpdateUserModel
}
const useUpdateUser = () => {
  const client = useApiClient()
  const mutation = useMutation({
    mutationFn: (props: useUpdateUserProps) =>
      client.user.updateUser(props.userid, props.data),
  })
  return mutation
}

export { useUpdateUser }
