import React from 'react'
import { Navigate } from 'react-router-dom'
import * as ROUTE from '../utils/constants/clientRoutes'
import { getToken } from 'utils/auth'
interface ProtectedRouteProps {
  Component: React.ElementType
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ Component }) => {
  const token = getToken()

  return token ? <Component /> : <Navigate to={ROUTE.LOGIN} />
}

export default ProtectedRoute
