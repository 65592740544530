import React from 'react'
import InputSearchIcon from '../assets/icon/search-input.svg'
import CloseIcon from '../assets/icon/navbar-close.svg'

interface NavSearchInputFieldProps {
  action: boolean
  setAction: React.Dispatch<React.SetStateAction<boolean>>
}
const NavSearchInputField = ({
  action,
  setAction,
}: NavSearchInputFieldProps) => {
  return (
    <div className="flex sm:mr-10 lg:mr-0">
      <div className="flex items-center bg-white h-full p-2 rounded-md w-[calc(100%-40px)]">
        <input
          placeholder="Search the site"
          className="outline-none text-base pl-2 pr-2 font-normal w-full"
        />
        <img
          className="cursor-pointer"
          src={InputSearchIcon}
          alt="searchInputIcon"
        />
      </div>
      <div className="flex items-center">
        <img
          className="ml-2 lg:ml-4 cursor-pointer"
          src={CloseIcon}
          alt="Close Icon"
          onClick={() => setAction(!action)}
        />
      </div>
    </div>
  )
}

export default NavSearchInputField
