import {
  AddUserAssessmentAnswerItemModel,
  AnswerOptionModel,
} from 'api/data-contracts'

type QuestionProps = {
  answer: AnswerOptionModel
  setSelectedAnswer: (answer: AddUserAssessmentAnswerItemModel[]) => void
  selectedAnswer: AddUserAssessmentAnswerItemModel[]
  answerItem: AddUserAssessmentAnswerItemModel
}

export const MultiSelect = ({
  answer,
  setSelectedAnswer,
  selectedAnswer,
  answerItem,
}: QuestionProps) => {
  return (
    <div className="question-answer-item sentence-uppercase mb-6">
      <label
        key={answer?.id}
        className={`shadow-bottom rounded-medium w-[100%] border-2 block px-5 py-3 ${
          selectedAnswer.some(
            selected => selected.answerOptionId === answer?.id,
          )
            ? 'border-main'
            : 'border-white'
        }`}
      >
        <input
          type="checkbox"
          className={`hidden border rounded-medium border-main p-2 cursor-pointer ${
            selectedAnswer.some(
              selected => selected.answerOptionId === answer?.id,
            )
              ? 'bg-main text-white'
              : ''
          }`}
          onChange={event => {
            if (event.target.checked) {
              setSelectedAnswer([...selectedAnswer, answerItem])
            } else {
              setSelectedAnswer(
                selectedAnswer.filter(
                  selected => selected.answerOptionId !== answer?.id,
                ),
              )
            }
          }}
          checked={selectedAnswer.some(
            selected => selected.answerOptionId === answer?.id,
          )}
        />
        {answer?.answerText}
      </label>
    </div>
  )
}
