import React from 'react'
import ReactDOM from 'react-dom/client'
import { AppProviders } from './context'
import LogRocket from 'logrocket'
import App from './App'
import './index.css'
import './ckeditor.css'
import { getEnv } from 'utils/env'

const { LOG_ROCKET_PROJECT_ID } = getEnv()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

if (LOG_ROCKET_PROJECT_ID) {
  LogRocket.init(LOG_ROCKET_PROJECT_ID)
}

root.render(
  <AppProviders>
    <App />
  </AppProviders>,
)
