import { useBranding } from 'utils/useBranding'
import PasswordRequirementCircle from 'assets/icon/password-requirement-circle.svg'

interface PasswordRequirementFieldProps {
  requirementFulfill: boolean
  label: string
}

const PasswordContainsEightToTwentyCharacter = () => (
  <p>
    Be <span className="font-bold">8-20 characters</span> long
  </p>
)
const PasswordContainsOneSpecialCharacter = () => (
  <p>
    Contain at least one <span className="font-bold">special character</span>
  </p>
)
const PasswordContainsOneCapitalLetter = () => (
  <p>
    Contain at least one <span className="font-bold">capital letter</span>
  </p>
)
const PasswordContainsOneNumber = () => (
  <p>
    Contain at least one <span className="font-bold">number</span>
  </p>
)

const PasswordRequirementField = ({
  requirementFulfill,
  label,
}: PasswordRequirementFieldProps) => {
  const { branding } = useBranding()

  return (
    <div className="flex items-center">
      <div className="w-[20px] flex justify-center">
        {requirementFulfill ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
          >
            <g
              id="Group_2565"
              data-name="Group 2565"
              transform="translate(-138 -936.316)"
            >
              <circle
                id="Ellipse_43"
                data-name="Ellipse 43"
                cx="7.5"
                cy="7.5"
                r="7.5"
                transform="translate(139 937)"
                fill="#fff"
              />
              <path
                id="check-circle-1-svgrepo-com"
                d="M13.833,8l-5,5.417L6.75,11.333M10.5,18A7.5,7.5,0,1,0,3,10.5,7.5,7.5,0,0,0,10.5,18Z"
                transform="translate(136 934.316)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                stroke={`${branding.stylingPrimary}`}
              />
            </g>
          </svg>
        ) : (
          <img
            src={PasswordRequirementCircle}
            alt="PasswordRequirementCircle"
          />
        )}
      </div>
      <div className="ml-3">
        {label === 'isPasswordEightToTwentyCharacter' && (
          <PasswordContainsEightToTwentyCharacter />
        )}
        {label === 'isPasswordOneSpecialCharacter' && (
          <PasswordContainsOneSpecialCharacter />
        )}
        {label === 'isPasswordOneCapitalLetter' && (
          <PasswordContainsOneCapitalLetter />
        )}
        {label === 'isPasswordOneNumber' && <PasswordContainsOneNumber />}
      </div>
    </div>
  )
}

export default PasswordRequirementField
