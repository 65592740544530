import {
  AddUserAssessmentAnswerItemModel,
  AnswerOptionModel,
} from 'api/data-contracts'

type QuestionProps = {
  answer: AnswerOptionModel
  setSelectedAnswer: (answer: AddUserAssessmentAnswerItemModel[]) => void
  selectedAnswer: AddUserAssessmentAnswerItemModel[]
  answerItem: AddUserAssessmentAnswerItemModel
}

export const SingleSelect = ({
  answer,
  setSelectedAnswer,
  selectedAnswer,
  answerItem,
}: QuestionProps) => {
  return (
    <button
      className={`text-lg mb-6 shadow-bottom font-medium rounded-medium w-[100%] border-2 block px-5 py-3 ${
        selectedAnswer[0]?.answerOptionId === answerItem.answerOptionId
          ? 'border-main'
          : 'border-white'
      }`}
      onClick={() => setSelectedAnswer([answerItem])}
    >
      {answer?.answerText}
    </button>
  )
}
