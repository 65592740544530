import { Fragment, useCallback } from 'react'

import { SectionTemplate } from './SectionTemplate'
import { ContentItemModel, ContentSectionModel } from 'api/data-contracts'

import {
  SIXTYSIX_THIRTYTHREE,
  THIRTYTHREE_SIXTYSIX,
} from 'utils/constants/section'

interface ContentProps {
  contentSection: ContentSectionModel[]
}
const Content = ({ contentSection }: ContentProps) => {
  const intialRenderValueHandler = useCallback(
    (contentItems: ContentItemModel[], numberOfCols: number) => {
      let array: ContentItemModel[] = []

      const orders = contentItems.map(value => value.order)
      if (contentItems.length) {
        array = [...contentItems]
      }
      for (let i = 0; i < numberOfCols; i++) {
        if (!orders.includes(i + 1)) {
          array.push({ order: i + 1 })
        }
      }
      return array.sort((a, b) => a.order! - b.order!)
    },
    [],
  )
  return (
    <>
      {contentSection &&
        contentSection.map(contentSectionData => (
          <Fragment key={contentSectionData.id}>
            {contentSectionData.numberOfCols === 1 && (
              <div className="mb-5 content-item ck ck-content">
                {contentSectionData &&
                  contentSectionData.contentItems?.map(contentItemsData => (
                    <Fragment key={contentItemsData.id}>
                      {SectionTemplate(contentItemsData)}
                    </Fragment>
                  ))}
              </div>
            )}
            {contentSectionData.numberOfCols === 2 && (
              <div
                className={`grid two-content-items-wrapper gap-14 my-12
                  ${
                    contentSectionData.colWeight === THIRTYTHREE_SIXTYSIX
                      ? 'oneFrametwoFrame'
                      : contentSectionData.colWeight === SIXTYSIX_THIRTYTHREE
                      ? 'twoFrameoneFrame'
                      : 'oneFrameoneFrame'
                  }`}
              >
                {intialRenderValueHandler(
                  contentSectionData.contentItems!,
                  contentSectionData.numberOfCols,
                ).map((contentItemData, index) => {
                  return contentItemData.id ? (
                    <div
                      className="w-full content-item ck ck-content"
                      key={index}
                    >
                      {SectionTemplate(contentItemData)}
                    </div>
                  ) : (
                    <div className="w-full content-item" key={index}></div>
                  )
                })}
              </div>
            )}
            {contentSectionData.numberOfCols === 3 && (
              <div className="grid three-content-items-wrapper gap-14 my-12">
                {intialRenderValueHandler(
                  contentSectionData.contentItems!,
                  contentSectionData.numberOfCols,
                ).map((contentItemData, index) => {
                  return contentItemData.id ? (
                    <div
                      className="w-full content-item ck ck-content"
                      key={index}
                    >
                      {SectionTemplate(contentItemData)}
                    </div>
                  ) : (
                    <div className="w-full content-item" key={index}></div>
                  )
                })}
              </div>
            )}
          </Fragment>
        ))}
    </>
  )
}

export default Content
