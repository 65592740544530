import { Suspense, useEffect, useState } from 'react'

import AppRoutes from 'routes'
import { LoaderSpinner } from 'components/LoaderSpinner'
import useResetScroll from './useResetScroll'

// API - custom hooks
import { useBranding } from 'utils/useBranding'
import { setOrganisationId } from 'utils/organisation'
import {
  getRefreshToken,
  getToken,
  useAuthTokenRefreshCreate,
} from 'utils/auth'

const App = () => {
  useResetScroll()
  const { mutateAsync: authTokenRefreshCreate } = useAuthTokenRefreshCreate()

  const { brandingId, isLoading: isBrandingLoading, branding } = useBranding()
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (!isBrandingLoading) {
      setOrganisationId(brandingId!)
      setIsReady(true)
      document.documentElement.style.setProperty(
        '--branding-primary',
        branding.stylingPrimary!,
      )
      document.documentElement.style.setProperty(
        '--branding-secondary',
        branding.stylingSecondary!,
      )
      document.documentElement.style.setProperty(
        '--branding-info',
        branding.stylingInfo!,
      )
      document.documentElement.style.setProperty(
        '--branding-svg',
        branding.stylingPrimary!.substring(1),
      )
      document.documentElement.style.setProperty(
        '--branding-primary-hover',
        branding.stylingPrimary! + 50,
      )
      document.documentElement.style.setProperty(
        '--branding-secondary-hover',
        branding.stylingSecondary! + 50,
      )
      document.documentElement.style.setProperty(
        '--branding-info-hover',
        branding.stylingInfo! + 50,
      )
    }
  }, [isBrandingLoading, brandingId, branding])

  useEffect(() => {
    const rootElement = document.documentElement

    rootElement?.addEventListener('contextmenu', e => {
      e.preventDefault()
    })
  }, [])

  const accessToken = getToken()
  const refreshToken = getRefreshToken()

  useEffect(() => {
    if (accessToken === null && refreshToken !== null) {
      (async () => {
        const data = {
          refreshToken: refreshToken,
        }
        await authTokenRefreshCreate(data)
      })()
    }
  }, [authTokenRefreshCreate, accessToken, refreshToken])
  return (
    <>
      {isReady ? (
        <Suspense
          fallback={
            <div className="width-[100%] height-[100vh] inline-flex">
              <LoaderSpinner />
            </div>
          }
        >
          <AppRoutes />
        </Suspense>
      ) : (
        <LoaderSpinner />
      )}
    </>
  )
}

export default App
