/* eslint-disable react-hooks/exhaustive-deps */
import { ModuleModel, UserModuleDataModel } from 'api/data-contracts'
import { useModuleContext } from 'context/ModuleContext'
import { useMemo, useState } from 'react'
import { useUpdateMySectionProgress } from 'utils/section'
import {
  COMPLETED,
  FEEDBACKREQUIRED,
  INPROGRESS,
  NOTSTARTED,
} from './constants/slide'
import { defaultSectionModel } from './models/Auth'
import { useIsMount } from './useIsMount'

const useSetInitialSection = (
  module: ModuleModel,
  userCourseInstanceId: string,
  myModule: UserModuleDataModel,
) => {
  const { mutateAsync: updateMySectionProgress } = useUpdateMySectionProgress()
  const { setCurrentSection, setModuleCompletion } = useModuleContext()
  const isMount = useIsMount()
  const [hasEffectRun, setHasEffectRun] = useState(false)

  useMemo(() => {
    if (
      module &&
      myModule &&
      userCourseInstanceId &&
      (!hasEffectRun || isMount)
    ) {
      setCurrentSection(defaultSectionModel)
      setModuleCompletion(false)
      const firstSection = module?.sections?.[0]

      const inProgressSectionId = myModule?.userModuleSectionDatas?.find(
        section => section.sectionProgress === INPROGRESS,
      )?.section?.id

      const currentSection = module?.sections?.find(
        section => section.id === inProgressSectionId,
      )

      if (myModule?.userModuleStatus === INPROGRESS) {
        inProgressSectionId === undefined
          ? firstSection && setCurrentSection(firstSection)
          : setCurrentSection(currentSection!)
      }

      if (myModule?.userModuleStatus === NOTSTARTED) {
        inProgressSectionId === undefined
          ? firstSection && setCurrentSection(firstSection)
          : setCurrentSection(currentSection!)

        firstSection &&
          updateMySectionProgress({
            sectionId: firstSection.id!,
            data: {
              userCourseInstanceId: userCourseInstanceId,
              sectionProgress: INPROGRESS,
            },
          }).then(() => {
            setCurrentSection(firstSection)
          })
      }
      if (
        myModule?.userModuleStatus === COMPLETED ||
        myModule?.userModuleStatus === FEEDBACKREQUIRED
      ) {
        firstSection && setCurrentSection(firstSection)
      }
      setHasEffectRun(true)
    }
  }, [module, isMount, myModule, userCourseInstanceId, hasEffectRun])
}

export default useSetInitialSection
