import { ModuleModel } from 'api/data-contracts'
import { generateMediaPath } from 'utils/generateMediaPath'
import { ReactComponent as HomeIcon } from 'assets/icon/homepage.svg'
import AnchorLink from 'components/AnchorLink'
import Header from '../Header'

interface Props {
  module: ModuleModel
  userCourseInstanceId: string
  totalSections: number
  lastCompletedSectionOrder: number
}

export const ModuleCompleted = ({
  module,
  userCourseInstanceId,
  totalSections,
  lastCompletedSectionOrder,
}: Props) => {
  return (
    <div className="flex items-baseline flex-col">
      <Header
        module={module}
        totalSections={totalSections}
        lastCompletedSectionOrder={lastCompletedSectionOrder}
      />
      <div className="headerSpacing">
        <p className="mb-4 text-2xl font-bold pt-5">
          {module.moduleCompletionTitle}
        </p>
        {module.moduleCompletionImage && module.moduleCompletionTitle && (
          <img
            className="mb-4 max-h-[50vh]"
            src={generateMediaPath(module.moduleCompletionImage)}
            alt={module.moduleCompletionTitle}
          />
        )}
        <p className="mb-4">{module.moduleCompletionDescription}</p>
        <AnchorLink
          to={`/course-dashboard/${userCourseInstanceId}`}
          className={`button secondary-button-primary mb-[45px] flex gap-2`}
        >
          Return to modules
          <HomeIcon className="w-[20px] h-[24px] ml-1" />
        </AnchorLink>
      </div>
    </div>
  )
}
