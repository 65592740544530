import { useState } from 'react'
import { useAddMySectionNote } from 'utils/section'
import { toast } from 'react-toastify'
import { useUpdateNote } from 'utils/note'
import { NoteModel } from 'api/data-contracts'
import { useNoteContext } from 'context/NoteContext'

type Props = {
  userCourseInstanceId: string
  currentSectionId: string
  notes: NoteModel[]
  note?: NoteModel
  setShowAddNote?: (value: React.SetStateAction<boolean>) => void
}

export const NoteConfig = ({
  userCourseInstanceId,
  currentSectionId,
  notes,
  note,
  setShowAddNote,
}: Props) => {
  const { setShowNoteConfig, setNote } = useNoteContext()
  const { mutateAsync: addMySectionNote } = useAddMySectionNote()
  const { mutateAsync: updateNote } = useUpdateNote()
  const [noteTitle, setNoteTitle] = useState(
    note?.title || 'Note 0' + (notes.length + 1),
  )
  const [noteDesc, setNoteDesc] = useState(note?.note)
  const noteId = note?.id
  const addSectionNote = () => {
    addMySectionNote({
      sectionId: currentSectionId!,
      data: {
        userCourseInstanceId: userCourseInstanceId,
        title: noteTitle,
        note: noteDesc,
      },
    }).then(res => {
      toast.success('Note added.')
      setShowNoteConfig(false)
      setShowAddNote && setShowAddNote(false)
    })
  }

  const closeNoteConfig = () => {
    setShowNoteConfig(false)
    setShowAddNote && setShowAddNote(false)
    setNote(undefined)
  }

  const updateSectionNote = () => {
    updateNote({
      noteId: noteId!,
      data: {
        title: noteTitle,
        note: noteDesc,
      },
    }).then(res => {
      toast.success('Note updated.')
      setNote(undefined)
      setShowNoteConfig(false)
    })
  }

  return (
    <div className="flex-col text-grey">
      <h5>Note title</h5>
      <input
        className="text-base text-grey my-4 bg-light-grey py-4 px-4 text-left w-[100%] border-2 border-light-grey focus:border-main rounded-medium focus:border-2 shadow-md"
        type="text"
        placeholder="Enter note title here..."
        value={noteTitle!}
        onChange={e => setNoteTitle(e.target.value)}
      />
      <h5>Your note</h5>
      <textarea
        className="text-base text-grey bg-light-grey h-[200px] note-textarea my-4 py-4 px-4 text-left w-[100%] border-2 border-light-grey focus:border-main rounded-medium focus:border-2 shadow-md"
        placeholder="Enter note here..."
        value={noteDesc!}
        onChange={e => setNoteDesc(e.target.value)}
      />
      <div className="flex justify-between w-full mt-[30px]">
        <button
          onClick={closeNoteConfig}
          className="button button-radius-large border mr-4 lg:mx-0 text-grey"
        >
          Close
        </button>
        <button
          onClick={!note ? addSectionNote : updateSectionNote}
          className="button button-radius-large bg-branding-secondary disabled:opacity-50 disabled:pointer-events-none ml-4 lg:mx-0 "
          disabled={!noteTitle || !noteDesc}
        >
          Publish
        </button>
      </div>
    </div>
  )
}
