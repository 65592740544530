import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { CustomPasswordField } from 'components/CustomPasswordField'
import { ResetPasswordModel } from 'utils/models/Auth'
import { getOrganisationId } from 'utils/organisation'
import { useResetUserPassword } from 'utils/auth'
import AuthPageWrapper from './AuthPageWrapper'
import PasswordValidation from './PasswordValidation'
import { toast } from 'react-toastify'
import { passwordValidation } from 'utils/validation/passwords'

const validationSchema = Yup.object<ResetPasswordModel>().shape({
  password: passwordValidation,
  confirmpassword: Yup.string()
    .nullable()
    .oneOf([Yup.ref('password'), null], 'Both passwords should match')
    .required('Confirm password is required'),
})

const ResetPassword = () => {
  const { mutateAsync: resetUserPassword } = useResetUserPassword()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const [passwordChange, setPasswordChange] = useState(false)

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<ResetPasswordModel>({
    resolver: yupResolver(validationSchema),
  })
  const search = useLocation().search
  const token = new URLSearchParams(search).get('token')

  const onPasswordChange = watch('password')

  const onSubmit: SubmitHandler<ResetPasswordModel> = async data => {
    const resetPasswordData = {
      resetToken: token,
      newPassword: data.password,
      organisationId: getOrganisationId(),
    }
    try {
      await resetUserPassword(resetPasswordData)
      setPasswordChange(true)
    } catch {
      toast.error('Unable to reset password')
    }
  }

  return (
    <AuthPageWrapper
      title="Reset your password"
      description="Setting your password"
      showPanel={false}
    >
      <PasswordValidation password={onPasswordChange!} />

      <form>
        <CustomPasswordField
          control={control}
          errors={errors?.password}
          name="password"
          label="Password"
          state={passwordVisible}
          setState={setPasswordVisible}
        />
        <CustomPasswordField
          control={control}
          errors={errors?.confirmpassword}
          name="confirmpassword"
          label="Confirm password"
          state={confirmPasswordVisible}
          setState={setConfirmPasswordVisible}
        />
      </form>
      <div className="w-full my-auto mx-0">
        <button
          type="submit"
          className="w-full button py-4 bg-branding-secondary"
          onClick={handleSubmit(onSubmit)}
        >
          {isSubmitting && (
            <span className="spinner-border spinner-border-sm mr-1"></span>
          )}
          Reset password
        </button>
      </div>
      {passwordChange && (
        <p className="mt-9 font-medium text-branding-secondary">
          Your password was changed successfully.
        </p>
      )}
    </AuthPageWrapper>
  )
}

export default ResetPassword
