import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  CompletionModel,
  UpdateMyModuleCompleteModel,
} from 'api/data-contracts'

import { useApiClient } from './use-clients'
import { ModuleCourseModel } from './models/Slide'

const useGetMyModuleData = (
  moduleId: string,
  userCourseInstanceId: string | undefined,
) => {
  const client = useApiClient()
  const myQueryFn = (
    moduleId: string,
    userCourseInstanceId: string | undefined,
  ) => {
    return client.module.getMyModuleData(moduleId, { userCourseInstanceId })
  }
  const result = useQuery({
    queryKey: ['myModuleData', { moduleId }],
    queryFn: () => myQueryFn(moduleId, userCourseInstanceId),
    enabled: getEnabled(moduleId, userCourseInstanceId),
  })

  return {
    ...result,
    module: result.data?.data,
  }
}

// GET:/api/Module/{moduleId}/data
const useGetModuleData = (moduleId: string) => {
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['moduleData', { moduleId }],
    queryFn: () => client.module.getModuleData(moduleId),
    enabled: !!moduleId,
  })
  return {
    ...result,
    module: result.data?.data as ModuleCourseModel,
  }
}

const getEnabled = (
  moduleId: string,
  userCourseInstanceId: string | undefined,
) => {
  if (moduleId && userCourseInstanceId) {
    return true
  } else return false
}

export type UpdateMyModuleCompleteProps = {
  moduleId: string
  data: UpdateMyModuleCompleteModel
  onSuccess: (data: CompletionModel) => void
}

const useCompleteMyModule = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (props: UpdateMyModuleCompleteProps) =>
      client.module.completeMyModule(props.moduleId, props.data),
    onSuccess: async (data, props) => {
      queryClient.setQueryData(['CompletionData'], data) //set query (oldData,newData)
      queryClient.refetchQueries(['CompletionData']) // refetch queries that depend on the user data
      const completionData = data.data
      props.onSuccess(completionData!)
    },
  })
  return mutation
}

export { useGetMyModuleData, useGetModuleData, useCompleteMyModule }

//Completion Model
// status COURSECOMPLETE | MODULECOMPLETE | TRAINERFEEDBACK
// image
// title
// description
// list of non completed items

//Module Complete Your Progress
//Title
//Description
//Image
//Home button

/*
export interface CompletionModel {
  status?: string | null
  completionTitle?: string | null
  completionImage?: string | null
  completionDescription?: string | null
  incompleteSections?: SectionModel[] | null
}
*/

//On last slide, hide next button, show finish button and have it fire my complete.

//if no sections completed, returns list of incomplete sections
//if nothing return (completed sections)
//if auto progression, moves them on automatically
//mark usermodulesection complete
//gets next module and sets that to be notstarted(current)
//if there is no next module sets course to complete and generates certificate
// if trainer required sets module to trainer required

//get course instance
// is course complete, if so show course complete
// if not show module complete

//course and module complete Image,title,description
