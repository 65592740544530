import parse from 'html-react-parser'
import AnchorLink from './AnchorLink'
import { OrganisationSettingModel } from 'api/data-contracts'
import { sectionColourModel } from 'utils/constants/sectionColour'
import { sectionColour } from 'utils/sectionColour'

type Props = {
  settings: OrganisationSettingModel
  bgColour: sectionColourModel['bgColour']
}

const ContentPanel = ({ settings, bgColour }: Props) => {
  const { backgroundColor, textColor, headingColor, buttonColor } =
    sectionColour(bgColour)
  return (
    <>
      {settings?.moreInfoTitle && settings?.moreInfoDescription && (
        <section
          className={`${backgroundColor} px-9 py-20 lg:py-32 lg:px-24 section-border-grey`}
        >
          <div className="container mx-auto xl:px-12 grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="justify-start">
              <div className="text-center lg:text-left">
                {settings?.moreInfoTitle && (
                  <>
                    <h2 className={`${headingColor}`}>
                      {settings?.moreInfoTitle}
                    </h2>
                    <hr className="hr rounded-cap bg-branding-secondary"></hr>
                  </>
                )}
              </div>
              <p
                className={`${textColor} text-center sm:text-left font-medium`}
              >
                {settings?.moreInfoDescription
                  ? parse(settings?.moreInfoDescription)
                  : ''}
              </p>
            </div>
            {settings?.moreInfoCallToActionUrl &&
              settings?.moreInfoCallToActionText && (
                <div className="pt-2 sm:pt-5 flex justify-center lg:justify-end items-end">
                  <span
                    className={`${buttonColor} inline-flex button button-radius-large button-large`}
                  >
                    <AnchorLink to={settings?.moreInfoCallToActionUrl}>
                      {settings?.moreInfoCallToActionText}
                    </AnchorLink>
                  </span>
                </div>
              )}
          </div>
        </section>
      )}
    </>
  )
}

export default ContentPanel
