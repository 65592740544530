import { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { CustomTextField } from 'components/CustomTextField'
import { useCheckEmailExist } from 'utils/auth'
import { ValidationExceptionModel } from 'api/data-contracts'
import { HttpResponse } from 'api/http-client'
import { CheckEmailExistsModel } from 'utils/models/Auth'
import AppBar from 'components/AppBar'
import AnchorLink from 'components/AnchorLink'

const validationSchema = Yup.object<CheckEmailExistsModel>().shape({
  email: Yup.string()
    .email(
      'Enter an email address in the correct format, for example: name@example.com',
    )
    .required('Email is required'),
})

interface CheckAccountProps {
  setEmailDefaultValue: (value: string) => void
  setAccountExist: (value: boolean) => void
}

const CheckAccount = ({
  setEmailDefaultValue,
  setAccountExist,
}: CheckAccountProps) => {
  const { mutateAsync } = useCheckEmailExist()
  const [registered, setRegistered] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<CheckEmailExistsModel>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit: SubmitHandler<CheckEmailExistsModel> = data => {
    mutateAsync({
      userName: data.email,
    })
      .then(
        (
          res: HttpResponse<CheckEmailExistsModel, ValidationExceptionModel>,
        ) => {
          setEmailDefaultValue(data.email as string)

          if (res?.data?.userExists === true) {
            setRegistered(true)
          } else {
            setAccountExist(false)
          }
        },
      )
      .catch(error => {
        setErrorMessage('Invalid username')
      })
  }

  return (
    <>
      <AppBar />
      <form onSubmit={handleSubmit(onSubmit)} className="!mt-3">
        <CustomTextField
          control={control}
          errors={errors?.email}
          name="email"
          label="Email"
        />

        <div className="w-full my-auto mx-0">
          <button
            type="submit"
            className="button py-4 w-full mt-4 bg-branding-secondary"
          >
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Create account
          </button>
        </div>
        {registered && (
          <>
            <p className="mt-6 text-error">
              This email address is already registered.
            </p>
            <p className="mt-2">
              Do you want to{' '}
              <AnchorLink to="/login" className="font-bold underline">
                login
              </AnchorLink>{' '}
              or{' '}
              <AnchorLink to="/forgot-password" className="font-bold underline">
                recover your password
              </AnchorLink>
              ?
            </p>
          </>
        )}
        {errorMessage !== '' && (
          <p className="text-error mt-5">{errorMessage}</p>
        )}
      </form>
    </>
  )
}

export default CheckAccount
