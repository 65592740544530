import { ResourceModel } from 'api/data-contracts'
import { Resource } from './Resource'

const Resources = ({ resources }: { resources: ResourceModel[] }) => {
  return (
    <>
      <h3 className="hidden lg:block font-normal text-grey px-7">Resources</h3>
      <hr className="hidden lg:block w-full mt-[20px] mb-[30px] h-[1px] bg-[#9D9D9D] rounded-cap border-0"></hr>
      <div className="py-3 px-7 resourse-panel">
        {resources && resources.length ? (
          resources.map(resource => (
            <Resource key={resource.id} resource={resource} />
          ))
        ) : (
          <p className="text-grey font-normal text-lg">
            No resources have been added for this course.
          </p>
        )}
      </div>
    </>
  )
}

export default Resources
