import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  AddMySectionAssessmentModel,
  AddMySectionNoteModel,
  UpdateSectionProgressModel,
  UserModuleSectionAssessmentModel,
  UserModuleSectionDataModule,
} from 'api/data-contracts'
import invariant from 'tiny-invariant'

import { useApiClient } from './use-clients'
import { useParams } from 'react-router-dom'

// PUT:/api/Section/{sectionId}/myprogress
export type UpdateSectionProgressProps = {
  sectionId: string
  data: UpdateSectionProgressModel
}
const useUpdateMySectionProgress = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (props: UpdateSectionProgressProps) =>
      client.section.updateMySectionProgress(props.sectionId, props.data),
    onSuccess: async data => {
      queryClient.refetchQueries(['myModuleData'])
      queryClient.refetchQueries(['moduleData'])
    },
  })
  return mutation
}

// GET:/api/Section/{sectionId}/assessment/my
export interface getMySectionAssessmentsQueryparams {
  userCourseInstanceId?: string
}
const useGetMySectionAssessments = (sectionId: string) => {
  invariant(sectionId, 'Invalid section id')
  const { userCourseInstanceId } = useParams()
  const client = useApiClient()
  const query: getMySectionAssessmentsQueryparams = {
    userCourseInstanceId: userCourseInstanceId,
  }
  const result = useQuery({
    queryKey: ['assessments', { sectionId }],
    queryFn: () => client.section.getMySectionAssessments(sectionId, query),
    enabled: !!sectionId && !!userCourseInstanceId,
  })
  return {
    ...result,
    assessments: result.data?.data,
  }
}

// POST:/api/Section/{sectionId}/assessment/my/start
export type UserModuleSectionAssessmentProps = {
  sectionId: string
  data: AddMySectionAssessmentModel
  onSuccess: (data: UserModuleSectionAssessmentModel) => void
}
const useStartMySectionAssessment = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (props: UserModuleSectionAssessmentProps) =>
      client.section.startMySectionAssessment(props.sectionId, props.data),
    onSuccess: async (data, props) => {
      queryClient.setQueryData(['sectionAssessment'], data) //set query (oldData,newData)
      queryClient.refetchQueries(['sectionAssessment']) // refetch queries that depend on the user data
      const assessment = data.data
      props.onSuccess(assessment!)
    },
  })
  return mutation
}

const useGetSectionRandomQuestions = (sectionId: string) => {
  invariant(sectionId, 'Invalid section id')
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['sectionRandomQuestionForAssessment', { sectionId }],
    queryFn: () => client.section.getSectionRandomQuestions(sectionId),
    enabled: !!sectionId,
  })
  return {
    ...result,
    questions: result.data?.data,
  }
}

const GetSectionRandomQuestions = (sectionId: string) => {
  const client = useApiClient()
  client.section.getMySectionAssessments(sectionId)
}

// GET:/api/Section/{sectionId}/usernotes
export type GetSectionNotesProps = {
  sectionId: string
  userCourseInstanceId: object
  userModuleSectionData: UserModuleSectionDataModule | undefined
}
const useGetSectionNotes = (props: GetSectionNotesProps) => {
  const client = useApiClient()

  const result = useQuery({
    queryKey: ['notes', { props }],
    queryFn: () =>
      client.section.getMySectionNotes(
        props.sectionId,
        props.userCourseInstanceId,
      ),
    enabled: !!props.sectionId && !!props.userCourseInstanceId,
  })
  return {
    ...result,
    notes: result.data?.data,
  }
}

// POST:/api/Section/{sectionId}/usernotes
export type AddSectionNoteModel = {
  sectionId: string
  data: AddMySectionNoteModel
}
const useAddMySectionNote = () => {
  const client = useApiClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (props: AddSectionNoteModel) =>
      client.section.addMySectionNote(props.sectionId, props.data),
    onSuccess: async data => {
      queryClient.setQueryData(['notes'], data)
      queryClient.refetchQueries(['notes'])
    },
  })
  return mutation
}

export {
  useGetMySectionAssessments,
  useUpdateMySectionProgress,
  useStartMySectionAssessment,
  useGetSectionRandomQuestions,
  GetSectionRandomQuestions,
  useGetSectionNotes,
  useAddMySectionNote,
}
