import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import invariant from 'tiny-invariant'

import { useApiClient } from './use-clients'

// GET:/api/CourseInstance/{courseInstanceId}/active
const useGetActiveCourseInstanceById = (courseInstanceId: string) => {
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['courseInstanceId', { courseInstanceId }],
    queryFn: () =>
      client.courseInstance.getActiveCourseInstanceAndCourseById(
        courseInstanceId,
      ),
    enabled: !!courseInstanceId,
    staleTime: Infinity,
  })
  return {
    ...result,
    courseInstance: result.data?.data,
  }
}

// PUT:/api/CourseInstance/{courseInstanceId}/participants/assignme
export type AddMeToCourseInstanceProps = {
  query?: {
    Optional1?: string
    Optional2?: string
  }
  onSuccess: () => void
  onError: (error: unknown) => void
}
const useAddMeToCourseInstance = (courseInstanceId: string) => {
  invariant(courseInstanceId, 'Invalid course instance id')
  const client = useApiClient()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (props: AddMeToCourseInstanceProps) =>
      client.courseInstance.addMeToCourseInstance(
        courseInstanceId,
        props.query,
      ),
    onSuccess: async (data, props) => {
      queryClient.setQueryData(['myCoursesByOrganisation'], data) //set query (oldData,newData)
      queryClient.refetchQueries(['myCoursesByOrganisation']) // refetch queries that depend on the user data
      props.onSuccess()
    },
    onError: async (error, props) => {
      props.onError(error)
    },
  })
  return mutation
}

export { useGetActiveCourseInstanceById, useAddMeToCourseInstance }
