import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useResetScroll = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location, navigate]);
};

export default useResetScroll;
