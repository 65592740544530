import { ModuleModel, UserModuleDataModel } from 'api/data-contracts'
import Appbar from 'components/module/Appbar'
import Drawer from 'components/module/Drawer'

interface SidebarProps {
  module: ModuleModel
  myModule: UserModuleDataModel
}

const Sidebar = ({ module, myModule }: SidebarProps) => {
  return (
    <>
      <Drawer module={module} myModule={myModule} />
      <Appbar module={module} />
    </>
  )
}
export default Sidebar
