import { AnswerOptionModel } from 'api/data-contracts'
import { Draggable } from 'react-beautiful-dnd'

type AnswerProps = {
  answer: AnswerOptionModel
  index: number
}

export const SpecificOrder = ({ answer, index }: AnswerProps) => {
  return (
    <Draggable draggableId={answer.id!} index={index} key={answer.id}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`my-6 px-5 py-3 text-left w-[100%] border-main rounded-medium shadow-bottom bg-white ${
            snapshot.isDragging ? 'bg-gray-400' : ''
          }`}
        >
          {answer.answerText}
        </div>
      )}
    </Draggable>
  )
}
