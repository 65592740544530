import { CourseInstanceModel } from 'api/data-contracts'

interface CourseBookingConfirmationProps {
  courseInstance: CourseInstanceModel
  optional1Val: string
  setOptional1Val: React.Dispatch<React.SetStateAction<string>>
  optional2Val: string
  setOptional2Val: React.Dispatch<React.SetStateAction<string>>
  onCancel: () => void
  onBook: () => void
}

const CourseBookingConfirmation = ({
  courseInstance,
  optional1Val,
  setOptional1Val,
  optional2Val,
  setOptional2Val,
  onCancel,
  onBook,
}: CourseBookingConfirmationProps) => {
  return (
    <div className="w-full h-screen bg-black fixed left-0 top-0 bg-opacity-80 z-[100]">
      <div className="flex justify-center items-center h-full relative z-1000">
        <div className="min-w-[300px] sm:min-w-[500px] max-w-[90%] sm:max-w-[430px] min-h-[100px] bg-white p-8 rounded-[15px]">
          {courseInstance.useOptional1 === true && (
            <>
              <p className="font-semibold text-xl mb-1">
                {courseInstance.optional1}
              </p>
              {courseInstance.optional1Values !== null ? (
                <>
                  <select
                    className="w-full bg-text-field border-0 outline-none p-3 text-lg rounded-lg mb-3 mt-1"
                    onChange={e => setOptional1Val(e.target.value)}
                  >
                    <option value={''}>Choose option</option>
                    {courseInstance.optional1Values?.includes(',') ? (
                      <>
                        {courseInstance.optional1Values
                          .split(',')
                          .map((value, index) => (
                            <option value={value} key={index}>
                              {value}
                            </option>
                          ))}
                      </>
                    ) : (
                      <option value={courseInstance.optional1Values!}>
                        {courseInstance.optional1Values}
                      </option>
                    )}
                  </select>
                </>
              ) : (
                <input
                  type="text"
                  value={optional1Val}
                  onChange={e => setOptional1Val(e.target.value)}
                  placeholder={`${courseInstance.optional1}`}
                  className="w-full bg-text-field p-3 rounded-lg outline-none text-lg mb-3 mt-1"
                />
              )}
            </>
          )}

          {courseInstance.useOptional2 === true && (
            <>
              <p className="font-semibold text-xl mb-1">
                {courseInstance.optional2}
              </p>
              {courseInstance.optional2Values !== null ? (
                <>
                  <select
                    className="w-full bg-text-field border-0 outline-none p-3 text-lg rounded-lg mb-3 mt-1"
                    onChange={e => setOptional2Val(e.target.value)}
                  >
                    <option value={''}>Choose option</option>
                    {courseInstance.optional2Values?.includes(',') ? (
                      <>
                        {courseInstance.optional2Values
                          .split(',')
                          .map((value, index) => (
                            <option value={value} key={index}>
                              {value}
                            </option>
                          ))}
                      </>
                    ) : (
                      <option value={courseInstance.optional2Values!}>
                        {courseInstance.optional2Values}
                      </option>
                    )}
                  </select>
                </>
              ) : (
                <input
                  type="text"
                  value={optional2Val}
                  onChange={e => setOptional2Val(e.target.value)}
                  placeholder={`${courseInstance.optional2}`}
                  className="w-full bg-text-field p-3 rounded-lg outline-none text-lg mb-3 mt-1"
                />
              )}
            </>
          )}

          <div className="flex justify-between w-full mt-[30px]">
            <button
              onClick={onCancel}
              className="border-0 px-6 py-3 rounded-lg justify-center text-xl font-medium text-center min-w-[100px] bg-black text-white"
            >
              Cancel
            </button>
            <button
              onClick={onBook}
              className="px-6 py-3 rounded-lg justify-center text-xl font-bold text-center min-w-[100px] bg-branding-secondary"
            >
              Book
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseBookingConfirmation
