interface AssessmentAwayMessageProps {
  onStay: () => void
  onLeave: () => void
}

const AssessmentAwayMessage = ({
  onStay,
  onLeave,
}: AssessmentAwayMessageProps) => {
  return (
    <div className="w-full h-screen bg-black fixed left-0 top-0 bg-opacity-80 z-[100]">
      <div className="flex justify-center items-center h-full relative z-1000">
        <div className="min-w-[100px] max-w-[90%] sm:max-w-[430px] min-h-[100px] bg-white p-8 rounded-[15px]">
          <p className="text-center font-semibold text-xl">
            You are about to leave the assessment. By doing so you will need to
            begin again when you come back.
          </p>
          <div className="flex justify-between w-full mt-[40px]">
            <button
              onClick={onLeave}
              className="px-6 py-3 rounded-lg justify-center text-xl font-medium text-center min-w-[120px] bg-black text-white"
            >
              Leave
            </button>
            <button
              onClick={onStay}
              className="border-0 px-6 py-3 rounded-lg justify-center text-xl font-bold text-center min-w-[120px] bg-branding-secondary"
            >
              Stay
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssessmentAwayMessage
