import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { SectionModel } from 'api/data-contracts'
import Sidebar from './Sidebar'
import Section from 'components/module/section/Section'

import { useGetModuleData } from 'utils/module'

const PreviewSlide = () => {
  const { sectionId, moduleId } = useParams()
  const { module } = useGetModuleData(moduleId!)

  const [previewSlideData, setPreviewSlideData] = useState<SectionModel>()

  useEffect(() => {
    if (module) {
      const slideData = module?.sections?.filter(
        value => value.id === sectionId,
      )
      slideData && slideData.length > 0 && setPreviewSlideData(slideData[0])
    }
  }, [module, sectionId])

  return (
    <Fragment>
      <div className="absolute right-[20px] xl:right-[150px] top-[10px] sm:top-[20px] xl:top-[30px] px-4 py-3 rounded-xl text-white text-lg bg-branding-secondary font-bold preview-mode">
        Preview mode
      </div>
      <div className="flex overflow-hidden bg-branding-primary">
        <section className="bottomShadow stage flex w-[100%] px-6 lg:px-20 bg-white h-[100vh] overflow-auto scrollbar-hide">
          <div className="container relative mb-20 lg:my-20 mx-auto flex flex-col">
            <Section previewSection={previewSlideData} />
          </div>
        </section>

        <div className="clippy-shadow shadow-top-right">
          <div className="rounded-border"></div>
        </div>
        <div className="clippy-shadow shadow-bottom-right">
          <div className="rounded-border"></div>
        </div>

        <Sidebar />
      </div>
    </Fragment>
  )
}

export default PreviewSlide
