import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'
import invariant from 'tiny-invariant'

import { useApiClient } from './use-clients'
import { useUser } from './auth'

const ORGANISATION_ID = 'organisation_id'

export function setOrganisationId(organisationId: string) {
  localStorage.setItem(ORGANISATION_ID, organisationId)
}

export function getOrganisationId() {
  return localStorage.getItem(ORGANISATION_ID)
}

const useGetOrganisationID = () => {
  const result = useQuery({
    queryKey: ['organisationId'],
    queryFn: () => getOrganisationId(),
  })
  return { ...result, organisationId: result?.data ? result.data : '' }
}

const useGetUserFirstOrganisationId = () => {
  const apiClient = useApiClient()

  const getUserFirstOrganisationId = useCallback(async () => {
    const organisationResponse =
      await apiClient.organisation.organisationsByUser()
    const organisationId = organisationResponse.data[0]?.id
    invariant(organisationId, 'Invalid organisation data')
    return organisationId
  }, [apiClient])

  return getUserFirstOrganisationId
}

//OrganisationSettingModel
const useGetSettings = () => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['settings', { organisationId }],
    queryFn: () => client.organisation.getSettings(organisationId),
    enabled: !isLoading && !!organisationId, // Only enable query if organisationId is truthy and isLoading is false
    staleTime: Infinity,
  })
  return { ...result, settings: result.data?.data }
}

//CourseInstanceModel[]
const useGetActivePublicCourseInstancesByOrganisation = () => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['activePublicCourseInstances', { organisationId }],
    queryFn: () =>
      client.organisation.getActivePublicCourseInstancesByOrganisation(
        organisationId,
      ),
    enabled: !isLoading && !!organisationId, // Only enable query if organisationId is truthy and isLoading is false
    staleTime: Infinity,
  })
  return {
    ...result,
    activePublicCourseInstances: result.data?.data,
  }
}

//UserCourseInstanceModel[]
const useGetMyCoursesByOrganisation = () => {
  const { organisationId, isLoading: isOrgLoading } = useGetOrganisationID()
  const { user, isLoading: isProfileLoading } = useUser()
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['myCoursesByOrganisation', { organisationId }],
    queryFn: () =>
      client.organisation.getMyCoursesByOrganisation(organisationId),
    enabled: !!organisationId && !isOrgLoading && !!user && !isProfileLoading, // Only enable query if organisationId & user is truthy and isLoading is false
    staleTime: Infinity,
  })
  return {
    ...result,
    myCourses: result.data?.data,
  }
}

//OrganisationTestimonialModel[]
const useGetTestimonials = () => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['Testimonials', { organisationId }],
    queryFn: () => client.organisation.getTestimonials(organisationId),
    enabled: !isLoading && !!organisationId, // Only enable query if organisationId is truthy and isLoading is false
    staleTime: Infinity,
  })
  return { ...result, testimonials: result.data?.data }
}

// GET:/api/Organisation/{organisationId}/policydocuments
const useGetPolicyDocuments = () => {
  const { organisationId, isLoading } = useGetOrganisationID()
  const client = useApiClient()
  const result = useQuery({
    queryKey: ['organisation', { organisationId }],
    queryFn: () => client.organisation.getPolicyDocuments(organisationId),
    enabled: !isLoading && !!organisationId,
    staleTime: Infinity,
  })
  return { ...result, policyDocuments: result.data?.data ?? [] }
}

export {
  useGetOrganisationID,
  useGetUserFirstOrganisationId,
  useGetSettings,
  useGetActivePublicCourseInstancesByOrganisation,
  useGetTestimonials,
  useGetMyCoursesByOrganisation,
  useGetPolicyDocuments,
}
