import AppBar from 'components/AppBar'
import YourAccountDetails from 'components/yourAccount'
import Footer from 'components/Footer'
import ProfileUserDetail from 'components/yourAccount/ProfileUserDetail'

const YourAccount = () => {
  return (
    <>
      <AppBar />
      <div className="px-6 py-20">
        <div className="mx-auto w-full container pb-20 lg:px-12">
          <h1 className="mt-5 mb-8 lg:mb-10 text-branding-primary">
            Your account
          </h1>
          <div className="lg:hidden">
            <ProfileUserDetail />
          </div>
          <div className="flex flex-wrap">
            <YourAccountDetails />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default YourAccount
