import { useEffect, useState } from 'react'

import Header from '../Header'
import { UserAssessment } from './assessment/UserAssessment'
import { MiniQuiz } from './miniQuiz/MiniQuiz'
import Content from './content/Content'
import { ModuleModel, SectionModel } from 'api/data-contracts'

// Constants
import { ASSESSMENT, CONTENT, MINIQUIZ } from 'utils/constants/section'
import MiniQuizPreview from 'components/previewSlide/MiniQuizPreview'
import AssessmentPreview from 'components/previewSlide/AssessmentPreview'

interface SectionProps {
  currentSection?: SectionModel
  module?: ModuleModel
  totalSections?: number
  lastCompletedSectionOrder?: number
  previewSection?: SectionModel
}
const Section = ({
  currentSection,
  module,
  totalSections,
  lastCompletedSectionOrder,
  previewSection,
}: SectionProps) => {
  const [currentSlide, setCurrentSlide] = useState<SectionModel>()
  const [previewMode, setPreviewMode] = useState(false)

  useEffect(() => {
    if (previewSection) {
      setCurrentSlide(previewSection)
      setPreviewMode(true)
    }
    if (currentSection?.id) {
      setCurrentSlide(currentSection)
      setPreviewMode(false)
    }
  }, [previewSection, currentSection])

  return (
    <div className="flex items-baseline flex-col">
      <Header
        module={module!}
        totalSections={totalSections!}
        lastCompletedSectionOrder={lastCompletedSectionOrder!}
        currentSlide={currentSlide}
      />

      {currentSlide?.sectionType === CONTENT ? (
        <div
          className={`module-content-section py-10 max-w-[1000px] auto-cols-[1fr] headerSpacing w-full ${
            previewMode && 'pointer-events-none'
          }`}
        >
          <Content contentSection={currentSlide.contentSections!} />
        </div>
      ) : currentSlide?.sectionType === ASSESSMENT ? (
        <div className="module-assessment-section py-10 max-w-[1000px] headerSpacing">
          {previewMode ? (
            <AssessmentPreview sectionId={currentSlide.id!} />
          ) : (
            <UserAssessment section={currentSlide} />
          )}
        </div>
      ) : currentSlide?.sectionType === MINIQUIZ ? (
        <div className="module-miniquiz-section py-10 max-w-[1000px] headerSpacing">
          {previewMode ? (
            <MiniQuizPreview section={currentSlide} />
          ) : (
            <MiniQuiz section={currentSlide} />
          )}
        </div>
      ) : null}
    </div>
  )
}

export default Section
