export interface sectionColourModel {
  bgColour: 'grey' | 'white' | 'branding' | ''
}

export const INTRO = 'INTRO'
export const MYCOURSES = 'MYCOURSES'
export const ACTIVECOURSES = 'ACTIVECOURSES'
export const CONTENTPANEL = 'CONTENTPANEL'
export const CENTEREDCONTENTPANEL = 'CENTEREDCONTENTPANEL'
export const TESTIMONIALS = 'TESTIMONIALS'
